<div class="page-sidebar note" [ngClass]="{show: true}">
	<div class="header">
		<i class="icon-arrow back" (click)="cancel()"></i>
		<h2>Note</h2>
	</div>
	<div class="content">
		<div class="main-info">
			<textarea
				class="description"
				placeholder="Note description"
				[(ngModel)]="modifiedNote.description"
			></textarea>
			<app-audio-player [(value)]="audio" (valueChange)="handleNewTranscription($event)"></app-audio-player>
			<!-- SPACE TO ADD THE IMAGE -->
			<app-image-uploader [(value)]="image"></app-image-uploader>
		</div>
		<div class="position-info">
			<div class="box">
				<h3>Current Position</h3>
				<p>
					You can modify the position of the note by dragging the icon on the map to the desired location. The
					changes of Lat and Long will be reflected below
				</p>
			</div>
			<div class="coordinates">
				<span>
					<b>Lat:</b>
					{{ modifiedNote.lat | number: '1.6-8' }}
				</span>
				<span>
					<b>Long:</b>
					{{ modifiedNote.lng | number: '1.6-8' }}
				</span>
			</div>
		</div>
		<div class="buttons">
			<button class="btn btn-white" (click)="deleteNote()">Delete note</button>
		</div>
	</div>
	<div class="footer">
		<button class="btn btn-white" (click)="cancel()">Cancel</button>
		<button class="btn btn-primary" (click)="saveNote()">Save Note</button>
	</div>
</div>
