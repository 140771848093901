import {LatLngLiteral} from '@agm/core';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BehaviorSubject, Subscription, throwError} from 'rxjs';
import {debounceTime, distinctUntilChanged, skip, take} from 'rxjs/operators';
import {AuthService} from 'src/app/services/auth.service';
import {FavoriteService} from 'src/app/services/favorite.service';
import {LoaderService} from 'src/app/services/loader.service';
import {MapService} from 'src/app/services/map.service';
import {MarkerService} from 'src/app/services/marker.service';
import {SearchService} from 'src/app/services/search.service';
import {
	CustomMarkerDto,
	CustomMarkerFolderDto,
	FavoriteDto,
	FolderDto,
	LayerDto,
	RecentSearchDto,
	MarkerDto,
	GeoNoteDto,
} from 'src/app/types/dtos/models';
import {COLORS, MarkerType, SIDEBAR_TO_SHOW, StopType} from 'src/app/types/enums';

enum PanelTabOptions {
	LAYER = 'LAYER',
	FAVORITES = 'FAVORITES',
	MY_PINS = 'MY_PINS',
	USERS = 'USERS',
}

@Component({
	selector: 'app-show-stops',
	templateUrl: './show-stops.component.html',
	styleUrls: ['./show-stops.component.scss'],
})
export class ShowStopsComponent implements OnInit, OnDestroy {
	public show: boolean = true;
	// Subscriptions
	private layerSubscription: Subscription;
	private currentLocationSubscription: Subscription;

	public selectedPanel: string = undefined;
	public openFolder: number;
	public selectedLayer: LayerDto;
	private currentLocation: LatLngLiteral;

	private filterValue: BehaviorSubject<string> = new BehaviorSubject<string>('');
	private debouncedInputSubscription: Subscription;

	public markers!: RecentSearchDto[];
	public filteredMarkers!: RecentSearchDto[];
	public favorites!: FavoriteDto[];
	public filteredFavorites!: FavoriteDto[];
	public folders!: FolderDto[];
	public filteredFolders!: FolderDto[];
	public customFolders!: CustomMarkerFolderDto[];
	public filteredCustomFolders!: CustomMarkerFolderDto[];
	public customPins!: CustomMarkerDto[];
	public filteredCustomPins!: CustomMarkerDto[];

	@Output() markerSelected: EventEmitter<Partial<GeoNoteDto>> = new EventEmitter<Partial<GeoNoteDto>>();
	@Input() showLayer: boolean = true;
	@Input() showFavorites: boolean = true;
	@Input() showMyPins: boolean = true;
	@Input() showUsers: boolean = true;

	constructor(
		private auth: AuthService,
		private loaderService: LoaderService,
		private markerService: MarkerService,
		private mapService: MapService,
		private searchService: SearchService,
		private favoriteService: FavoriteService
	) {}

	public set valueFilter(value: string) {
		this.filterValue.next(value);
	}

	public get valueFilter(): string {
		return this.filterValue.value;
	}

	ngOnInit(): void {
		this.layerSubscription = this.mapService.layerStatus.subscribe(async (layer) => {
			this.selectedLayer = layer;
			this.selectPanel(PanelTabOptions.LAYER);
		});
		this.currentLocationSubscription = this.mapService.currentLocation.subscribe((location) => {
			if (location) {
				this.currentLocation = location;
				this.selectPanel(PanelTabOptions.LAYER);
			}
		});
		this.debouncedInputSubscription = this.filterValue
			.pipe(debounceTime(250), distinctUntilChanged())
			.subscribe((x) => {
				this.filterData();
			});
	}

	ngOnDestroy(): void {
		this.layerSubscription?.unsubscribe();
	}

	close = () => {
		this.markerSelected.emit(undefined);
	};

	public selectMarker = (marker: MarkerDto) => {
		const markerSelected: Partial<GeoNoteDto> = {
			lat: marker.lat,
			lng: marker.lng,
			title: marker.name,
			description: `#${marker.apiNum}`,
			color: COLORS.DEFAULT,
			stopType: StopType.MARKER,
		};
		this.markerSelected.emit(markerSelected);
	};
	public selectFavorite = (favorite: FavoriteDto) => {
		const markerSelected: Partial<GeoNoteDto> = {
			lat: favorite.lat,
			lng: favorite.lng,
			title: favorite.nickname,
			description: `#${favorite.apiNum}`,
			color: COLORS.DEFAULT,
			stopType: StopType.FAVORITE,
		};
		this.markerSelected.emit(markerSelected);
	};
	public selectCustomMarker = (customPin: CustomMarkerDto) => {
		const markerSelected: Partial<GeoNoteDto> = {
			lat: customPin.lat,
			lng: customPin.lng,
			title: customPin.name,
			description: '',
			color: COLORS.BLUE,
			stopType: StopType.COORDINATES,
		};
		this.markerSelected.emit(markerSelected);
	};
	public toggleFolder = (index: number) => {};
	public selectPanel = (panel: string) => {
		switch (panel) {
			case PanelTabOptions.FAVORITES:
				this.getFavorites();
				this.selectedPanel = PanelTabOptions.FAVORITES;
				break;
			case PanelTabOptions.MY_PINS:
				this.getCustomMarkers();
				this.selectedPanel = PanelTabOptions.MY_PINS;
				break;
			case PanelTabOptions.LAYER:
				this.getMarkers();
				this.selectedPanel = PanelTabOptions.LAYER;
				break;
		}
	};

	private getMarkers = async () => {
		try {
			if (!this.markers) {
				this.loaderService.show();
				const markersResult = await this.searchService.getSearches(
					this.selectedLayer.id,
					this.currentLocation.lng.toString(),
					this.currentLocation.lat.toString()
				);
				this.markers = markersResult.data.markers;
				this.filteredMarkers = markersResult.data.markers;
			}
		} catch (error) {
		} finally {
			this.loaderService.hide();
		}
	};

	private getFavorites = async () => {
		try {
			if (!this.favorites) {
				this.loaderService.show();
				const favoritesResult = await this.favoriteService.getFavorites();
				this.favorites = favoritesResult.data;
				this.filteredFavorites = favoritesResult.data;
			}
		} catch (error) {
		} finally {
			this.loaderService.hide();
		}
	};

	private getCustomMarkers = async () => {
		try {
			if (!this.customPins) {
				this.loaderService.show();
				const customMarkersResult = await this.markerService.getCustomMarkers();
				this.customPins = customMarkersResult.data;
				this.filteredCustomPins = customMarkersResult.data;
			}
		} catch (error) {
		} finally {
			this.loaderService.hide();
		}
	};

	public createPin = () => {
		this.show = false;
		this.mapService.showSidebarPanel = {id: SIDEBAR_TO_SHOW.ADD_PIN, metadata: {type: MarkerType.CUSTOM_MARKER}};
		this.mapService.showSidebar.pipe(skip(1), take(1)).subscribe((x) => {
			if (x === undefined) {
				this.show = true;
				this.customPins = undefined;
				this.getCustomMarkers();
			}
		});
	};

	searchValue = async (value) => {
		try {
			document.body.style.cursor = 'progress';
			const states = this.auth.currentUserValue.states.map((s) => s.id).join('-');
			const result = await this.markerService.getMarkers({
				params: btoa(value.toString()),
				states,
				long: this.mapService.mapParamas.lng,
				lat: this.mapService.mapParamas.lat,
				layerId: this.selectedLayer.id,
				byApi: typeof value === 'string' || value instanceof String ? 'false' : 'true',
			});
			this.filteredMarkers = result.data.markers as RecentSearchDto[];
		} catch (err) {
			throwError(err);
		} finally {
			document.body.style.cursor = 'default';
		}
	};

	private filterData = () => {
		if (this.selectedPanel === PanelTabOptions.LAYER) {
			if (this.filterValue.value.length > 2) {
				this.searchValue(+this.filterValue.value ? +this.filterValue.value : this.filterValue.value);
			} else if (this.filterValue.value.length === 0) {
				this.filteredMarkers = this.markers;
			}
		}
		this.filteredCustomPins = this.customPins?.filter((customPin) => {
			return customPin.name.toLowerCase().includes(this.filterValue.value.toLowerCase());
		});
		this.filteredFavorites = this.favorites?.filter((favorite) => {
			return (
				favorite.nickname.toLowerCase().includes(this.filterValue.value.toLowerCase()) ||
				favorite.apiNum.includes(this.filterValue.value)
			);
		});
		this.filteredCustomFolders = this.customFolders?.filter((customFolder) => {
			return customFolder.name.toLowerCase().includes(this.filterValue.value.toLowerCase());
		});
		this.filteredFolders = this.folders?.filter((folder) => {
			return folder.name.toLowerCase().includes(this.filterValue.value.toLowerCase());
		});
	};
}
