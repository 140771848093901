import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-verification',
	templateUrl: './verification.component.html',
})
export class VerificationComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}
}
