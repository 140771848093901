import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'layerName',
})
export class LayerNamePipe implements PipeTransform {
	transform(layerId: string): string {
		switch (layerId) {
			case 'WELLS':
				return 'Well';
			case 'SWD':
				return 'SWD';
			case 'RIGS':
				return 'Rig';
			case 'CA_WELLS':
				return 'Well';
			case 'CA_SWD':
				return 'SWD';
      case 'IN_WELLS':
        return 'Well';
		}
	}
}
