import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../types/dtos/service';
import { ImageDto } from '../types/dtos/models';

@Injectable({
	providedIn: 'root',
})
export class ImageService {
	private apiHost = `${environment.apiHost}/image`;

	constructor(private http: HttpClient) { }

	public uploadImage = async (imageDto: ImageDto): Promise<ApiResponse<{ id: string }>> =>
		this.http
			.post<ApiResponse<{ id: string }>>(`${this.apiHost}/upload`, imageDto, { withCredentials: true })
			.toPromise();

	public signImage = async (id: string): Promise<ApiResponse<{ url: string }>> =>
		this.http
			.post<ApiResponse<{ url: string }>>(`${this.apiHost}/sign`, { id: id }, { withCredentials: true })
			.toPromise();
}