import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';

import {StateDto, UserTracking} from '../types/dtos/models';

@Injectable({
	providedIn: 'root',
})
export class StateService {
	private apiHost = `${environment.apiHost}/state`;

	constructor(private http: HttpClient) {}

	public getAllStates = async (): Promise<ApiResponse<StateDto[]>> =>
		this.http
			.get<ApiResponse<StateDto[]>>(`${this.apiHost}`, {
				withCredentials: true,
			})
			.toPromise();

	public getAllUserStates = async (userId: number): Promise<ApiResponse<StateDto[]>> =>
		this.http
			.get<ApiResponse<StateDto[]>>(`${this.apiHost}/${userId}`, {
				withCredentials: true,
			})
			.toPromise();

	public updateUserStates = async (newUserStates: string[], userId: number): Promise<ApiResponse<StateDto[]>> =>
		this.http
			.post<ApiResponse<StateDto[]>>(
				`${this.apiHost}/${userId}`,
				{states: newUserStates},
				{
					withCredentials: true,
				}
			)
			.toPromise();
}
