<div class="modal confirmation" *ngIf="showModal">
	<div class="modal-content">
		<i class="icon-close" (click)="confirm(false)"></i>
		<h1 class="title">{{ title }}</h1>
		<div class="modal-body">
			<p>
				{{ message }}
			</p>
		</div>
		<div class="buttons">
			<button class="btn btn-white" (click)="confirm(false)">{{ rejectText }}</button>
			<button class="btn" (click)="confirm(true)">{{ confirmationText }}</button>
		</div>
	</div>
</div>
