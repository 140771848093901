import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {LoaderService} from 'src/app/services/loader.service';
import {PlanService} from 'src/app/services/plan.service';
import {ToastService} from 'src/app/services/toast.service';
import {InviteUserToPlanDto} from 'src/app/types/dtos/models';
import {ERROR_CODES} from 'src/app/types/enums';

@Component({
	selector: 'app-confirm-email-modal',
	templateUrl: './confirm-email-modal.component.html',
	styleUrls: ['./confirm-email-modal.component.scss'],
})
export class ConfirmEmailModalComponent implements OnInit {
	public email: string;
	public form: UntypedFormGroup = undefined;
	public availablePlans;
	public selectedPlan;
	public showNotificationOutOfSeats = false;
	private inviteUserToPlan: InviteUserToPlanDto;
	@Output() closeModal = new EventEmitter();
	thenBlock: TemplateRef<any> | null = null;
	@ViewChild('emailBlock', {static: true})
	EmailBlock: TemplateRef<any> | null = null;
	@ViewChild('confirmationBlock', {static: true})
	ConfirmationBlock: TemplateRef<any> | null = null;

	constructor(
		private planService: PlanService,
		private route: Router,
		private toastService: ToastService,
		private loaderService: LoaderService
	) {}

	ngOnInit(): void {
		this.thenBlock = this.EmailBlock;
		this.getAvailablePlans();
	}

	private setForm = () => {
		this.form = new UntypedFormGroup({
			email: new UntypedFormControl(null, [Validators.required, Validators.email]),
			businessPlanId: new UntypedFormControl(this.selectedPlan, [Validators.required]),
		});
	};

	public sendMail = () => {
		window.open('mailto:info@wellsitenavigator.com', '_blank');
	};

	getAvailablePlans = async () => {
		try {
			this.loaderService.show();
			const result = await this.planService.getPlans();
			const availablePlans = result.data;
			if (!availablePlans) {
				this.toastService.show({severity: 'error', summary: `You don't have seats on your business plans.`});
				this.closeModal.emit();
			} else {
				if (availablePlans.length > 1) {
					this.availablePlans = availablePlans;
				}
				this.selectedPlan = availablePlans[0].id;
			}
		} catch (err) {
			this.toastService.show({
				severity: 'error',
				summary: `Couldn't load your business plans, please try again later.`,
			});
			this.closeModal.emit();
		} finally {
			this.loaderService.hide();
		}
		this.setForm();
	};

	public send = async () => {
		try {
			this.loaderService.show();
			const formResult = this.form.getRawValue();
			const result = await this.planService.inviteAllowed(formResult);
			const {restricted, exists, alreadyInPlan, userId} = result.data;

			if (restricted) {
				this.toastService.show({
					severity: 'error',
					summary: `We were not able to process your request at this time. Please try again later or contact us.`,
				});
			} else if (!exists) {
				this.route.navigateByUrl('/dashboard/user', {
					state: {email: formResult.email, selectedPlan: formResult.businessPlanId},
				});
			} else if (alreadyInPlan) {
				this.toastService.show({
					severity: 'error',
					summary: `This user already belongs to your business plan.`,
				});
			} else {
				this.inviteUserToPlan = {businessPlanId: formResult.businessPlanId, invitedUserId: userId};
				this.thenBlock = this.ConfirmationBlock;
			}
		} catch (err) {
			if (err.error.error === ERROR_CODES.INVALID_TRANSACTION) {
				this.showNotificationOutOfSeats = true;
			}
			this.toastService.show({
				severity: 'error',
				summary: `${err.error?.message || 'Could not process this request now. Please try again later.'}`,
			});
		} finally {
			this.loaderService.hide();
		}
	};

	public onConfirmation = async () => {
		this.thenBlock = this.EmailBlock;

		try {
			this.loaderService.show();
			await this.planService.inviteUserToBusinessPlan(this.inviteUserToPlan);
			this.toastService.show({severity: 'success', summary: `Invitation sent to ${this.form.value.email}.`});
		} catch (err) {
			if (err.error.error === ERROR_CODES.INVALID_TRANSACTION) {
				this.showNotificationOutOfSeats = true;
			}
			this.toastService.show({
				severity: 'error',
				summary: `${err.error?.message || 'Could not process this request now. Please try again later.'}`,
			});
		} finally {
			this.loaderService.hide();
		}
		this.closeModal.emit();
	};
}
