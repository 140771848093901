<nav class="sidebar">
	<a href="https://www.wellsitenavigator.com" target="_blank">
		<figure class="logo">
			<img src="../../../../assets/img/wellslogo-color.svg" />
		</figure>
	</a>

	<ul>
		<li *ngIf="menuItemsShow.SEARCH_LAYER">
			<span
				[routerLink]="['/dashboard/map', 'search']"
				[ngClass]="{active: router.url.includes('/dashboard/map/search')}"
			>
				<i class="icon-search"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.FAVORITES">
			<span
				[routerLink]="['/dashboard/map', 'favorites']"
				[ngClass]="{active: router.url.includes('/dashboard/map/favorites')}"
			>
				<i class="icon-heart"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.PINS">
			<span
				[routerLink]="['/dashboard/map', 'pins']"
				[ngClass]="{active: router.url.includes('/dashboard/map/pins')}"
			>
				<i class="icon-location"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.USERS_LOCATION">
			<span
				[routerLink]="['/dashboard/map', 'user-tracking']"
				[ngClass]="{active: router.url.includes('/dashboard/map/user-tracking')}"
			>
				<i class="icon-map-user"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.ROUTES" class="routes">
			<span
				[routerLink]="['/dashboard/map/routing']"
				[ngClass]="{active: router.url.includes('/dashboard/map/routing')}"
			>
				<i class="icon-routing"></i>
				<span [ngClass]="{notification: showRecordedRouteNotificationBadge}"></span>
			</span>
		</li>
		<li class="chat" *ngIf="menuItemsShow.CHAT">
			<span [routerLink]="['/dashboard/chat']" [ngClass]="{active: router.url.includes('/dashboard/chat')}">
				<i class="icon-chat"></i>
				<span [ngClass]="{notification: notification}"></span>
			</span>
		</li>
		<li *ngIf="menuItemsShow.USERS">
			<span [routerLink]="['/dashboard/users']" [ngClass]="{active: router.url.includes('/dashboard/users')}">
				<i class="icon-users"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.VEHICLE_PROFILES">
			<span [routerLink]="['/dashboard/trucks']" [ngClass]="{active: router.url.includes('/dashboard/trucks')}">
				<i class="icon-truck"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.INSIGHTS_DASHBOARD">
			<span (click)="redirectToInsights()">
				<i class="icon-insight-dashboard"></i>
			</span>
		</li>
		<li *ngIf="menuItemsShow.SETTINGS">
			<span
				[routerLink]="['/dashboard/settings']"
				[ngClass]="{active: router.url.includes('/dashboard/settings')}"
			>
				<i class="icon-settings"></i>
			</span>
		</li>
	</ul>

	<div class="avatar-initials" (click)="showLogoutPopup = !showLogoutPopup">
		<span>{{ firstName | slice: 0:1 | uppercase }}{{ lastName | slice: 0:1 | uppercase }}</span>
	</div>

	<div class="logout-popup" [ngClass]="{show: showLogoutPopup}">
		<span class="name">{{ firstName }} {{ lastName }}</span>
		<span class="logout" (click)="logout()">Sign out</span>
	</div>
</nav>

<app-notification-modal
	*ngIf="nextNotification"
	[notification]="nextNotification"
	(closeModal)="closeNotificationModal(nextNotification)"
></app-notification-modal>
