import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject, Subject, throwError} from 'rxjs';
import {
	getGeoHashlevel,
	getMarkerRequest,
	createMapMarkers,
	getPinnedCustomMarker,
	getAllPinsLayers,
	createMapMarker,
	createClusterDto,
} from '../helpers/mapHelper';
import {MarkerService} from './marker.service';
import {
	MultiStopDto,
	MultiStopRouteDto,
	GeoLocationDto,
	UserTracking,
	GeoNoteDto,
	MarkerDto,
	ClusterDto,
	FavoriteDto,
	MapMarker,
	LayerDto,
	ShowCenterPinOnMap,
	MapDrawCustomRouteData,
	ShowSidebar,
	CountryState,
} from '../types/dtos/models';
import {FavoriteService} from './favorite.service';
import {AuthService} from './auth.service';
import {LatLngLiteral} from '@agm/core';
import {COLORS, DrawTools} from '../types/enums';
import {LoaderService} from './loader.service';
import {UserService} from './user.service';
import {LAYER_TYPES} from '../types/constants';
import {StateService} from './states.service';
import { RecipientLicenseService } from './recipientLicense.service';
import * as moment from 'moment';

export interface MapStatus {
	layer: LayerDto;
	favorite: boolean;
	favoriteFolders?: number;
	showNearbyLayer: boolean;
	showNearbyPins: boolean;
	states: CountryState[];
	wellsPins: MapMarker[];
	swdPins: MapMarker[];
	caWellsPins: MapMarker[];
	caSwdPins: MapMarker[];
  inWellsPins: MapMarker[];
	ringsPins: MapMarker[];
	fracPins: MapMarker[];
	recentAddress: google.maps.places.AutocompletePrediction[];
	recentLatLong: MapMarker[];
}

export interface MapParams {
	lat: number;
	lng: number;
	zoom: number;
}

@Injectable({
	providedIn: 'root',
})
export class MapService {
	private mapReferenceSubject: BehaviorSubject<google.maps.Map> = new BehaviorSubject<google.maps.Map>(null);
	public currentMapReference: Observable<any> = this.mapReferenceSubject.asObservable();
	private mapSubject: BehaviorSubject<MapStatus> = new BehaviorSubject<MapStatus>(undefined);
	public currentMapStatus: Observable<MapStatus> = this.mapSubject.asObservable();
	private markerSubject: BehaviorSubject<MapMarker[]> = new BehaviorSubject<MapMarker[]>([]);
	public currentMarkers: Observable<MapMarker[]> = this.markerSubject.asObservable();
	private clusterSubject: BehaviorSubject<ClusterDto[]> = new BehaviorSubject<ClusterDto[]>([]);
	public currentClusters: Observable<ClusterDto[]> = this.clusterSubject.asObservable();
	private currentLocationSubject: BehaviorSubject<LatLngLiteral> = new BehaviorSubject<LatLngLiteral>(undefined);
	public currentLocation: Observable<LatLngLiteral> = this.currentLocationSubject.asObservable();

	private mapCenterSubject: Subject<LatLngLiteral> = new Subject<LatLngLiteral>();
	public mapCenter: Observable<LatLngLiteral> = this.mapCenterSubject.asObservable();
	private actualMapCenterSubject: BehaviorSubject<LatLngLiteral>;
	public mapCurrentCenter: Observable<LatLngLiteral>;
	private mapShowCenterPinSubject: Subject<ShowCenterPinOnMap> = new Subject<ShowCenterPinOnMap>();
	public mapShowCenterPin: Observable<ShowCenterPinOnMap> = this.mapShowCenterPinSubject.asObservable();

	private drawCustomRouteOnMapSubject: BehaviorSubject<MapDrawCustomRouteData> =
		new BehaviorSubject<MapDrawCustomRouteData>({active: false});
	public drawCustomRouteOnMap: Observable<MapDrawCustomRouteData> = this.drawCustomRouteOnMapSubject.asObservable();

	private favoriteSubject: Subject<{added: boolean} & FavoriteDto> = new Subject<{added: boolean} & FavoriteDto>();
	public favoriteChanges: Observable<{added: boolean} & FavoriteDto> = this.favoriteSubject.asObservable();

	private layerSubject: BehaviorSubject<LayerDto> = new BehaviorSubject<LayerDto>(undefined);
	public layerStatus: Observable<LayerDto> = this.layerSubject.asObservable();

	private showSidebarSubject: BehaviorSubject<ShowSidebar> = new BehaviorSubject<ShowSidebar>(undefined);
	public showSidebar: Observable<ShowSidebar> = this.showSidebarSubject.asObservable();

	private addNote: Subject<GeoNoteDto> = new Subject<GeoNoteDto>();
	public addNote$ = this.addNote.asObservable();
	private toolSelected: Subject<string> = new Subject<string>();
	public toolSelected$ = this.toolSelected.asObservable();
	private fitBoundsSubject: Subject<void>= new Subject<void>();
	public fitBounds$ = this.fitBoundsSubject.asObservable();

	public mapParamas: MapParams;
	private markerSelected: MarkerDto;
	private addressSelected: MapMarker;
	private favorites: FavoriteDto[] = [];
	public filteredFavorites: FavoriteDto[];
	public customMarkers: MapMarker[] = [];
	private lockZoomLevelValue: BehaviorSubject<boolean>;
	public lockZoomLevelValue$: Observable<boolean>;
	private zoomLevel: BehaviorSubject<number>;
  public zoomLevel$: Observable<number>;
  public routeAddEdit: boolean = false;

	constructor(
		private markerService: MarkerService,
		private favoriteService: FavoriteService,
		private userService: UserService,
		private auth: AuthService,
		private stateService: StateService
	) {
		const mapCenter = JSON.parse(localStorage.getItem('mapCenter'));
		const zoomLevel = localStorage.getItem('zoomLevel');
    const lockZoomLevelItem = localStorage.getItem('lockZoomLevel');

		this.lockZoomLevelValue = new BehaviorSubject<boolean>(lockZoomLevelItem === 'true');
		this.lockZoomLevelValue$ = this.lockZoomLevelValue.asObservable();

		if (zoomLevel && lockZoomLevelItem === 'true') {
			this.zoomLevel = new BehaviorSubject<number>(parseInt(zoomLevel));
		} else {
			this.zoomLevel = new BehaviorSubject<number>(14);
			localStorage.setItem('zoomLevel', '14');
		}
		this.zoomLevel$ = this.zoomLevel.asObservable();

		if (mapCenter) {
			this.actualMapCenterSubject = new BehaviorSubject<LatLngLiteral>(mapCenter);
		} else {
			this.actualMapCenterSubject = new BehaviorSubject<LatLngLiteral>(undefined);
		}
		this.mapCurrentCenter = this.actualMapCenterSubject.asObservable();

		this.mapSubject = new BehaviorSubject<MapStatus>(JSON.parse(localStorage.getItem('mapStatus')));

		let ms: MapStatus = JSON.parse(localStorage.getItem('mapStatus'));

		if (ms) {
			// TODO: Migration fix should be removed after all users have the new map status
			// ms = this.migrationFix(ms);
      ms.inWellsPins ??= [];
		}
		this.mapSubject = new BehaviorSubject<MapStatus>(ms);
		this.auth.currentUser.subscribe((authResult) => {
			if (!authResult) {
				localStorage.removeItem('mapStatus');
				this.mapSubject.next(null);
			} else {
				if (!this.mapSubject.value) {
					this.setMapStatus({
						layer:
							authResult.layers.find((layer) => layer.id === LAYER_TYPES.WELLS) ||
							authResult.layers[0] ||
							null,
						favorite: false,
						showNearbyLayer: false,
						showNearbyPins: false,
						states: authResult.states,
						favoriteFolders: 0,
						wellsPins: [],
						caWellsPins: [],
            inWellsPins: [],
						swdPins: [],
						caSwdPins: [],
						ringsPins: [],
						fracPins: [],
						recentAddress: [],
						recentLatLong: [],
					});
				}
				this.layerSubject.next(this.currentStatus.layer);
			}
		});

		this.getFavorites();
		this.getCustomMarker();
		this.getRoutes();
	}

	/**
	 * Migration fix for map status
	 * Canada wells and swd pins were not initialized
	 * States were not initialized with countryId
	 * @param ms
	 */
	private migrationFix = async (ms: MapStatus): Promise<MapStatus> => {
		const states = (await this.stateService.getAllStates()).data;
		ms.caWellsPins ??= [];
		ms.caSwdPins ??= [];
		if (ms.states && ms.states.length > 0) {
			ms.states = ms.states.map((s) => {
				if (typeof s === 'string') {
					const state = states.find((state) => state.id === s);
					if (state) {
						return {id: state.id, countryId: state.countryId};
					}
				}
				return s;
			});
		}
		return ms;
	};

	public addNoteToRoute = (note: GeoNoteDto): void => {
		this.addNote.next(note);
	};
	public selectTool = (tool: string): void => {
		this.toolSelected.next(tool);
		if (tool === DrawTools.NOTE_EXTENDED) {
			this.mapRef.setOptions({draggableCursor: 'crosshair'});
		}
		if (tool === DrawTools.NONE) {
			this.mapRef.setOptions({draggableCursor: 'grab'});
		}
	};

	public fitBounds = () => {
		this.fitBoundsSubject.next();
	}

	public set zoomLevelValue(value: number) {
		this.zoomLevel.next(value);
		localStorage.setItem('zoomLevel', value.toString());
	}

	public get zoomLevelValue(): number {
		return this.zoomLevel.value;
	}

	public get lockZoomLevelData(): Observable<boolean> {
		return this.lockZoomLevelValue.asObservable();
	}

	public set lockZoomLevel(value: boolean) {
		this.lockZoomLevelValue.next(value);
		localStorage.setItem('lockZoomLevel', value.toString());
	}

	set showSidebarPanel(value: ShowSidebar) {
		this.showSidebarSubject.next(value);
	}
	get showSidebarPanel() {
		return this.showSidebarSubject.value;
	}

	public showCenterPinOnMap(show: boolean, color: COLORS = COLORS.DEFAULT) {
		this.mapShowCenterPinSubject.next({show, color});
	}

	public getRoutes = async () => {
		// this.routes = (await this.routeService.getRoutes()).data;
	};

	public get currentStatus(): MapStatus {
		return this.mapSubject.value;
	}

	public get mapCurrentLocation(): LatLngLiteral {
		return this.currentLocationSubject.value;
	}

	public set mapCurrentLocation(location: LatLngLiteral) {
		this.currentLocationSubject.next(location);
	}

	private getFavorites = async () => {
		try {
			this.favorites = (await this.favoriteService.getFavorites()).data;
		} catch (err) {
			throwError(err);
		}
	};

	private getCustomMarker = async () => {
		try {
			this.customMarkers = (await this.markerService.getCustomMarkers()).data.map((marker) => {
				return {
					id: marker.id,
					lat: marker.lat,
					lng: marker.lng,
					name: marker.name,
					color: marker.color || COLORS.BLUE,
					iscustomMarker: true,
				};
			});
		} catch (err) {
			throwError(err);
		}
	};

	private filterFavorite = (): MapMarker[] => {
		this.clusterSubject.next([]);
		const favoriteFolder = this.mapSubject.value.favoriteFolders;
		const filteredFavorites = this.favorites.filter(
			(favorite) =>
				(favoriteFolder === 0 && favorite.rootFolder && favorite.layerId === this.mapSubject.value.layer.id) ||
				favorite.folders.includes(favoriteFolder)
		);
		const mapMarkers = createMapMarkers(filteredFavorites as MapMarker[], this.markerSelected);
		return mapMarkers;
		this.markerSubject.next(mapMarkers);
	};

	public setMapStatus = (mapStatus: MapStatus) => {
		localStorage.setItem('mapStatus', JSON.stringify(mapStatus));
		this.mapSubject.next(mapStatus);
	};

	public getMarkersAndClusters = async (mapParamas: MapParams) => {
		try {
			this.mapParamas = mapParamas;
			let clusters = [];
			let markers = [];
			let mapMarkers = [];
			if (this.mapSubject.value.favorite) {
				mapMarkers = this.filterFavorite();
			} else {
        if (this.mapSubject.value.showNearbyLayer) {
					const geohashLevel = getGeoHashlevel(mapParamas.zoom);
					const markerRequest = getMarkerRequest(mapParamas, geohashLevel, this.mapSubject.value);
					const result = await this.markerService.getMarkersAndClusters(markerRequest);
					markers = !result.data.markers ? [] : result.data.markers.data;
					clusters = result.data.clusters ? result.data.clusters.data : [];
				}
				if (this.mapSubject.value.showNearbyPins) {
					mapMarkers = createMapMarkers(markers, this.markerSelected).concat(
						createMapMarkers(this.customMarkers as MapMarker[], this.markerSelected)
					);
					if (!this.mapSubject.value.showNearbyLayer) {
						const pins = getAllPinsLayers(this.mapSubject.value).filter(
							(pin) => !mapMarkers.find((marker) => marker.iscustomMarker && marker.id === pin.id)
						);
						mapMarkers = mapMarkers.concat(createMapMarkers(pins, this.markerSelected));
					}
        } else {
					const filterCustomMarkers = this.mapSubject.value.showNearbyLayer
						? getPinnedCustomMarker(this.mapSubject.value)
						: getAllPinsLayers(this.mapSubject.value);
					mapMarkers = createMapMarkers(markers, this.markerSelected).concat(
						createMapMarkers(filterCustomMarkers as MapMarker[], this.markerSelected)
					);
				}
			}

			if (this.addressSelected) {
				if (!mapMarkers.find((marker) => marker.id === this.addressSelected.id))
					mapMarkers.push(this.addressSelected as MarkerDto);
			}
			if (this.markerSelected) {
				if (!mapMarkers.find((marker) => marker.id === this.markerSelected.id))
					mapMarkers.push(createMapMarker(this.markerSelected, this.markerSelected));
			}

			this.clusterSubject.next(clusters.map(createClusterDto));
			this.markerSubject.next(mapMarkers);
		} catch (err) {
			throwError(err);
		}
	};

	setSelectedMarker = (marker: MarkerDto) => {
		this.markerSelected = marker;
		this.addressSelected = undefined;
		if (this.markerSelected && this.markerSelected.favorite) {
			this.markerSelected.color = this.markerSelected.favorite.color;
		}
		this.getMarkersAndClusters({
			zoom: this.mapParamas.zoom < 14 ? 14 : this.mapParamas.zoom,
			lat: this.markerSelected ? this.markerSelected.lat : this.mapParamas.lat,
			lng: this.markerSelected ? this.markerSelected.lng : this.mapParamas.lng,
		});
	};

	changeLayer = (layerId: String) => {
		if (this.mapSubject.value.layer.id !== layerId) {
			const selectedLayer = this.auth.currentUserValue.layers.find((layer) => layer.id === layerId);
			const {layer, ...map} = this.mapSubject.value;
			this.layerSubject.next(selectedLayer);
			this.setMapStatus({layer: selectedLayer, ...map});
			this.getMarkersAndClusters(this.mapParamas);
		}
	};

	public changeMapStatus = (newMapStatus: MapStatus) => {
		if (this.mapSubject.value !== newMapStatus) {
			this.setMapStatus(newMapStatus);
			this.getMarkersAndClusters(this.mapParamas);
		}
	};

	public clearPinned = (layerId?) => {
		let {wellsPins, swdPins, ringsPins, fracPins, ...currentUser} = this.currentStatus;
		switch (layerId) {
			case 'WELLS':
				wellsPins = [];
				this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, ...currentUser});
				break;
			case 'SWD':
				swdPins = [];
				this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, ...currentUser});
				break;
			case 'RIGS':
				ringsPins = [];
				this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, ...currentUser});
				break;
			case 'FRAC':
				fracPins = [];
				this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, ...currentUser});
				break;
			case 'DRILL_PERMITS':
				this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, ...currentUser});
				break;
      default:
        wellsPins = []
        swdPins = []
        ringsPins = []
        fracPins = []
				this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, ...currentUser});
				break;
		}
	};

	private removeFromArray = (array: MapMarker[], marker: MapMarker): boolean => {
		const index = array.findIndex((item) => item.id === marker.id);
		if (index > -1) {
			array.splice(index, 1);
			return true;
		}
		return false;
	};
	addPin = (marker: MapMarker) => {
		const {wellsPins, swdPins, ringsPins, fracPins, caWellsPins, caSwdPins, inWellsPins, ...currentUser} = this.currentStatus;
		switch (this.currentStatus.layer.id) {
			case 'WELLS':
				wellsPins.push(marker);
				break;
			case 'CA_WELLS':
				caWellsPins.push(marker);
				break;
      case 'IN_WELLS':
        inWellsPins.push(marker);
        break;
			case 'SWD':
				swdPins.push(marker);
				break;
			case 'CA_SWD':
				swdPins.push(marker);
				break;
			case 'RIGS':
				ringsPins.push(marker);
				break;
			case 'FRAC':
				fracPins.push(marker);
				break;
		}
    this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, caWellsPins, caSwdPins, inWellsPins,...currentUser});
	};

	removePin = (marker: MapMarker) => {
		const {wellsPins, swdPins, ringsPins, fracPins, caWellsPins, caSwdPins, inWellsPins, ...currentUser} = this.currentStatus;
		let changed = false;
		switch (this.currentStatus.layer.id) {
			case 'WELLS':
				changed = this.removeFromArray(wellsPins, marker);
				break;
			case 'CA_WELLS':
				changed = this.removeFromArray(caWellsPins, marker);
				break;
      case 'IN_WELLS':
        changed = this.removeFromArray(inWellsPins, marker);
        break;
			case 'SWD':
				changed = this.removeFromArray(swdPins, marker);
				break;
			case 'CA_SWD':
				changed = this.removeFromArray(caSwdPins, marker);
				break;
			case 'RIGS':
				changed = this.removeFromArray(ringsPins, marker);
				break;
			case 'FRAC':
				changed = this.removeFromArray(fracPins, marker);
				break;
		}
		if (changed) {
			this.changeMapStatus({wellsPins, swdPins, ringsPins, fracPins, caWellsPins, caSwdPins, inWellsPins, ...currentUser});
		}
	};

	addFavorite = (fav: FavoriteDto) => {
		this.favorites.push(fav);
		this.getMarkersAndClusters(this.mapParamas);
		this.favoriteSubject.next({added: true, ...fav});
	};

	removeFavorite = (fav: FavoriteDto) => {
		if (this.markerSelected && this.markerSelected.id === fav.id) {
			this.markerSelected.color = 'DEFAULT';
		}
		const favorite = this.favorites.find((currentFavorite) => currentFavorite.id === fav.id);
		if (favorite) {
			const index = this.favorites.indexOf(favorite);
			this.favorites.splice(index, 1);
			this.getMarkersAndClusters(this.mapParamas);
		}
		this.favoriteSubject.next({added: false, ...fav});
	};

	setAddress = (address: MapMarker) => {
		this.addressSelected = address;
		this.mapCenterSubject.next({lat: address.lat, lng: address.lng});
		this.getMarkersAndClusters({
			lat: address.lat,
			lng: address.lng,
			zoom: this.mapParamas.zoom,
		});
	};

	setAddressWithoutMarker = (address: MapMarker) => {
		this.addressSelected = address;
		this.mapCenterSubject.next({lat: address.lat, lng: address.lng});
	};

	removeAddress = () => {
		this.addressSelected = undefined;
		this.getMarkersAndClusters(this.mapParamas);
	};

	centerMap = (latLng: LatLngLiteral) => {
		this.mapCenterSubject.next(latLng);
	};

	addCustomPin = () => {
		this.getCustomMarker();
		this.getMarkersAndClusters(this.mapParamas);
	};

	editCustomPin = (pin: MarkerDto) => {
		const marker = this.customMarkers.findIndex((marker) => marker.id === pin.id);
		this.customMarkers[marker] = pin;
	};

	public get mapCenterActual() {
		return this.actualMapCenterSubject.value;
	}

	public set mapCenterActual(value: LatLngLiteral) {
		this.actualMapCenterSubject.next(value);
		localStorage.setItem('mapCenter', JSON.stringify(value));
	}

	// Draw on map
	public get drawStatus() {
		return this.drawCustomRouteOnMapSubject.value || ({} as MapDrawCustomRouteData);
	}
	public set drawStatus(value: MapDrawCustomRouteData) {
		this.drawCustomRouteOnMapSubject.next(value);
	}

	// Set map reference
	public get mapRef(): google.maps.Map {
		return this.mapReferenceSubject.value;
	}
	public set mapRef(value: google.maps.Map) {
		this.mapReferenceSubject.next(value);
	}
}
