export type SearchResultType = 'USER' | 'ENTERPRISE';

export type LayerType = 'WELLS' | 'SWD' | 'RIGS' | 'FRAC';
export const LayerList = [
	{id: 'WELLS', countryId: 'US'},
	{id: 'CA_WELLS', countryId: 'CA'},
	{id: 'SWD', countryId: 'US'},
	{id: 'CA_SWD', countryId: 'CA'},
	{id: 'RIGS', countryId: 'US'},
	{id: 'FRAC', countryId: 'US'},
	{id: 'IN_WELLS', countryId: 'IN'},
];

export enum MarkerIconFile {
	NOTE = 'ic_note.svg',
	PIN_BLUE = 'ic_marker_pin_blue.svg',
	PIN_DEFAULT = 'ic_marker_pin_default.svg',
	TURN_LEFT = 'ic_turn_left.svg',
	TURN_RIGHT = 'ic_turn_right.svg',
}

export type MapPageType = 'map' | 'search' | 'favorites' | 'pins' | 'user-tracking' | 'routing';

export enum MarkerType {
	MARKER = 'MARKER',
	CUSTOM_MARKER = 'CUSTOM_MARKER',
}

export enum ItemType {
	NOTE = 'NOTE',
	MARKER = 'MARKER',
	SURVEY = 'SURVEY',
	STOP = 'STOP',
	START_POINT = 'START_POINT',
	END_POINT = 'END_POINT',
	TURN_LEFT = 'TURN_LEFT',
	TURN_RIGHT = 'TURN_RIGHT',
}

export enum ContentType {
	IMAGE = 'image',
	AUDIO = 'audio',
}

export enum DrawTools {
	POLYLINE = 'POLYLINE',
	NOTE = 'NOTE',
	SELECT = 'SELECT',
	MARKER = 'MARKER',
	NOTE_EXTENDED = 'NOTE_EXTENDED',
	NONE = 'NONE',
}

export enum OSRMRouteType {
	OPTIMAL_ROUTE = 'optimal_route',
	CUSTOM_STOPS = 'custom_stops',
	OPTIMAL_ROUTE_FIXED_DESTINATION = 'optimal_route_fixed_destination',
	OPTIMAL_ROUNDTRIP = 'optimal_roundtrip',
}

export enum COLORS {
	DEFAULT = 'DEFAULT',
	WHITE = 'WHITE',
	BLACK = 'BLACK',
	BLUE = 'BLUE',
	GREEN = 'GREEN',
	YELLOW = 'YELLOW',
	ORANGE = 'ORANGE',
	RED = 'RED',
	PINK = 'PINK',
	CUSTOM = 'CUSTOM',
}

export enum FEATURES {
	CHAT = 'CHAT',
	CAN_ACCESS_PORTAL = 'CAN_ACCESS_PORTAL',
	CUSTOM_ROUTES = 'CUSTOM_ROUTES',
	ENTERPRISE_BASIC = 'ENTERPRISE_BASIC',
	ENTERPRISE_DISPATCH = 'ENTERPRISE_DISPATCH',
	ENTERPRISE_PLUS = 'ENTERPRISE_PLUS',
	HAZMAT_ROUTES = 'HAZMAT_ROUTES',
	COMPANY_DEFAULT_HAZMAT_ROUTE = 'COMPANY_DEFAULT_HAZMAT_ROUTE',
	SHARE_BETWEEN_PORTAL_USERS = 'SHARE_BETWEEN_PORTAL_USERS',
	RECORD_ROUTES = 'RECORD_ROUTES',
	DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT = 'DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT',
	COMPANY_ALLOW_ROUTE_URLS = 'COMPANY_ALLOW_ROUTE_URLS',
	AUTOMATED_ROUTE_NOTES = 'AUTOMATED_ROUTE_NOTES',
	CREATE_EXTERNAL_SHARE_LINKS = 'CREATE_EXTERNAL_SHARE_LINKS',
	CREATE_ROUTE_RETURN = 'CREATE_ROUTE_RETURN',
	INSIGHTS_DASHBOARD = 'INSIGHTS_DASHBOARD',
}

export enum USER_PERMISSIONS {
	RESTRICT_CREATE_VEHICLE_PROFILE = 'RESTRICT_CREATE_VEHICLE_PROFILE',
	RESTRICT_UPDATE_VEHICLE_PROFILE = 'RESTRICT_UPDATE_VEHICLE_PROFILE',
	RESTRICT_DELETE_VEHICLE_PROFILE = 'RESTRICT_DELETE_VEHICLE_PROFILE',
	RESTRICT_CREATE_HAZMAT_ROUTES = 'RESTRICT_CREATE_HAZMAT_ROUTES',
	RESTRICT_CHANGE_FROM_HAZMAT_ROUTE = 'RESTRICT_CHANGE_FROM_HAZMAT_ROUTE',
	RESTRICT_SHARE_RECORDED_ROUTES = 'RESTRICT_SHARE_RECORDED_ROUTES',
	RESTRICT_DOWNLOAD_RECORDED_ROUTES = 'RESTRICT_DOWNLOAD_RECORDED_ROUTES',
	ALLOW_CREATE_VEHICLE_PROFILE = 'ALLOW_CREATE_VEHICLE_PROFILE',
	ALLOW_UPDATE_VEHICLE_PROFILE = 'ALLOW_UPDATE_VEHICLE_PROFILE',
	ALLOW_DELETE_VEHICLE_PROFILE = 'ALLOW_DELETE_VEHICLE_PROFILE',
	ALLOW_CREATE_HAZMAT_ROUTES = 'ALLOW_CREATE_HAZMAT_ROUTES',
	ALLOW_CHANGE_FROM_HAZMAT_ROUTE = 'ALLOW_CHANGE_FROM_HAZMAT_ROUTE',
	ALLOW_DOWNLOAD_RECORDED_ROUTES = 'ALLOW_DOWNLOAD_RECORDED_ROUTES',
}

export enum COMPANY_PERMISSIONS {
	COMPANY_DEFAULT_HAZMAT_ROUTE = 'COMPANY_DEFAULT_HAZMAT_ROUTE',
	COMPANY_RESTRICT_CREATE_VEHICLE_PROFILE = 'COMPANY_RESTRICT_CREATE_VEHICLE_PROFILE',
	COMPANY_RESTRICT_UPDATE_VEHICLE_PROFILE = 'COMPANY_RESTRICT_UPDATE_VEHICLE_PROFILE',
	COMPANY_RESTRICT_DELETE_VEHICLE_PROFILE = 'COMPANY_RESTRICT_DELETE_VEHICLE_PROFILE',
	COMPANY_RESTRICT_CREATE_HAZMAT_ROUTES = 'COMPANY_RESTRICT_CREATE_HAZMAT_ROUTES',
	COMPANY_RESTRICT_CHANGE_FROM_HAZMAT_ROUTE = 'COMPANY_RESTRICT_CHANGE_FROM_HAZMAT_ROUTE',
	COMPANY_SHARE_RECORDED_ROUTES = 'COMPANY_SHARE_RECORDED_ROUTES',
	COMPANY_DOWNLOAD_RECORDED_ROUTES = 'COMPANY_DOWNLOAD_RECORDED_ROUTES',
}

export enum StopType {
	MARKER = 'MARKER',
	COORDINATES = 'COORDINATE',
	START = 'START',
	USER = 'USER',
	FAVORITE = 'FAVORITE',
	WAYPOINT = 'WAYPOINT',
}

export type severityType = 'success' | 'info' | 'warn' | 'error' | 'custom';

export enum ShareTypes {
	ANY = 'ANY',
	COMPANY_USERS = 'COMPANY_USERS',
	THEMSELVES = 'THEMSELVES',
}

export enum ERROR_CODES {
	// third party services errors
	REVENUE_CAT_HOOK_PLAN = 'REVENUE_CAT_HOOK_PLAN',
	REVENUE_CAT_HOOK_IDS_INCONSISTENCY = 'REVENUE_CAT_HOOK_IDS_INCONSISTENCY',
	REVENUE_CAT_HOOK_CATCH = 'REVENUE_CAT_HOOK_CATCH',
	REVENUE_CAT_API_PLAN = 'REVENUE_CAT_API_PLAN',
	REVENUE_CAT_API_IDS_INCONSISTENCY = 'REVENUE_CAT_API_IDS_INCONSISTENCY',
	REVENUE_CAT_API_CATCH = 'REVENUE_CAT_API_CATCH',
	BRANCH_UNAVAILABLE = 'BRANCH_UNAVAILABLE',
	STRIPE_HOOK_CATCH = 'STRIPE_HOOK_CATCH',
	FRIENDBUY_TRACK_PURCHASE = 'FRIENDBUY_TRACK_PURCHASE',
	FRIENDBUY_TRACK_SIGNUP = 'FRIENDBUY_TRACK_SIGNUP',
	HERE_ERROR_GETTING_ROUTE = 'HERE_ERROR_GETTING_ROUTE',
	HERE_ROUTE_VIOLATES_RESTRICTIONS = 'HERE_ROUTE_VIOLATES_RESTRICTIONS',

	// social
	GOOGLE_ID_EXISTS = 'GOOGLE_ID_EXISTS',
	FACEBOOK_ID_EXISTS = 'FACEBOOK_ID_EXISTS',
	APPLE_ID_EXISTS = 'APPLE_ID_EXISTS',
	USER_NOT_EXISTS_GOOGLE_ID = 'USER_NOT_EXISTS_GOOGLE_ID',
	USER_NOT_EXISTS_APPLE_ID = 'USER_NOT_EXISTS_APPLE_ID',
	USER_NOT_EXISTS_FACEBOOK_ID = 'USER_NOT_EXISTS_FACEBOOK_ID',

	// logic
	NO_APP_ACCESS = 'NO_APP_ACCESS',
	LAYER_NOT_FOUND = 'LAYER_NOT_FOUND',
	LAYER_NOT_ONLINE = 'LAYER_NOT_ONLINE',
	LAYER_UNAVAILABLE_FOR_USER = 'LAYER_UNAVAILABLE_FOR_USER',
	VERSION_MISMATCH = 'VERSION_MISMATCH',
	EDIT_NOT_ALLOWED = 'EDIT_NOT_ALLOWED',
	BUSINESS_SUBSCRIPTION_EXPIRED = 'BUSINESS_SUBSCRIPTION_EXPIRED',
	UPDATE_REQUIRED = 'UPDATE_REQUIRED',
	BUSINESS_PLAN_INVITE_NOT_FOUND = 'BUSINESS_PLAN_INVITE_NOT_FOUND',
	CREATE_ALL_COMPANY_USER_THREADS = 'CREATE_ALL_COMPANY_USER_THREADS',
	MAX_VEHICLE_PROFILE_PER_USER = 'MAX_VEHICLE_PROFILE_PER_USER',

	// common
	NOT_FOUND = 'NOT_FOUND',
	BAD_REQUEST = 'BAD_REQUEST',
	EMAIL_NOT_EXISTS = 'EMAIL_NOT_EXISTS',
	EMAIL_EXISTS = 'EMAIL_EXISTS',
	INVALID_PASSWORD = 'INVALID_PASSWORD',
	PASSWORDS_DONT_MATCH = 'PASSWORDS_DONT_MATCH',
	INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
	CREDENTIALS_MISMATCH = 'CREDENTIALS_MISMATCH',
	NOT_AUTHORIZED = 'NOT_AUTHORIZED',
	INVALID_LOGIN = 'INVALID_LOGIN',
	INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
	INVALID_TRANSACTION = 'INVALID_TRANSACTION',
	INVALID_PARAMETERS = 'INVALID_PARAMETERS',
	INVALID_QUERY = 'INVALID_QUERY',
	INVALID_PAYLOAD = 'INVALID_PAYLOAD',
	TOKEN_INVALID = 'TOKEN_INVALID',
	TOKEN_REQUIRED = 'TOKEN_REQUIRED',
	TOKEN_EXPIRED = 'TOKEN_EXPIRED',
	ALREDY_EXISTS = 'ALREDY_EXISTS',
	FORBIDDEN = 'FORBIDDEN',
	NOT_EXTENDED = 'NOT_EXTENDED',

	// sms
	SMS_ERROR = 'SMS_ERROR',
	INVALID_PHONE_NUMBERS = 'INVALID_PHONE_NUMBERS',
	ONE_SIGNAL = 'ONE_SIGNAL',

	OSRM_ERROR = 'OSRM_ERROR',

	// External Users Errors
	LICENSE_NOT_FOUND = 'LICENSE_NOT_FOUND',
	DEVICE_ID_NOT_FOUND = 'DEVICE_ID_NOT_FOUND',
	SHARED_ITEM_NOT_FOUND = 'SHARED_ITEM_NOT_FOUND',
	LICENSES_NUMBER_EXCEEDED = 'LICENSES_NUMBER_EXCEEDED',
}

export enum COLORS_HEX {
	DEFAULT = 'CF4727',
	WHITE = 'FFFFFF',
	BLACK = '000000',
	BLUE = '4F97E9',
	GREEN = '83BC5F',
	YELLOW = 'F6CB6E',
	ORANGE = 'EF9148',
	RED = 'DC555B',
	PINK = 'C02C68',
}
export const ColorList = ['DEFAULT', 'WHITE', 'BLACK', 'BLUE', 'GREEN', 'YELLOW', 'ORANGE', 'RED', 'PINK'];

export enum LANGUAGES {
	ENGLISH = 'en',
	SPANISH = 'es',
}

export enum POPUPS_TO_SHOW {
	ADD_PHONE_NUMBER = 'ADD_PHONE_NUMBER',
}

export enum SIDEBAR_TO_SHOW {
	USER_PROFILE = 'USER_PROFILE',
	USER_SETTINGS = 'USER_SETTINGS',
	USER_FAVORITES = 'USER_FAVORITES',
	USER_PINS = 'USER_PINS',
	USER_TRACKING = 'USER_TRACKING',
	USER_ROUTES = 'USER_ROUTES',
	USER_CHAT = 'USER_CHAT',
	ADD_PIN = 'ADD_PIN',
}

export enum PORTAL_FEATURES {
	SEARCH_LAYER = 'SEARCH_LAYER',
	FAVORITES = 'FAVORITES',
	PINS = 'PINS',
	USERS_LOCATION = 'USERS_LOCATION',
	ROUTES = 'ROUTES',
	CUSTOM_ROUTES = 'CUSTOM_ROUTES',
	CHAT = 'CHAT',
	USERS = 'USERS',
	VEHICLE_PROFILES = 'VEHICLE_PROFILES',
	SETTINGS = 'SETTINGS',
	SHARE_BETWEEN_PORTAL_USERS = 'SHARE_BETWEEN_PORTAL_USERS',
	DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT = 'DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT',
	INSIGHTS_DASHBOARD = 'INSIGHTS_DASHBOARD',
}

export enum HERE_TRANSPORT_MODE {
	car = 'car',
	truck = 'truck',
	pedestrian = 'pedestrian',
	bicycle = 'bicycle',
	scooter = 'scooter',
	taxi = 'taxi',
	privateBus = 'privateBus',
	bus = 'bus',
}

export enum HERE_VEHICLE_CATEGORY {
	lightTruck = 'lightTruck',
	undefined = 'undefined',
}

export enum HERE_WEIGHT_PER_AXLE_GROUP {
	single = 'single',
	tandem = 'tandem',
	triple = 'triple',
}

export enum HERE_VEHICLE_TYPE {
	straightTruck = 'straightTruck',
	tractor = 'tractor',
}

export enum HERE_TUNNEL_CATEGORY {
	B = 'B',
	C = 'C',
	D = 'D',
	E = 'E',
}

export enum HERE_SHIPPED_HAZARDOUS_GOODS {
	explosive = 'explosive',
	gas = 'gas',
	flammable = 'flammable',
	combustible = 'combustible',
	organic = 'organic',
	poison = 'poison',
	radioactive = 'radioactive',
	poisonousInhalation = 'poisonousInhalation',
	harmfulToWater = 'harmfulToWater',
	other = 'other',
}

export enum SHARE_TYPES {
	CUSTOM_ROUTE = 'CUSTOM_ROUTE',
	RECORDED_ROUTE = 'RECORDED_ROUTE',
	ROUTE = 'ROUTE',
}
