<div class="modal share share-location">
	<!-- IF CAN SHARE TO ALL COMPANY USERS -->
	<div class="modal-content user-staff" *ngIf="shareType === 'COMPANY_USERS'">
		<i class="icon-close" (click)="closedModal.emit()"></i>

		<div class="header">
			<div class="location" *ngIf="marker">
				<h1 class="title">Share this location</h1>
				<p class="subtitle">{{ markerName }}</p>
				<span class="api-num">Api #{{ marker.apiNum }}</span>
			</div>

			<div class="location" *ngIf="customMarker">
				<h1 class="title">Share custom pin</h1>
				<p class="subtitle">{{ customMarker.name }}</p>
			</div>

			<div class="share-folder" *ngIf="folders || customFolders">
				<h1 class="title">Select the users you want to share this elements with</h1>
			</div>

			<div class="search-bar">
				<input type="text" [(ngModel)]="valueFilter" />
				<i class="icon-search-bar"></i>
			</div>

			<div class="bottom-options clearfix">
				<a class="recent" *ngIf="users">Users {{ selectedUsers.length + '/' + users.length }}</a>
				<a class="select-all" (click)="selectAll()">Select all</a>
			</div>
		</div>

		<div class="user-list">
			<perfect-scrollbar>
				<ul>
					<li *ngFor="let user of filteredUsers">
						<span class="name">{{ user.firstName + ' ' + user.lastName }}</span>
						<span class="email">{{ user.email }}</span>
						<a>
							<p-checkbox [value]="user" [(ngModel)]="selectedUsers"></p-checkbox>
						</a>
					</li>
				</ul>
			</perfect-scrollbar>
		</div>

		<div class="buttons">
			<button class="btn" (click)="share('SMS')" [disabled]="!selectedUsers.length">Text message</button>
			<button class="btn" (click)="share('EMAIL')" [disabled]="!selectedUsers.length">Email</button>
			<button
				class="btn"
				(click)="share('CHAT')"
				[disabled]="!selectedUsers.length"
				*ngIf="firebaseChat && userIsStaff"
			>
				Send through App
			</button>
			<!-- <button class="btn btn-white" (click)="closedModal.emit()">Cancel</button> -->
		</div>
	</div>

	<!-- IF CAN SHARE TO ANYONE -->
	<div class="modal-content not-user-staff" *ngIf="shareType === 'ANY'">
		<i class="icon-close" (click)="closedModal.emit()"></i>
		<div class="header">
			<div class="location" *ngIf="marker">
				<h1 class="title">Share this location</h1>
				<p class="subtitle">{{ markerName }}</p>
				<span class="api-num">Api #{{ marker.apiNum }}</span>
			</div>

			<div class="location" *ngIf="customMarker">
				<h1 class="title">Share custom pin</h1>
				<p class="subtitle">{{ customMarker.name }}</p>
			</div>

			<div class="share-folder" *ngIf="folders">
				<h1 class="title">Enter email(s) or phone number(s) you want to share with</h1>
			</div>
		</div>
		<div class="user-list">
			<span class="guide">Type in email address or phone number and hit enter:</span>
			<p-chips [(ngModel)]="selectedData" separator="," placeholder="Enter email or phone numbers"></p-chips>
			<span class="guide">Message:</span>
			<textarea [(ngModel)]="message"></textarea>
		</div>
		<div class="buttons">
			<button class="btn" [disabled]="!selectedData.length" (click)="share('CUSTOM')">Send</button>
		</div>
	</div>

	<!-- IF CAN SHARE TO THEMSELVES -->
	<div class="modal-content individual-desktop-portal" *ngIf="shareType === 'THEMSELVES'">
		<i class="icon-close" (click)="closedModal.emit()"></i>
		<div class="header">
			<div class="location" *ngIf="marker">
				<h1 class="title">Share this location</h1>
				<p class="subtitle">{{ markerName }}</p>
				<span class="api-num">Api #{{ marker.apiNum }}</span>
			</div>

			<div class="location" *ngIf="customMarker">
				<h1 class="title">Share custom pin</h1>
				<p class="subtitle">{{ customMarker.name }}</p>
			</div>

			<div class="share-folder" *ngIf="folders">
				<h1 class="title">Select how do you want to share it with you</h1>
			</div>
		</div>
		<div class="buttons">
			<button class="btn" (click)="share('SMS')" [disabled]="!selectedUsers.length">
				Send me by a Text message
			</button>
			<button class="btn" (click)="share('EMAIL')" [disabled]="!selectedUsers.length">Send me by Email</button>
		</div>
	</div>
</div>
