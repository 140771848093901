<div class="page-sidebar favorite" [ngClass]="{show: true}">
	<h2 class="title">
		{{ marker || customMarker ? 'Edit' : 'Add' }} {{ type === CUSTOM_MARKER ? 'Pin' : 'Favorite' }}
	</h2>

	<form *ngIf="form" [formGroup]="form" (ngSubmit)="type === CUSTOM_MARKER ? addCustomMarker() : addFavorite()">
		<input formControlName="nickname" />
		<p-multiSelect
			[options]="folders"
			[virtualScroll]="true"
			[itemSize]="34"
			[styleClass]="'test'"
			formControlName="folders"
		></p-multiSelect>
		<div class="location" *ngIf="type === CUSTOM_MARKER">
			<p class="description">
				{{
					customMarker
						? 'Drag the custom pin on the map to a new location or edit coordinates and name below'
						: 'Click and drag on the map to place a new custom pin'
				}}
			</p>
			<input placeholder="Latitude" type="number" formControlName="lat" step=0.01 (input)="handleLatChange($event)"/>
			<input placeholder="Longitudes" type="number" formControlName="lng" step=0.01 (input)="handleLngChange($event)"/>
		</div>
		<div class="colors">
			<p class="mini-title">Choose {{ type === CUSTOM_MARKER ? "pin's" : "favorite's" }} color</p>
			<ul>
				<li
					*ngFor="let color of colorList"
					[className]="color.toLowerCase()"
					[ngClass]="{active: form.value.color === color}"
					(click)="selectColor(color)"
				></li>
			</ul>
		</div>

		<div class="buttons">
			<button class="btn btn-white" type="button" (click)="close.emit()">Cancel</button>
			<button class="btn" type="submit">Save</button>
		</div>
	</form>
</div>
