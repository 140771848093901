import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {GateDto} from '../types/dtos/models';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class GateService {
	private apiHost = `${environment.apiHost}/gates`;

	constructor(private http: HttpClient, private router: Router) {}

	public AddGate = async (gate: Partial<GateDto>): Promise<ApiResponse<GateDto>> =>
		this.http
			.post<ApiResponse<GateDto>>(this.apiHost, gate, {withCredentials: true})
			.toPromise();

	public daleteGate = async (gateId: number): Promise<ApiResponse<GateDto>> =>
		this.http
			.delete<ApiResponse<GateDto>>(`${this.apiHost}/${gateId}`, {withCredentials: true})
			.toPromise();
}
