import {Pipe, PipeTransform} from '@angular/core';
import {LayerDto, FeatureDto} from 'src/app/types/dtos/models';

@Pipe({
	name: 'array',
})
export class ArrayPipe implements PipeTransform {
	transform(value: LayerDto[] | FeatureDto[], ...args: unknown[]): string {
		if (!value || !value.length) return '';
		if ((value[0] as LayerDto).type !== undefined) {
			return (value as LayerDto[]).reduce((prevVal, currVal, idx) => {
				return idx == 0 ? currVal.name : prevVal + ', ' + currVal.name;
			}, '');
		} else {
			return (value as FeatureDto[]).reduce((prevVal, currVal, idx) => {
				return idx == 0 ? currVal.name : prevVal + ', ' + currVal.name;
			}, '');
		}
	}
}
