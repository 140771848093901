<div class="modal add-email">
	<div class="modal-content">
		<i class="icon-close" (click)="closeModal.emit()"></i>
		<ng-container *ngIf="true; then thenBlock"></ng-container>
		<ng-template #emailBlock>
			<h1 class="title">Please insert the email of the user you’d like to add</h1>
			<form [formGroup]="form" *ngIf="form">
				<div class="modal-body">
					<input type="text" placeholder="Email" formControlName="email" />
					<div *ngIf="availablePlans">
						<span>Select a plan</span>
						<select formControlName="businessPlanId">
							<option *ngFor="let plan of availablePlans" [ngValue]="plan.id">{{ plan.name }}</option>
						</select>
					</div>
				</div>
				<div class="modal-footer">
					<button class="btn" [disabled]="!form.valid" (click)="send()">Add</button>
				</div>
			</form>
		</ng-template>

		<ng-template #confirmationBlock>
			<h1 class="title confirmation">Attention</h1>
			<div class="modal-body">
				<p>
					Existing individual account with introduced email detected. Would you like to invite the individual
					user to join your enterprise account?
				</p>
			</div>
			<div class="modal-footer confirmation">
				<div class="buttons">
					<button class="btn" (click)="onConfirmation()">Yes</button>
					<button class="btn btn-white" (click)="thenBlock = EmailBlock">No</button>
				</div>
			</div>
		</ng-template>
	</div>
</div>
<div class="notification contact-us" *ngIf="showNotificationOutOfSeats">
	<app-notification-custom-modal (close)="showNotificationOutOfSeats = false">
		<span title>This plan has no available user licenses.</span>
		<div body class="notification-body">
			<p>Please contact us to request more.</p>
			<div class="footer">
				<button class="btn contact-us" (click)="sendMail()">Contact Us</button>
			</div>
		</div>
	</app-notification-custom-modal>
</div>
