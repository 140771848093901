import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ToastService} from './toast.service';

declare var webkitSpeechRecognition: any;

@Injectable({
	providedIn: 'root',
})
export class SpeechRecognitionService {
	public recognization;
	private textOfAudio: Subject<string> = new Subject<string>();
	public textOfAudio$ = this.textOfAudio.asObservable();

	constructor() {
		try {
			this.recognization = new webkitSpeechRecognition();
		} catch (err) {}
	}

	public start = () => {
		if (this.recognization) {
			this.recognization.onstart = () => {
				// Do nothing
			};
			this.recognization.onresult = (e) => {
				const transcript = e.results[0][0].transcript;
				this.textOfAudio.next(transcript);
			};
			this.recognization.start();
		}
	};

	public stop = () => {
		if (this.recognization) {
			this.recognization.stop();
			this.textOfAudio.next(undefined);
		} else {
			this.textOfAudio.next(undefined);
		}
	};
}
