<div class="modal add-entrance">
  <div class="modal-content">
    <perfect-scrollbar>
      <div class="scroll">
        <h2 class="title">{{ markerId ? 'Add new entrance' : customMarker ? 'Edit Pin' : 'New Pin' }}</h2>
        <p class="subtitle">
          {{
            markerId
              ? 'Click and drag on the map to place the new entrance'
              : customMarker
                ? 'Drag the custom pin on the map to a new location or edit coordinates and name below'
                : 'Click and drag on the map to place a new custom pin'
          }}
        </p>

        <form class="clearfix" [formGroup]="form">
          <div style="display: flex; justify-content: center;">
            <span>Custom pin</span>
            <a class="switch" style="margin-left: 5px; margin-right: 5px;" (click)="handleSavePinChange()"
               [ngClass]="{on: !savePin}">
              <span class="dot"></span>
            </a>
            <span>Waypoint</span>
          </div>
          <br />
          <input placeholder="Name" type="text" formControlName="name" />
          <input
            placeholder="Latitude"
            type="number"
            formControlName="lat"
            (input)="handleLatChange($event)"
            step="0.01"
          />
          <input
            placeholder="Longitudes"
            type="number"
            formControlName="lng"
            (input)="handleLngChange($event)"
            step="0.01"
          />

          <div class="buttons">
            <button class="btn btn-white" (click)="closeModal.emit()">Cancel</button>
            <button
              class="btn"
              (click)="markerId ? addEntrance() : addCustomMarker()"
              [disabled]="!form.valid"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </perfect-scrollbar>
  </div>
</div>
