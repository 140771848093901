<div class="modal notification">
	<div class="modal-content">
		<i class="icon-close" (click)="closeModal.emit()"></i>
		<h1 class="title">
			You have new user licenses available. You can create and manage user credentials from the User Management
			tab.
		</h1>
		<button class="btn" (click)="closeModal.emit()">OK</button>
	</div>
</div>
