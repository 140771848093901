import {COLORS} from '../types/enums';
import {LatLngLiteral} from '@agm/core';
import {MapMarker, MarkerDto, CustomMarkerDto} from '../types/dtos/models';
import {MEASUREMENT_SYSTEMS, MEASUREMENT_UNITS_TRANSLATION} from '../types/constants';

declare var google: any;

const placeIddict: {[key: string]: LatLngLiteral} = {};

export const iconByColor = (color: COLORS | string, selected: boolean) => {
	switch (color) {
		case COLORS.BLACK:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_black_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_black.svg';
		case COLORS.BLUE:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_blue_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_blue.svg';
		case COLORS.DEFAULT:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_default_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_default.svg';
		case COLORS.GREEN:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_green_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_green.svg';
		case COLORS.ORANGE:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_orange_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_orange.svg';
		case COLORS.PINK:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_pink_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_pink.svg';
		case COLORS.RED:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_red_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_red.svg';
		case COLORS.WHITE:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_white_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_white.svg';
		case COLORS.YELLOW:
			if (selected) return '../../../../assets/img/map_assets/markers/ic_marker_pin_yellow_selected.svg';
			else return '../../../../assets/img/map_assets/markers/ic_marker_pin_yellow.svg';
	}
};

export const geocode = (placeId: string): Promise<LatLngLiteral> => {
	const addressLocation = placeIddict[placeId];
	if (addressLocation)
		return new Promise((resolve, reject) => {
			resolve(addressLocation);
		});
	else {
		const geocoder = new google.maps.Geocoder();
		return new Promise((resolve, reject) => {
			geocoder.geocode(
				{
					placeId: placeId,
				},
				(results, status) => {
					if (status === google.maps.GeocoderStatus.OK) {
						const latLng = {
							lat: results[0].geometry.location.lat(),
							lng: results[0].geometry.location.lng(),
						};
						placeIddict[placeId] = latLng;
						resolve(latLng);
					} else {
						reject(new Error(status));
					}
				}
			);
		});
	}
};

export const creatMapPin = (marker: MarkerDto | CustomMarkerDto): MapMarker => {
	const color = marker.iscustomMarker ? marker.color : COLORS.BLUE;
	return {
		id: marker.id,
		lat: marker.lat,
		lng: marker.lng,
		color,
		iconUrl: iconByColor(color, false),
		iscustomMarker: marker.iscustomMarker,
		name: marker.name,
	};
};

export const getCustomPinByPlace = async (placeId: string, customMarkers: CustomMarkerDto[]) => {
	const addressLocation = await geocode(placeId);
	const customMarker = customMarkers.find(
		(marker) => marker.lat === addressLocation.lat && marker.lng === addressLocation.lng
	);
	return customMarker;
};

export const assignValuesToObjectByKeys = (obj1: Object, obj2: Object) => {
	for (const key in obj1) {
		if (obj1.hasOwnProperty(key)) {
			obj1[key] = obj2[key];
		}
	}
};

export const cleanObject = (obj: Object) => {
	Object.keys(obj).forEach((k) => obj[k] == null && delete obj[k]);
	return obj;
};

export const cleanEmptyStrings = (obj: Object) => {
	Object.keys(obj).forEach((k) => obj[k] === '' && delete obj[k]);
	return obj;
};
export const copyObject = (obj: Object) => JSON.parse(JSON.stringify(obj));

export const convertToFeetAndInches = (value: number, measurement: MEASUREMENT_SYSTEMS): string => {
	if (measurement === MEASUREMENT_SYSTEMS.IMPERIAL) {
		return `${Math.floor(value)}' ${Math.round((value * MEASUREMENT_UNITS_TRANSLATION.FEET_TO_INCHES) % 12)}"`;
	}
	if (measurement === MEASUREMENT_SYSTEMS.METRIC) {
		return `${Math.floor(value * MEASUREMENT_UNITS_TRANSLATION.METERS_TO_FEET)}' ${Math.round(
			(value * MEASUREMENT_UNITS_TRANSLATION.METERS_TO_FEET * MEASUREMENT_UNITS_TRANSLATION.FEET_TO_INCHES) % 12
		)}"`;
	}
};
