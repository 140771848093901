import {Injectable, Inject, forwardRef, Injector} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {AuthService} from '../../services/auth.service';
import {Observable, throwError} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private router: Router, private inj: Injector) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authService = this.inj.get(AuthService);
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				return event;
			}),
			catchError((error) => {
				const urlWithoutParams = this.router.url.split('?')[0];
				if (error.status === 401 && urlWithoutParams !== '/login') {
					authService.clearStorage();
					return new Observable<HttpEvent<any>>();
				}
				return throwError(error);
			})
		);
	}
}
