import {AfterContentInit, Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {AuthService} from './services/auth.service';
import {ChatService} from './services/chat.service';
import {UtilitiesService} from './services/utilities.service';
import {FirebaseService} from './services/firebase.service';
import {Menu} from 'primeng';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentInit {
	userSubscription: Subscription;
	@ViewChild('menu', {static: true}) menu: Menu;
	constructor(
		private authService: AuthService,
		private chatService: ChatService,
		private utilitiesService: UtilitiesService,
		private firebaseService: FirebaseService
	) {}

	@HostListener('document:click', ['$event'])
	documentClick(event: any): void {
		this.utilitiesService.documentClickedTarget.next(event.target);
	}

	ngOnInit() {
		this.userSubscription = this.authService.currentUserChat.subscribe((userChat) => {
			if (userChat) {
				this.chatService.initChat();
			}
		});
	}

	ngAfterContentInit(): void {
		this.utilitiesService.setMenu(this.menu);
	}
	ngOnDestroy() {
		this.userSubscription.unsubscribe();
	}
}
