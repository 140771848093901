import {Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener} from '@angular/core';
import {
	MarkerDto,
	ShareAssetDto,
	CustomMarkerDto,
	FavoriteDto,
	UserDto,
	FolderDto,
	RecentSearchDto,
	MultiStopRouteDto,
	CustomMarkerFolderDto,
	MapDrawingDto,
	UserLogged,
	MapRecordedRouteDto,
	MapRecordedRouteDtoToShare,
} from 'src/app/types/dtos/models';
import {ToastService} from 'src/app/services/toast.service';
import {UserService} from 'src/app/services/user.service';
import {ShareService} from 'src/app/services/share.service';
import {LoaderService} from 'src/app/services/loader.service';
import {Subscription, throwError} from 'rxjs';
import {
	ChatCustomMarker,
	ChatCustomMarkersFolder,
	ChatFavoriteMarker,
	ChatFavoriteMarkersFolder,
	ChatMarker,
	ChatRoute,
	Message,
	MESSAGE_TYPES,
} from 'src/app/types/dtos/chat';
import {ChatService} from 'src/app/services/chat.service';
import {FavoriteService} from 'src/app/services/favorite.service';
import {MarkerService} from 'src/app/services/marker.service';
import * as _ from 'lodash';
import {AuthService} from 'src/app/services/auth.service';
import {FEATURES, ShareTypes, StopType} from 'src/app/types/enums';
import {LAYER_TYPES} from 'src/app/types/constants';
import {DrawingToShareDto, polylinesToGeoJson} from 'src/app/helpers/shareHelper';
import {DrawService} from 'src/app/services/draw.service';
import {v4 as uuid} from 'uuid';

@Component({
	selector: 'app-share',
	templateUrl: './share.component.html',
	styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit, OnDestroy {
	public userIsStaff: boolean = false;
	public currentUserSubscription: Subscription;
	private currentUser: UserLogged;
	users: UserDto[];
	searchValue: string;
	public shareType: ShareTypes = undefined;
	filteredUsers: Partial<UserDto>[];
	selectedUsers: Partial<UserDto>[] = [];
	public selectedData: string[] = [];
	public message: string = 'A ';
	currentMarker: MarkerDto | FavoriteDto;
	firebaseChat: boolean = false;

	markerName = '';

	constructor(
		private toastService: ToastService,
		private userService: UserService,
		private shareService: ShareService,
		private loaderService: LoaderService,
		private chatService: ChatService,
		private auth: AuthService,
		private drawService: DrawService,
		private markerService: MarkerService,
		private favoriteService: FavoriteService
	) {}

	@Input() favorites: (FavoriteDto & MarkerDto)[];
	@Input() folders: FolderDto[];
	@Input() customFolders: CustomMarkerFolderDto[];
	@Input() markers: MarkerDto[];
	@Input() pins: CustomMarkerDto[];
	@Input() routes: MultiStopRouteDto[];
	@Input() customRoutes: MapDrawingDto[];
	@Input() customMarker: CustomMarkerDto;
	@Input() recordRoutes: MapRecordedRouteDto[];
	@Output() closedModal = new EventEmitter();
	@Input('marker')
	set marker(value: MarkerDto | FavoriteDto) {
		this.currentMarker = value;
		if (value) {
			(value as MarkerDto).favorite
				? (this.markerName = (value as MarkerDto).favorite.nickname)
				: (value as FavoriteDto).nickname
				? (this.markerName = (value as FavoriteDto).nickname)
				: (this.markerName = (value as MarkerDto).name);
		}
	}
	get marker(): MarkerDto | FavoriteDto {
		return this.currentMarker;
	}

	ngOnDestroy(): void {
		this.currentUserSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		let types = [];
		types = types.concat(
			this.favorites?.map((f) => f.layerId),
			this.folders?.map((f) => f.layerId),
			this.markers?.map((f) => f.layerId),
			this.routes?.map((f) => f.layerId)
		);
		types = _.uniq(types);
		types = _.reject(types, _.isUndefined);
		if (this.routes && this.routes.length) {
			this.message = this.message.concat('Route ');
		} else if (this.customRoutes && this.customRoutes.length) {
			this.message = this.message.concat('Recorded Route ');
		} else if (this.recordRoutes && this.recordRoutes.length) {
			this.message = this.message.concat('Recorded Route ');
		} else if (types.includes(LAYER_TYPES.WELLS)) {
			this.message = this.message.concat('Well ');
		} else if (types.includes(LAYER_TYPES.SWD)) {
			this.message = this.message.concat('SWD ');
		} else if (types.includes(LAYER_TYPES.FRAC)) {
			this.message = this.message.concat('Frac ');
		} else if (types.includes(LAYER_TYPES.DRILL_PERMITS)) {
			this.message = this.message.concat('Drill Permit ');
		} else if (types.includes(LAYER_TYPES.RIGS)) {
			this.message = this.message.concat('Rig ');
		} else {
			this.message = this.message.concat('Pin ');
		}
		this.message = this.message.concat(
			'has been shared with you through Wellsite Navigator. Please click the link below to view the details in your Wellsite Navigator App.'
		);
		this.currentUserSubscription = this.auth.currentUser.subscribe((currentUser) => {
			if (currentUser) {
				this.currentUser = currentUser;
				this.shareType =
					currentUser.staff && this.auth.userHasFeature(FEATURES.ENTERPRISE_BASIC)
						? ShareTypes.THEMSELVES
						: currentUser.staff
						? ShareTypes.COMPANY_USERS
						: ShareTypes.ANY;
				this.userIsStaff = currentUser.staff;
				if (this.userIsStaff && this.shareType === ShareTypes.COMPANY_USERS) {
					this.getUsers();
				}
				if (this.shareType === ShareTypes.THEMSELVES) {
					this.selectedUsers = [(currentUser as unknown) as Partial<UserDto>];
				}
			}
		});
		if (
			this.auth.currentUserValue.features?.some(
				(f) =>
					f.id === FEATURES.CHAT || f.id === FEATURES.ENTERPRISE_DISPATCH || f.id === FEATURES.ENTERPRISE_PLUS
			)
		) {
			this.firebaseChat = true;
		}
	}

	get valueFilter(): string {
		return this.searchValue;
	}

	set valueFilter(value: string) {
		this.searchValue = value;
		this.filteredUsers = this.filterUsers(this.searchValue);
	}

	getUsers = async () => {
		try {
			const result = await this.userService.getAllUsers();
			this.users = result.data;
			this.filteredUsers = this.filterUsers('');
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not get users', detail: 'Try again later'});
		}
	};

	filterUsers(filterBy: string): UserDto[] {
		filterBy = filterBy.toLocaleLowerCase();
		return this.users.filter(
			(user: UserDto) =>
				`${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()}`.indexOf(filterBy) !== -1 ||
				user.email.toLocaleLowerCase().indexOf(filterBy) !== -1
		);
	}

	share = async (shareBy: string) => {
		try {
			this.loaderService.show();
			if (!this.selectedUsers) return;
			if (shareBy === 'CHAT') {
				if (this.folders?.length) {
					await Promise.all(
						this.folders.map(async (folder: FolderDto) => {
							const request = await this.favoriteService.getFolderFavorites(
								folder.id,
								folder.createdByUser.id
							);
							if (request.data.length === 0) {
								return;
							}
							const allMarkers = request.data;
							const actualMarkers = await Promise.all(
								allMarkers.map(async (marker) => {
									return {
										id: marker.id,
										name: marker.nickname,
										color: marker.color,
										gates: marker.gates.map((gate) => ({
											name: gate.name,
											markerId: gate.markerId,
											lat: gate.lat,
											lng: gate.lng,
											latitude: gate.latitude,
											longitude: gate.longitude,
										})),
									} as ChatFavoriteMarker;
								})
							);
							await Promise.all(
								this.selectedUsers.map(async (user: UserDto) => {
									const message: Partial<Message<ChatFavoriteMarkersFolder>> = {
										message_type: MESSAGE_TYPES.FAVORITE_FOLDER,
										content: {
											id: folder.id,
											name: folder.name,
											number_of_markers: folder.numberOfMarkers,
											layerId: folder.layerId,
											markers: actualMarkers,
										},
									};
									await this.chatService.sendMessage(message, user.threadId);
								})
							);
						})
					);
					if (this.folders.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared folders successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared folder '${this.limitStrLength(this.folders[0].name, 15)}' successfully.`,
						});
					}
				}
				if (this.customFolders?.length) {
					await Promise.all(
						this.customFolders.map(async (customFolder: FolderDto) => {
							const request = await this.markerService.getCustomMarkersOfFolder(
								customFolder.id,
								customFolder.createdByUser.id
							);
							if (request.data.length === 0) {
								this.toastService.show({
									severity: 'error',
									summary: 'Could not share folder. Folder is empty',
								});
								return;
							}
							const allMarkers: CustomMarkerDto[] = request.data;
							const actualMarkers = await Promise.all(
								allMarkers.map(async (marker) => {
									return {
										id: marker.id,
										name: marker.name,
										lat: marker.lat,
										lng: marker.lng,
										color: marker.color,
									} as ChatCustomMarker;
								})
							);
							await Promise.all(
								this.selectedUsers.map(async (user: UserDto) => {
									const message: Partial<Message<ChatCustomMarkersFolder>> = {
										message_type: MESSAGE_TYPES.CUSTOM_FOLDER,
										content: {
											id: customFolder.id,
											name: customFolder.name,
											number_of_markers: customFolder.numberOfMarkers,
											markers: actualMarkers,
										},
									};
									await this.chatService.sendMessage(message, user.threadId);
								})
							);
						})
					);
					if (this.customFolders.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared custom folders successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared folder '${this.limitStrLength(
								this.customFolders[0].name,
								15
							)}' successfully.`,
						});
					}
				}
				if (this.markers?.length) {
					await Promise.all(
						this.markers.map(async (marker: RecentSearchDto) => {
							const actualMarker = (await this.markerService.getMarker(marker.id)).data.marker;
							await Promise.all(
								this.selectedUsers.map(async (user: UserDto) => {
									const message: Partial<Message<ChatMarker>> = {
										message_type: MESSAGE_TYPES.MARKER,
										content: {
											id: actualMarker.id,
											name: actualMarker.name,
											layerId: actualMarker.layerId,
											gates: actualMarker.gates.map((gate) => ({
												name: gate.name,
												markerId: gate.markerId,
												lat: gate.lat,
												lng: gate.lng,
												latitude: gate.latitude,
												longitude: gate.longitude,
											})),
										},
									};
									await this.chatService.sendMessage(message, user.threadId);
								})
							);
						})
					);
					if (this.markers.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared ${this.markers.length} markers successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared marker '${this.limitStrLength(this.markers[0].name, 15)}' successfully.`,
						});
					}
				}
				if (this.favorites?.length) {
					await Promise.all(
						this.favorites.map(async (favorite: any) => {
							const favoriteWithGates = (
								await this.markerService.getMarker(favorite.markerId || favorite.id)
							).data.marker;
							await Promise.all(
								this.selectedUsers.map(async (user: UserDto) => {
									const favoriteGate =
										favoriteWithGates.gates || favoriteWithGates.favorite.gates || [];
									const message: Partial<Message<ChatFavoriteMarker>> = {
										message_type: MESSAGE_TYPES.FAVORITE_MARKER,
										content: {
											id: favoriteWithGates.id,
											layerId: favoriteWithGates.layerId,
											name: favoriteWithGates.name,
											gates: favoriteGate.map((gate) => ({
												name: gate.name,
												markerId: gate.markerId,
												lat: gate.lat,
												lng: gate.lng,
												latitude: gate.latitude,
												longitude: gate.longitude,
											})),
											color:
												favoriteWithGates.color ||
												favoriteWithGates.favorite?.color ||
												'DEFAULT',
										},
									};
									await this.chatService.sendMessage(message, user.threadId);
								})
							);
						})
					);
					if (this.favorites.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared ${this.favorites.length} favorite markers successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared favorite marker '${this.limitStrLength(
								this.favorites[0].name || this.favorites[0].nickname,
								15
							)}' successfully.`,
						});
					}
				}
				if (this.pins?.length) {
					await Promise.all(
						this.pins.map(async (pin: CustomMarkerDto) => {
							await Promise.all(
								this.selectedUsers.map(async (user: UserDto) => {
									const message: Partial<Message<ChatCustomMarker>> = {
										message_type: MESSAGE_TYPES.CUSTOM_MARKER,
										content: {
											id: pin.id,
											name: pin.name,
											lat: pin.lat,
											lng: pin.lng,
											color: pin.color,
										},
									};
									const content = message.content;
									Object.keys(content).forEach(
										(key) => content[key] === undefined && delete content[key]
									);
									await this.chatService.sendMessage(message, user.threadId);
								})
							);
						})
					);
					if (this.pins.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared ${this.pins.length} pins successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared pin '${this.limitStrLength(this.pins[0].name, 15)}' successfully.`,
						});
					}
				}
				if (this.routes) {
					await Promise.all(
						this.routes.map(async (route: MultiStopRouteDto) => {
							let draws = route.draws?.map((d) => JSON.stringify(d)) || [];
							if (route.drawIds) {
								draws = await Promise.all(
									route.drawIds.map(async (drawId) => {
										const res = await this.drawService.getFullDraw(drawId);
										const draw = res.data;
										const polylines = polylinesToGeoJson(draw.polylines);
										const content: DrawingToShareDto = {
											id: draw.id || uuid(),
											name: draw.name,
											description: draw.description,
											distance: draw.distance,
											color: draw.color,
											items: draw.items,
											polylines,
											userId: draw.userId,
										};
										return JSON.stringify(content);
									})
								);
							}
							this.selectedUsers.forEach(async (user: UserDto) => {
								const message: Partial<Message<ChatRoute>> = {
									message_type: MESSAGE_TYPES.ROUTE,
									content: {
										id: route.id || null,
										name: route.name || null,
										description: route.description || null,
										layerId: route.layerId || null,
										draws,
										stops: route.stops.map((stop) => ({
											id: stop.id || null,
											type: stop.type,
											name: stop.name,
											lat: stop.lat || null,
											lng: stop.lng || null,
										})),
										isHazmat: route.isHazmat,
										items: route.items,
										vehicleProfileId: route.vehicleProfileId || null,
										vehicleProfile: route.vehicleProfile || null,
									},
								};
								const content = message.content;
								Object.keys(content).forEach(
									(key) => content[key] === undefined && delete content[key]
								);
								await this.chatService.sendMessage(message, user.threadId);
							});
						})
					);
					if (this.routes.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared ${this.routes.length} routes successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared route '${this.limitStrLength(this.routes[0].name, 15)}' successfully.`,
						});
					}
				}
				if (this.customRoutes) {
					await Promise.all(
						this.customRoutes.map((route: MapDrawingDto) => {
							const polylines = polylinesToGeoJson(route.polylines);
							const content: DrawingToShareDto = {
								id: route.id || uuid(),
								name: route.name,
								description: route.description,
								distance: route.distance,
								color: route.color,
								items: route.items,
								polylines,
								userId: route.userId,
							};
							this.selectedUsers.forEach(async (user: UserDto) => {
								const message: Partial<Message<string>> = {
									message_type: MESSAGE_TYPES.CUSTOM_ROUTE,
									content: JSON.stringify(content),
								};
								await this.chatService.sendMessage(message, user.threadId);
							});
						})
					);
					if (this.customRoutes.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared ${this.customRoutes.length} routes successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared route '${this.limitStrLength(
								this.customRoutes[0].name,
								15
							)}' successfully.`,
						});
					}
				}
				if (this.recordRoutes) {
					await Promise.all(
						this.recordRoutes.map((route: MapRecordedRouteDto) => {
							const content: MapRecordedRouteDtoToShare = {
								id: route.id || uuid(),
								name: route.name,
								description: route.description,
								items: route.items,
								isHazmat: !!route.isHazmat,
								geoJson: polylinesToGeoJson(route.polylines),
								createdByUser: route.createdByUser,
							};
							this.selectedUsers.forEach(async (user: UserDto) => {
								const message: Partial<Message<string>> = {
									message_type: MESSAGE_TYPES.RECORDED_ROUTE,
									content: JSON.stringify(content),
								};
								await this.chatService.sendMessage(message, user.threadId);
							});
						})
					);
					if (this.recordRoutes.length > 1) {
						this.toastService.show({
							severity: 'success',
							summary: `Shared ${this.recordRoutes.length} routes successfully.`,
						});
					} else {
						this.toastService.show({
							severity: 'success',
							summary: `Shared route '${this.limitStrLength(
								this.recordRoutes[0].name,
								15
							)}' successfully.`,
						});
					}
				}
			} else {
				let shareAssetDto: ShareAssetDto = {};
				if (this.favorites) {
					shareAssetDto.favorites = this.favorites.map((fav) => fav.id);
					shareAssetDto.userId = this.favorites[0].createdByUser?.id || this.currentUser.id;
				}
				if (this.folders) {
					shareAssetDto.folders = this.folders
						.filter((folder) => folder?.id !== 0)
						?.map((folder) => folder?.id);
					shareAssetDto.rootFolder = this.folders.some((val) => val.id === 0);
					shareAssetDto.userId = this.folders[0].createdByUser?.id || this.currentUser.id;
				}
				if (this.customRoutes) {
					const [notSavedRoutes, savedRoutes] = _.partition(
						this.customRoutes,
						(customRoute) => !customRoute.id
					);
					if (savedRoutes.length) shareAssetDto.drawing = savedRoutes.map((customRoute) => customRoute.id)[0];
					if (notSavedRoutes.length) shareAssetDto.customDrawing = notSavedRoutes[0];
					shareAssetDto.userId = this.currentUser.id;
				}
				if (this.markers) {
					shareAssetDto.markers = this.markers.map((marker) => marker.id);
					shareAssetDto.userId = this.markers[0].createdByUser?.id || this.currentUser.id;
				}
				if (this.pins) {
					shareAssetDto.pins = this.pins.map((pin) => pin.id);
					shareAssetDto.userId = this.pins[0].createdByUser?.id || this.currentUser.id;
				}
				if (this.routes) {
					const [notSavedRoutes, savedRoutes] = _.partition(this.routes, (route) => !route.id);
					if (savedRoutes.length) shareAssetDto.routes = savedRoutes.map((route) => route.id);
					if (notSavedRoutes.length) shareAssetDto.customRoutes = [notSavedRoutes[0]];
					shareAssetDto.userId = this.routes[0].createdByUser?.id || this.currentUser.id;
				}
				if (this.customFolders) {
					shareAssetDto.customFolders = this.customFolders.map((folder) => folder.id);
					shareAssetDto.userId = this.customFolders[0].createdByUser?.id || this.currentUser.id;
				}
				if (this.recordRoutes) {
					shareAssetDto.recordRoutes = this.recordRoutes.map((route) => route.id);
					shareAssetDto.userId = this.recordRoutes[0].createdByUser?.id || this.currentUser.id;
				}
				if (shareBy === 'CUSTOM') {
					shareAssetDto = {
						...shareAssetDto,
						people: this.selectedData,
						message: this.message,
					};
					await this.shareService.shareAssets(shareAssetDto);
					this.toastService.show({
						severity: 'success',
						summary: `Message successfully sent!`,
					});
				} else {
					shareAssetDto = {
						...shareAssetDto,
						users: this.selectedUsers.map((user) => +user.id),
						shareBy,
					};
					await this.shareService.shareAssets(shareAssetDto);
					this.toastService.show({
						severity: 'success',
						summary: `${shareBy} successfully sent!`,
					});
				}
			}
		} catch (err) {
			throwError(err);
			if (err.error?.error === 'INVALID_PHONE_NUMBERS') {
				const erros: string[] = JSON.parse(err.error.message).errorPhones;
				erros.forEach((user) =>
					this.toastService.show({
						severity: 'error',
						summary: `${user} have no number or is invalid`,
					})
				);
			} else if (err.error?.error === 'INVALID_PAYLOAD') {
				this.toastService.show({
					severity: 'error',
					summary: `Invalid payload`,
				});
			} else {
				this.toastService.show({
					severity: 'error',
					summary: 'Sharing was not possible',
					detail: 'Try again later',
				});
			}
		} finally {
			this.loaderService.hide();
		}
		this.closedModal.emit();
	};

	limitStrLength = (text, max_length) => {
		if (text.length > max_length - 3) {
			return text.substring(0, max_length).trimEnd() + '...';
		} else {
			return text;
		}
	};

	selectAll = () => {
		this.selectedUsers = this.users;
		this.filteredUsers = this.filterUsers(this.valueFilter || '');
	};
}
