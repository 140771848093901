import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Papa} from 'ngx-papaparse';
import {throwError} from 'rxjs';
import {MarkerService} from 'src/app/services/marker.service';
import {ToastService} from 'src/app/services/toast.service';
import {StopDto} from 'src/app/types/dtos/models';
import {StopType} from 'src/app/types/enums';

enum Types {
	WELLS = 'Well',
	PINS = 'Pin',
}
enum Steps {
	TYPE_SELECTION = 'type_selection',
	FILE_UPLOAD = 'file_upload',
}
@Component({
	selector: 'app-csv-stops-uploader',
	templateUrl: './csv-stops-uploader.component.html',
})
export class CSVStopsUploader {
	showModal = false;
	selectedType: string = Types.WELLS;
	selectedStep: string = Steps.TYPE_SELECTION;
	data: StopDto[] = [];
	@Output() saveStops = new EventEmitter();
	csvUploadedInfo: File = null;
	csvError = false;
	@Input() disabled = true;

	constructor(private papa: Papa, private toastService: ToastService, private markerService: MarkerService) {}

	handleDisplayModal = (show: boolean) => {
		this.showModal = show;
		if (!show) {
			this.selectedStep = Steps.TYPE_SELECTION;
			this.selectedType = Types.WELLS;
			this.csvUploadedInfo = null;
			this.data = [];
			this.csvError = false;
		}
	};

	handleSelectType = (type: string) => {
		this.selectedType = type;
	};

	handleSelectStep = (step: string) => {
		this.selectedStep = step;
		this.csvUploadedInfo = null;
		this.data = [];
		this.csvError = false;
	};

	public readCSV = async (csvInput: HTMLInputElement) => {
		try {
			this.csvError = false;
			const csvFile: File = csvInput.files[0];
			this.csvUploadedInfo = csvFile;
			const parsedRecords = (await new Promise((resolve, reject) =>
				this.papa.parse(csvFile, {
					skipEmptyLines: true,
					complete: (results) => resolve(results),
					error: (err) => {
						reject(err);
					},
				})
			)) as {data: string[][]};
			this.selectedType === Types.PINS
				? this.getPinsFromCSVFile(parsedRecords.data)
				: await this.getWellsFromCSVFile(parsedRecords.data);
		} catch (err) {
			throwError(err);
			this.toastService.show({
				severity: 'error',
				summary: 'An error occurred while trying to read the svg',
				detail: 'Try again later',
			});
			this.csvError = true;
		}
	};

	getPinsFromCSVFile = (csvRecordsArray: string[][]) => {
		if (!csvRecordsArray[0][0] || !csvRecordsArray[0][1] || !csvRecordsArray[0][2] || csvRecordsArray[0][4]) {
			this.toastService.show({severity: 'error', summary: 'Invalid format, please follow the provided format.'});
			this.csvError = true;
		} else {
			csvRecordsArray.shift();
			this.data = csvRecordsArray.reduce((prevVal: StopDto[], currVal) => {
				if (!currVal[0] || !currVal[1] || !currVal[2]) {
					this.toastService.show({severity: 'warn', summary: 'Empty value in the data'});
				} else {
					const newStop: StopDto = {
						name: currVal[0],
						lat: +currVal[1],
						lng: +currVal[2],
						type: StopType.COORDINATES,
					};
					prevVal.push(newStop);
					return prevVal;
				}
			}, []);
		}
	};

	getWellsFromCSVFile = async (csvRecordsArray: string[][]) => {
		try {
			if (!csvRecordsArray[0][0] || csvRecordsArray[0][1] || csvRecordsArray[0][2]) {
				this.toastService.show({
					severity: 'error',
					summary: 'Invalid format, please follow the provided format.',
				});
				this.csvError = true;
			} else {
				let apis = [];
				csvRecordsArray.forEach((element) => {
					apis = apis.concat(element);
				});
				const wells = (await this.markerService.getMarkersByAPINumbers(apis)).data;
				const stops: StopDto[] = wells.markers.map((marker) => {
					return {
						id: marker.id,
						name: marker.name,
						lat: marker.lat,
						lng: marker.lng,
						type: StopType.MARKER,
					};
				});
				this.data = stops;
			}
		} catch (error) {
			this.toastService.show({severity: 'error', summary: error.error.message});
			this.csvError = true;
		}
	};

	handleSaveStops = () => {
		if (this.data) {
			this.saveStops.emit(this.data);
			this.handleDisplayModal(false);
		} else {
			this.csvError = true;
		}
	};

	handleDownloadTamplate = async () => {
		const templateUrl =
			this.selectedType === Types.PINS
				? '../../../../assets/templates/pins-template.csv'
				: '../../../../assets/templates/wells-template.csv';
		const response = await fetch(templateUrl);
		if (!response.ok) {
			throw new Error('Failed to fetch template file');
		}
		const templateBlob = await response.blob();
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(templateBlob);
		link.download = `${this.selectedType}-template.csv`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(link.href);
	};
}
