import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {MapService} from 'src/app/services/map.service';

@Component({
	selector: 'app-map-settings',
	templateUrl: './map-settings.component.html',
	styleUrls: ['./map-settings.component.scss'],
})
export class MapSettingsComponent implements OnInit {
	constructor(public mapService: MapService) {}

	@Output() closeModal = new EventEmitter();

	ngOnInit(): void {}

	public clearPins = () => {
		this.mapService.clearPinned(this.mapService.currentStatus.layer.id);
	};

	showNearbyLayer = (show: boolean) => {
		const {showNearbyLayer, ...curretStatus} = this.mapService.currentStatus;
		this.mapService.changeMapStatus({showNearbyLayer: show, ...curretStatus});
	};

	showNearbyPins = (show: boolean) => {
		const {showNearbyPins, ...curretStatus} = this.mapService.currentStatus;
		this.mapService.changeMapStatus({showNearbyPins: show, ...curretStatus});
	};
}
