import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MenuComponent} from './menu/menu.component';
import {SearchPopoverComponent} from './search-popover/search-popover.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddEntranceComponent} from './add-entrance/add-entrance.component';
import {MarkerInfoComponent} from './marker-info/marker-info.component';
import {RemoveModalComponent} from './remove-modal/remove-modal.component';
import {AddEditFavoriteComponent} from './add-edit-favorite/add-edit-favorite.component';

import {FilterFavoritesComponent} from './filter-favorites/filter-favorites.component';
import {MapSettingsComponent} from './map-settings/map-settings.component';
import {RouterModule} from '@angular/router';

import {MarkerActionsComponent} from './marker-actions/marker-actions.component';
import {ToastComponent} from './toast/toast.component';

import {MultiSelectModule} from 'primeng/multiselect';
import {ToastModule} from 'primeng/toast';
import {MessageService} from 'primeng/api';
import {ShareComponent} from './share/share.component';
import {LoaderComponent} from './loader/loader.component';
import {ArrayPipe} from 'src/app/helpers/pipes/array.pipe';
import {ArrayToUpperCaseCommaSeparatedPipe} from 'src/app/pipes/array-to-upper-case-comma-separated.pipe';
import {ColorCodePipe} from 'src/app/pipes/color-code.pipe';
import {ShareListComponent} from './share-list/share-list.component';
import {AddListComponent} from './add-list/add-list.component';
import {ListboxModule} from 'primeng/listbox';
import {PaginationComponent} from './pagination/pagination.component';
import {ResetPasswordModalComponent} from './reset-password-modal/reset-password-modal.component';

import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {RadioButtonModule} from 'primeng';
import {MenuModule} from 'primeng/menu';

import {TruncateTextPipe} from '../pipes/truncate-text.pipe';
import {LayerNamePipe} from '../pipes/layer-name.pipe';
import {ChangePasswordComponent} from './change-password/change-password.component';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {PERFECT_SCROLLBAR_CONFIG} from 'ngx-perfect-scrollbar';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {NotificationModalComponent} from './notification-modal/notification-modal.component';
import {ConfirmEmailModalComponent} from './confirm-email-modal/confirm-email-modal.component';
import {PendingUsersModalComponent} from './pending-users-modal/pending-users-modal.component';
import {ConfirmationModalComponent} from './confirmation-modal/confirmation-modal.component';
import {FirstLetterPipe} from '../pipes/first-letter.pipe';
import {ShowFolderModalComponent} from './show-folder-modal/show-folder-modal.component';
import {ShowStopsComponent} from './show-stops/show-stops.component';
import {PopupNotificationsComponent} from './popup-notifications/popup-notifications.component';
import {PopupAddPhoneNumberComponent} from './popup-notifications/popup-add-phone-number/popup-add-phone-number.component';
import {NotificationCustomModalComponent} from './notification-custom-modal/notification-custom-modal.component';
import {SplitTextByCapitalLetterPipe} from '../pipes/split-text-by-capital-letter.pipe';
import {TransformFeetToFeetInchesPipe} from '../pipes/transform-feet-to-feet-inches.pipe';
import {FilterPipe} from '../pipes/filter.pipe';
import {AudioPlayerComponent} from './UI/audio-player/audio-player.component';
import {RouteCardComponent} from './UI/route-card/route-card.component';
import {AddEditNoteComponent} from './add-edit-note/add-edit-note.component';
import {ImageUploaderComponent} from './UI/image-uploader/image-uploader.component';
import {ShowRecordedRouteModalComponent} from './show-recorded-route-modal/show-recorded-route-modal.component';
import {ExternalShareComponent} from './external-share/external-share.component';
import {PopupAcknowledgeComponent} from './popup-notifications/popup-acknowledge/popup-acknowledge.component';
import {CSVStopsUploader} from './UI/csv-stops-uploader/csv-stops-uploader.component';
import {TooltipModule} from 'primeng/tooltip';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
};

@NgModule({
	declarations: [
		MenuComponent,
		SearchPopoverComponent,
		AddEntranceComponent,
		MarkerInfoComponent,
		RemoveModalComponent,
		AddEditFavoriteComponent,
		FilterFavoritesComponent,
		MapSettingsComponent,
		MarkerActionsComponent,
		ToastComponent,
		ShareComponent,
		ArrayToUpperCaseCommaSeparatedPipe,
		LoaderComponent,
		ArrayPipe,
		TruncateTextPipe,
		FirstLetterPipe,
		ColorCodePipe,
		SplitTextByCapitalLetterPipe,
		ShareListComponent,
		AddListComponent,
		PaginationComponent,
		ResetPasswordModalComponent,
		LayerNamePipe,
		ChangePasswordComponent,
		NotificationModalComponent,
		ConfirmEmailModalComponent,
		PendingUsersModalComponent,
		ConfirmationModalComponent,
		ShowFolderModalComponent,
		ShowStopsComponent,
		PopupNotificationsComponent,
		PopupAddPhoneNumberComponent,
		NotificationCustomModalComponent,
		TransformFeetToFeetInchesPipe,
		AudioPlayerComponent,
		RouteCardComponent,
		AddEditNoteComponent,
		ImageUploaderComponent,
		FilterPipe,
		ShowRecordedRouteModalComponent,
		ExternalShareComponent,
		PopupAcknowledgeComponent,
		CSVStopsUploader,
	],
	exports: [
		MenuComponent,
		SearchPopoverComponent,
		AddEntranceComponent,
		MarkerInfoComponent,
		NotificationCustomModalComponent,
		AddEditFavoriteComponent,
		RemoveModalComponent,
		FilterFavoritesComponent,
		MapSettingsComponent,
		ToastComponent,
		LoaderComponent,
		SplitTextByCapitalLetterPipe,
		ArrayToUpperCaseCommaSeparatedPipe,
		TruncateTextPipe,
		FirstLetterPipe,
		MarkerActionsComponent,
		ShareComponent,
		ShareListComponent,
		AddListComponent,
		PaginationComponent,
		ResetPasswordModalComponent,
		ShowStopsComponent,
		LayerNamePipe,
		ColorCodePipe,
		TransformFeetToFeetInchesPipe,
		CheckboxModule,
		RadioButtonModule,
		ChangePasswordComponent,
		PerfectScrollbarModule,
		NotificationModalComponent,
		ConfirmEmailModalComponent,
		PendingUsersModalComponent,
		ConfirmationModalComponent,
		ShowFolderModalComponent,
		PopupNotificationsComponent,
		AudioPlayerComponent,
		RouteCardComponent,
		AddEditNoteComponent,
		ImageUploaderComponent,
		FilterPipe,
		ShowRecordedRouteModalComponent,
		ExternalShareComponent,
		MenuModule,
		CSVStopsUploader,
		TooltipModule,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MultiSelectModule,
		RouterModule,
		ToastModule,
		DialogModule,
		CheckboxModule,
		RadioButtonModule,
		ChipsModule,
		PerfectScrollbarModule,
		ListboxModule,
		MenuModule,
	],
	providers: [
		MessageService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
	],
})
export class CoreModule {}
