import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'truncate',
})
export class TruncateTextPipe implements PipeTransform {
	transform(value: string, limit: number, trail = '...', ...args: unknown[]): string {
		if (value.length <= limit) {
			return value;
		}
		return value.substring(0, limit - trail.length).replace(/\s+$/, '') + trail;
	}
}
