<div class="modal filter-favorites filter-by-favorites-modal" *ngIf="filteredFolders">
	<div class="modal-content">
		<i class="icon-close" (click)="selectedFolder.emit(undefined)"></i>
		<h3 class="title">Filter by Favorite</h3>

		<div class="search-bar">
			<input type="text" class="search" [(ngModel)]="valueFilter" />
			<i class="icon-search-bar"></i>
		</div>

		<ul class="folder-list">
			<perfect-scrollbar>
				<li
					*ngFor="let folder of filteredFolders"
					(click)="selected = folder"
					class="clearfix"
					[ngClass]="{active: folder === selected}"
				>
					<div class="pins-value">
						<i class="icon-pins"></i>
						<span class="value">{{ folder.numberOfMarkers }}</span>
					</div>
					<span class="name">{{ folder.name }}</span>
				</li>
			</perfect-scrollbar>
		</ul>

		<button [disabled]="!selected" class="btn" (click)="selectedFolder.emit(selected.id)">
			Show selected favorites
		</button>
	</div>
</div>
