<div class="modal add-phone-number">
	<div class="modal-content">
		<i class="icon-close" (click)="closePopup()"></i>
		<div class="modal-header">
			<figure>
				<img src="../../../../assets/img/logo-all-white.svg" alt="" />
			</figure>
		</div>
		<div class="modal-body">
			<div class="form">
				<p class="text">
					Please add your phone number to complete
					<br />
					your profile
				</p>
				<form [formGroup]="form" *ngIf="form" (ngSubmit)="onSubmit()">
					<input
						type="text"
						pattern="^\+?[0-9]{0,115}"
						class="phone"
						formControlName="phone"
						placeholder="Phone Number"
					/>
				</form>
			</div>
			<div class="footer">
				<button class="btn" [disabled]="!form.valid" (click)="onSubmit()">Save</button>
			</div>
		</div>
	</div>
</div>
