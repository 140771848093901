import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NotificationDto} from 'src/app/types/dtos/models';

@Component({
	selector: 'app-notification-modal',
	templateUrl: './notification-modal.component.html',
	styleUrls: ['./notification-modal.component.scss'],
})
export class NotificationModalComponent implements OnInit {
	constructor() {}

	@Input() notification: NotificationDto;
	@Output() closeModal = new EventEmitter();

	ngOnInit(): void {}
}
