import {Pipe, PipeTransform} from '@angular/core';
import {getColorByCode, getColorCodeById} from '../helpers/mapHelper';
import {COLORS} from '../types/enums';

@Pipe({
	name: 'colorCode',
})
export class ColorCodePipe implements PipeTransform {
	transform(value: string, hex: boolean = false): unknown {
		if (hex) {
			return getColorByCode(value.toUpperCase());
		}
		return `#${getColorCodeById(value as COLORS)}`;
	}
}
