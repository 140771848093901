import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from 'primeng/api';
import {Subscription} from 'rxjs';
import {ToastService, MessageType} from 'src/app/services/toast.service';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, OnDestroy {
	constructor(private messageService: MessageService, private toastService: ToastService) {}
	private subscription: Subscription;
	private clearSubscription: Subscription;
	ngOnInit(): void {
		this.subscription = this.toastService.getToastValue().subscribe((value: MessageType) => {
			this.messageService.add(value);
		});
		this.clearSubscription = this.toastService.getClearValue().subscribe(() => {
			this.messageService.clear();
		});
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
		this.clearSubscription?.unsubscribe();
	}
}
