<div class="marker-info" *ngIf="marker">
	<header>
		<i class="icon-arrow" (click)="closeSidebar.emit(false)"></i>
		<h1>Selected {{ marker.iscustomMarker ? 'Pin' : mapService.currentStatus.layer.displayName }}</h1>
	</header>
	<div class="marker-descriptions clearfix">
		<div class="title">
			<!-- <div class="title"> -->
			<h3 class="name">
				<figure *ngIf="marker.color">
					<img
						[src]="[
							'../../../assets/img/map_assets/markers/ic_marker_pin_' +
								(marker.color === 'BLACK'
									? 'black'
									: marker.color === 'BLUE'
									? 'blue'
									: marker.color === 'DEFAULT'
									? 'default'
									: marker.color === 'GREEN'
									? 'green'
									: marker.color === 'ORANGE'
									? 'orange'
									: marker.color === 'PINK'
									? 'pink'
									: marker.color === 'RED'
									? 'red'
									: marker.color === 'WHITE'
									? 'white'
									: 'yellow') +
								'.svg'
						]"
					/>
				</figure>
				{{ marker.favorite ? marker.favorite.nickname : marker.name }}
			</h3>
			<!-- </div> -->
			<app-marker-actions
				[marker]="!marker.iscustomMarker ? marker : undefined"
				[pin]="marker.iscustomMarker ? marker : undefined"
				[shareIcon]="marker.iscustomMarker"
				(editFavorite)="editFavorite($event)"
				(editCustomMarker)="editMarker(marker)"
				(shareCustomMarker)="shareMarker(marker)"
				(removeCustomMarker)="removeCustomMarker.emit($event); closeSidebar.emit(true)"
			></app-marker-actions>
			<app-share
				*ngIf="markersToShare"
				[pins]="markersToShare"
				(closedModal)="markersToShare = undefined"
			></app-share>
		</div>

		<ul class="info-desc">
			<div *ngIf="!marker.iscustomMarker">
				<li>API: {{ marker.apiNum }}</li>
				<li>COUNTY: {{ marker.county }}</li>
			</div>
			<li>LAT: {{ marker.lat | number: '1.1-8' }}</li>
			<li>LON: {{ marker.lng | number: '1.1-8' }}</li>
			<li *ngIf="!marker.iscustomMarker" class="all-space">STATUS: {{ marker.status }}</li>
		</ul>
	</div>

	<div class="marker-gates" *ngIf="!marker.iscustomMarker">
		<h3>Entrances to {{ mapService.currentStatus.layer.displayName }}</h3>
		<perfect-scrollbar>
			<ul>
				<li *ngFor="let gate of marker.gates">
					<figure><img src="../../../assets/img/map_assets/entrances/ic_entrance_pin.svg" /></figure>
					<span class="name">
						{{ gate.name }}
					</span>
					<span class="lat-long">
						Lat: {{ gate.lat | number: '1.1-8' }}, Long: {{ gate.lng | number: '1.1-8' }}
					</span>
					<i *ngIf="!gate.newGate" class="icon-trash" (click)="gateToRemove = gate"></i>
					<i *ngIf="gate.newGate" class="icondl-plus-circled" (click)="addGate(gate)"></i>
				</li>
			</ul>
		</perfect-scrollbar>

		<a class="btn btn-white" (click)="newEntrance.emit({markerId: marker.id})">
			<span class="plus">+</span>
			Add new entrance
		</a>
	</div>

	<div class="route-to-wrapper">
		<div class="add-to-pins" *ngIf="marker.isNewMarker">
			<a class="btn btn-white" (click)="addToMyPins()">Add to my pins</a>
		</div>
		<div class="route-to">
			<a class="btn" (click)="routeToMarker(marker)">
				Route to
				{{ marker.iscustomMarker ? 'this location ' : 'the ' + mapService.currentStatus.layer.displayName }}
			</a>
			<i
				class="icondl-plus-circled"
				(click)="showRouteGates = !showRouteGates"
				*ngIf="!marker.iscustomMarker"
			></i>
			<div class="route-more" [ngClass]="{show: showRouteGates}">
				<div class="white-buttons">
					<a class="btn btn-white" *ngFor="let gate of marker.gates" (click)="selectGate.emit(gate)">
						Route to {{ gate.name }}
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
<app-remove-modal
	*ngIf="gateToRemove"
	[text]="'Are you sure you want to remove this entrance?'"
	(removeConfirmation)="removeConfirmation($event)"
></app-remove-modal>
