import {Injectable} from '@angular/core';
import {ApiResponse} from '../types/dtos/service';
import {NotificationDto} from '../types/dtos/models';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	private apiHost = `${environment.apiHost}/notifications`;

	constructor(private http: HttpClient) {}

	public getNotifications = async (): Promise<ApiResponse<NotificationDto[]>> =>
		this.http
			.get<ApiResponse<NotificationDto[]>>(this.apiHost, {withCredentials: true})
			.toPromise();

	public updateNotification = async (notificationId: number): Promise<ApiResponse<NotificationDto[]>> =>
		this.http
			.put<ApiResponse<NotificationDto[]>>(`${this.apiHost}/${notificationId}`, {}, {withCredentials: true})
			.toPromise();
}
