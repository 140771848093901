import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {CustomMarkerDto, CustomMarkerFolderDto, MarkerDto} from '../types/dtos/models';
import {Router} from '@angular/router';
import {
	MarkerRequest,
	castPaginationToRequestParams,
	MarkerResponse,
	MarkersResponse,
	MarkersClustesResponse,
} from '../helpers/requestHelper';
import {BehaviorSubject, Observable} from 'rxjs';

export interface CustomMarkerOrder {
	customMarkerId: number;
	order: number;
	folderId: number;
}

@Injectable({
	providedIn: 'root',
})
export class MarkerService {
	private apiHost = `${environment.apiHost}/marker`;
	private customMarkerApiHost = `${environment.apiHost}/custom`;
	private selectedMarker: BehaviorSubject<MarkerDto> = new BehaviorSubject<MarkerDto>(null);

	constructor(private http: HttpClient, private router: Router) {}

	public getMarkers = async (markerquery: MarkerRequest): Promise<ApiResponse<MarkersResponse>> =>
		this.http
			.get<ApiResponse<MarkersResponse>>(`${this.apiHost}/online/search`, {
				params: {...castPaginationToRequestParams(markerquery)},
				withCredentials: true,
			})
			.toPromise();

	public getMarkersAndClusters = async (markerquery: any): Promise<ApiResponse<MarkersClustesResponse>> => {
		return this.http
			.get<ApiResponse<MarkersClustesResponse>>(`${this.apiHost}/online/data`, {
				params: {...castPaginationToRequestParams(markerquery)},
				withCredentials: true,
			})
			.toPromise();
	};

	public getMarker = async (markerId: number): Promise<ApiResponse<MarkerResponse>> =>
		this.http
			.get<ApiResponse<MarkerResponse>>(`${this.apiHost}/online/search/${markerId}`, {
				withCredentials: true,
			})
			.toPromise();

	// Custom Markers //

	public getCustomMarkers = async (): Promise<ApiResponse<CustomMarkerDto[]>> =>
		this.http
			.get<ApiResponse<CustomMarkerDto[]>>(`${this.customMarkerApiHost}/marker`, {
				withCredentials: true,
			})
			.toPromise();

	public addCustomMarkers = async (customMarker: Partial<CustomMarkerDto>): Promise<ApiResponse<CustomMarkerDto>> =>
		this.http
			.post<ApiResponse<CustomMarkerDto>>(`${this.customMarkerApiHost}`, customMarker, {
				withCredentials: true,
			})
			.toPromise();

	public addListCustomMarkers = async (
		customMarkers: Partial<CustomMarkerDto>[],
		folders: number[],
		rootFolder: boolean
	): Promise<ApiResponse<CustomMarkerDto[]>> =>
		this.http
			.post<ApiResponse<CustomMarkerDto[]>>(
				`${this.customMarkerApiHost}/bulk`,
				{markers: customMarkers, folders, rootFolder},
				{
					withCredentials: true,
				}
			)
			.toPromise();

	public editCustomMarkers = async (
		customMarker: Partial<CustomMarkerDto>,
		customMarkerId: number
	): Promise<ApiResponse<CustomMarkerDto>> =>
		this.http
			.put<ApiResponse<CustomMarkerDto>>(
				`${this.customMarkerApiHost}`,
				{markerId: customMarkerId, ...customMarker},
				{
					withCredentials: true,
				}
			)
			.toPromise();

	public deleteCustomMarker = async (markerId: number, ownerUserId: number): Promise<ApiResponse<any>> =>
		this.http
			.delete<ApiResponse<any>>(this.customMarkerApiHost, {
				withCredentials: true,
				params: {markerId: markerId.toString(), userId: ownerUserId.toString()},
			})
			.toPromise();

	// Custom Marker Folder //
	public getFolders = async (): Promise<ApiResponse<CustomMarkerFolderDto[]>> =>
		this.http
			.get<ApiResponse<CustomMarkerFolderDto[]>>(`${this.customMarkerApiHost}/folder`, {
				withCredentials: true,
			})
			.toPromise();

	public getCustomMarkersOfFolder = async (
		folderId: number,
		ownerUserId: number
	): Promise<ApiResponse<CustomMarkerDto[]>> =>
		this.http
			.get<ApiResponse<CustomMarkerDto[]>>(`${this.customMarkerApiHost}/folder/marker`, {
				withCredentials: true,
				params: {folderId: folderId.toString(), userId: ownerUserId.toString()},
			})
			.toPromise();

	public addFolder = async (folder: Partial<CustomMarkerFolderDto>): Promise<ApiResponse<CustomMarkerFolderDto>> =>
		this.http
			.post<ApiResponse<CustomMarkerFolderDto>>(`${this.customMarkerApiHost}/folder`, folder, {
				withCredentials: true,
			})
			.toPromise();

	public editFolder = async (
		name: string,
		folderId: number,
		ownerUserId: number
	): Promise<ApiResponse<CustomMarkerFolderDto>> =>
		this.http
			.put<ApiResponse<CustomMarkerFolderDto>>(
				`${this.customMarkerApiHost}/folder`,
				{name, folderId, userId: ownerUserId.toString()},
				{withCredentials: true}
			)
			.toPromise();

	public deleteFolder = async (folderId: string): Promise<ApiResponse<CustomMarkerDto>> =>
		this.http
			.delete<ApiResponse<CustomMarkerDto>>(`${this.customMarkerApiHost}/folder`, {
				params: {folderId},
				withCredentials: true,
			})
			.toPromise();

	public orderCustomMarkers = async (customMarkerOrders: CustomMarkerOrder[]): Promise<ApiResponse<any>> => {
		return this.http
			.put<ApiResponse<any>>(
				`${this.customMarkerApiHost}/order`,
				{orders: customMarkerOrders},
				{withCredentials: true}
			)
			.toPromise();
	};

	getCurrentMarkerSelected(): Observable<MarkerDto> {
		return this.selectedMarker.asObservable();
	}

	setCurrentMarkerSelected(marker: MarkerDto): void {
		this.selectedMarker.next(marker);
	}
	public getMarkersByAPINumbers = async (apis: string[]): Promise<ApiResponse<{markers: MarkerDto[]}>> => {
		return this.http
			.post<ApiResponse<any>>(`${this.apiHost}/online/data/apis`, {apis}, {withCredentials: true})
			.toPromise();
	};
}
