<div class="modal share share-list">
	<div class="modal-content">
		<i class="icon-close" (click)="closedModal.emit()"></i>

		<div class="header">
			<h1 class="title">
				Select the elements to share with other users
			</h1>
		</div>

		<div class="search-bar">
			<input type="text" [(ngModel)]="valueFilter" (ngModelChange)="filterValue.emit(valueFilter)" />
			<i class="icon-search-bar"></i>
		</div>

		<ng-container *ngIf="!pins; else elseBlock">
			<div class="bottom-options clearfix">
				<a class="recent" *ngIf="folders">Folders {{ selectedFolders.length + '/' + folders.length }}</a>
				<a class="select-all" (click)="selectAllFolders()">Select all</a>
			</div>

			<div
				class="user-list folder-list"
				[ngClass]="{
					two: filteredFolders.length === 2,
					three: filteredFolders.length > 2
				}"
			>
				<perfect-scrollbar>
					<ul>
						<li *ngFor="let folder of filteredFolders">
							<div class="pins-value">
								<i class="icon-pins"></i>
								<span class="value">{{ folder.numberOfMarkers }}</span>
							</div>
							<span class="name">{{ folder.name }}</span>
							<a>
								<p-checkbox [value]="folder" [(ngModel)]="selectedFolders"></p-checkbox>
							</a>
						</li>
					</ul>
				</perfect-scrollbar>
			</div>

			<div
				class="user-list favorite-list"
				[ngClass]="{
					two: filteredFolders.length === 2,
					three: filteredFolders.length > 2
				}"
			>
				<div class="bottom-options clearfix">
					<a class="recent" *ngIf="favorites">
						Favorites {{ selectedFavorites.length + '/' + favorites.length }}
					</a>
					<a class="select-all" (click)="selectedAllFavorites()">Select all</a>
				</div>

				<ul class="scrollbar">
					<perfect-scrollbar>
						<li *ngFor="let favorite of filteredFavorites" class="clearfix">
							<figure>
								<img
									[src]="[
										'../../../assets/img/map_assets/markers/ic_marker_pin_' +
											(favorite.color === 'BLACK'
												? 'black'
												: favorite.color === 'BLUE'
												? 'blue'
												: favorite.color === 'DEFAULT'
												? 'default'
												: favorite.color === 'GREEN'
												? 'green'
												: favorite.color === 'ORANGE'
												? 'orange'
												: favorite.color === 'PINK'
												? 'pink'
												: favorite.color === 'RED'
												? 'red'
												: favorite.color === 'WHITE'
												? 'white'
												: 'yellow') +
											'.svg'
									]"
								/>
							</figure>
							<span class="name">{{ favorite.nickname }}</span>
							<a>
								<p-checkbox [value]="favorite" [(ngModel)]="selectedFavorites"></p-checkbox>
							</a>
						</li>
					</perfect-scrollbar>
				</ul>
			</div>
		</ng-container>

		<ng-template #elseBlock>
			<div class="user-list pins-list">
				<div class="bottom-options clearfix">
					<a class="recent" *ngIf="pins">Pins {{ selectedPins.length + '/' + pins.length }}</a>
					<a class="select-all" (click)="selectedAllPins()">Select all</a>
				</div>

				<ul class="scrollbar">
					<perfect-scrollbar>
						<li *ngFor="let pin of filteredPins" class="clearfix">
							<figure>
								<img [src]="['../../../assets/img/map_assets/markers/ic_marker_pin_blue.svg']" />
							</figure>
							<span class="name">{{ pin.name }}</span>
							<a>
								<p-checkbox [value]="pin" [(ngModel)]="selectedPins"></p-checkbox>
							</a>
						</li>
					</perfect-scrollbar>
				</ul>
			</div>
		</ng-template>

		<div class="buttons">
			<button class="btn btn-white" (click)="closedModal.emit(undefined)">Cancel</button>
			<button
				[disabled]="
					(!pins && !selectedFolders.length && !selectedFavorites.length) || (pins && !selectedPins.length)
				"
				class="btn"
				(click)="
					closedModal.emit({
						selectedFolders: selectedFolders,
						selectedFavorites: selectedFavorites,
						selectedPins: selectedPins
					})
				"
			>
				Next
			</button>
		</div>
	</div>
</div>
