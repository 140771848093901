import {ItemData, PolylineDto} from '../types/dtos/models';

export interface FeatureCollectionGeoJson {
	type: string;
	features: FeatureGeoJson[];
}

export interface FeatureGeoJson {
	type: string;
	properties: any;
	geometry: {
		coordinates: number[][];
		type: string;
	};
}

export interface DrawingToShareDto {
	id?: number | string;
	userId: number;
	name: string;
	description: string;
	distance: number;
	color: string;
	polylines: FeatureCollectionGeoJson;
	items: Partial<ItemData>[];
	startingCustomRoute?: boolean;
}

export const polylinesToGeoJson = (polylines: Partial<PolylineDto>[]): FeatureCollectionGeoJson => {
	let features: FeatureGeoJson[] = [];

	polylines.forEach((line) => {
		const feature: FeatureGeoJson = {
			type: 'Feature',
			properties: {},
			geometry: {
				coordinates: line.coordinates.map((coordinate) => [coordinate.lng, coordinate.lat]),
				type: 'LineString',
			},
		};
		features.push(feature);
	});
	const geoJson: FeatureCollectionGeoJson = {
		type: 'FeatureCollection',
		features: features,
	};
	return geoJson;
};

export const geoJsonToPolylines = (geoJson: FeatureCollectionGeoJson): PolylineDto[] => {
	let polylines: PolylineDto[] = [];

	geoJson.features.forEach((feature) => {
		const polyline: PolylineDto = {
			coordinates: feature.geometry.coordinates.map((coordinate) => ({lat: coordinate[1], lng: coordinate[0]})),
		};
		polylines.push(polyline);
	});
	return polylines;
};
