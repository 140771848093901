<div class="page-sidebar share-sidebar" [ngClass]="{show: show}">
	<div class="header">
		<i class="icon-arrow back" (click)="removeModal()"></i>
		<h2>Share {{ asset ? asset.data.name : '' }}</h2>
		<span *ngIf="this.selectedPanel === 'EXTERNAL_LINKS'">Copy the link to share with external users</span>
	</div>
	<div class="content">
		<div class="panel-selection">
			<ul class="tab-view" *ngIf="selectedPanel === 'INTERNAL_USERS' || selectedPanel === 'EXTERNAL_LINKS'">
				<li [ngClass]="{selected: selectedPanel === 'INTERNAL_USERS'}" (click)="selectPanel('INTERNAL_USERS')">
					Internal users
				</li>
				<li
					[ngClass]="{selected: selectedPanel === 'EXTERNAL_LINKS'}"
					(click)="selectPanel('EXTERNAL_LINKS')"
					*ngIf="externalShareFeature"
				>
					Third party routes
				</li>
			</ul>
		</div>
		<div class="internal-users-panel" *ngIf="selectedPanel === 'INTERNAL_USERS' && users.length > 0">
			<div class="search-container left">
				<div class="search-bar">
					<input type="text" [(ngModel)]="valueFilter" class="users-input" />
					<i class="icon-search-bar"></i>
				</div>
			</div>
			<div class="user-list">
				<perfect-scrollbar>
					<ul>
						<li *ngFor="let user of filteredUsers">
							<div class="user">
								<div class="user-info">
									<div class="profile">
										{{ user.firstName | firstLetter }}{{ user.lastName | firstLetter }}
									</div>
									<span class="name">{{ user.email }}</span>
									<a>
										<p-checkbox [value]="user" [(ngModel)]="selectedUsers"></p-checkbox>
									</a>
								</div>
							</div>
						</li>
					</ul>
				</perfect-scrollbar>
			</div>
			<div class="buttons">
				<button class="text-email-btn" [disabled]="selectedUsers.length === 0" (click)="shareAsset('EMAIL')">
					Email
				</button>
				<button class="text-email-btn" [disabled]="selectedUsers.length === 0" (click)="shareAsset('SMS')">
					Text message
				</button>
				<button class="through-app-btn" [disabled]="selectedUsers.length === 0" (click)="shareAsset('CHAT')">
					Send through App
				</button>
			</div>
		</div>
		<div
			class="external-users-panel"
			*ngIf="
				selectedPanel === 'EXTERNAL_LINKS' &&
				!linkDisplay &&
				recipientLicenses.length > 0 &&
				externalShareFeature
			"
		>
			<p>Select the vendor:</p>
			<div class="external-licenses-list">
				<perfect-scrollbar>
					<ul>
						<li *ngFor="let license of recipientLicenses">
							<div
								class="external-license-card"
								[ngClass]="{
									selected: recipientLicenseSelected && license.id === recipientLicenseSelected.id
								}"
								(click)="handleSelectedLicense(license.id)"
							>
								<div class="card-header">
									<div class="plan-info">
										<p-radioButton
											class="external-license-checkbox"
											[(ngModel)]="recipientLicenseSelected"
											[value]="license"
										></p-radioButton>
										<label>{{ license.name }}</label>
									</div>
									<i
										class="icon-arrow"
										[ngClass]="{
											selected:
												recipientLicenseSelected && license.id === recipientLicenseSelected.id
										}"
									></i>
								</div>
								<div
									class="card-content"
									[ngClass]="{
										selected: recipientLicenseSelected && license.id === recipientLicenseSelected.id
									}"
								>
									<div class="available-licenses">
										<h2>Available licenses</h2>
										<span>{{ license.numberOfLicenses - license.numberOfUsers || 0 }}</span>
									</div>
									<div class="used-licenses">
										<h2>Used licenses</h2>
										<span>{{ license.numberOfUsers || 0 }}</span>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</perfect-scrollbar>
			</div>
			<div class="buttons">
				<button class="btn cancel" (click)="removeModal()">Cancel</button>
				<button class="btn" (click)="handleCreateLink()">Continue</button>
			</div>
		</div>
		<div class="external-link-panel" *ngIf="linkDisplay && selectedPanel === 'EXTERNAL_LINKS_COPY_LINK'">
			<div class="license-name">
				<span>{{ recipientLicenseSelected.name }}</span>
			</div>
			<div class="link-info">
				<a>{{ links.urlToSite }}</a>
				<span (click)="handleCopyLink()">Copy link</span>
			</div>
			<div class="share-by">
				<span (click)="handleShareByEmail()">Send by email or sms</span>
			</div>
			<div class="buttons">
				<button class="btn cancel" (click)="cancelLink()">Cancel</button>
				<button class="btn" (click)="removeModal()">Done</button>
			</div>
		</div>
		<div
			class="external-share-by-email-panel"
			*ngIf="
				selectedPanel === 'EXTERNAL_LINKS_SHARE_EMAIL' &&
				!linkDisplay &&
				recipientLicenses.length > 0 &&
				externalShareFeature
			"
		>
			<div class="share-section">
				<span class="guide">Type in email address or phone numbers and hit enter:</span>
				<p-chips separator="," placeholder="Enter email or phone numbers" [(ngModel)]="peopleToSend"></p-chips>
				<span class="guide">Message:</span>
				<textarea placeholder="Message to send" [(ngModel)]="messageToSend"></textarea>
			</div>
			<div class="buttons">
				<button class="btn cancel" (click)="selectPanel('EXTERNAL_LINKS_COPY_LINK'); linkDisplay = true">
					Cancel
				</button>
				<button
					class="btn"
					(click)="handleSendEmail()"
					[disabled]="!messageToSend.length || !peopleToSend.length"
				>
					Send
				</button>
			</div>
		</div>
	</div>
</div>
