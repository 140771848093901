import {Component, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from 'src/app/services/auth.service';
import {AcknowledgeDto, ConfirmationService} from 'src/app/services/confirmation.service';
import {UtilitiesService} from 'src/app/services/utilities.service';
import {PopupStorage} from 'src/app/types/dtos/models';
import {POPUPS_TO_SHOW} from 'src/app/types/enums';

@Component({
	selector: 'app-popup-notifications',
	templateUrl: './popup-notifications.component.html',
	styleUrls: ['./popup-notifications.component.scss'],
})
export class PopupNotificationsComponent implements OnInit {
	private destroy$ = new Subject<void>();

	public showAddPhoneNumberPopup = false;
	public showAcknowledgePopup = false;

	private now = new Date();
	private sevenDaysAgo = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - 7);
	private currentUser;

	public acknowledgePopupData: AcknowledgeDto;

	constructor(
		private utilities: UtilitiesService,
		private auth: AuthService,
		private confirmationService: ConfirmationService
	) {}

	ngOnInit(): void {
		this.auth.currentUser.pipe(takeUntil(this.destroy$)).subscribe((user) => {
			this.currentUser = user;
			this.handlePopups();
		});
		this.acknowledgePopup();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private handlePopups = () => {
		const popups = this.utilities.getPopupsToShow();
		if (this.showPopup(popups, POPUPS_TO_SHOW.ADD_PHONE_NUMBER) && !this.currentUser?.phoneNumber) {
			// If the popup has been shown in the last 7 days, don't show it again
			this.showAddPhoneNumberPopup = true;
		}
	};

	showPopup = (popups: PopupStorage[], id: POPUPS_TO_SHOW) => {
		return (
			!popups ||
			!popups.length ||
			!popups.some((p) => p.id === id) ||
			(popups.some((p) => p.id === id) && popups.find((p) => p.id === id).shownAt < this.sevenDaysAgo)
		);
	};

	public acknowledgePopup = () => {
		this.confirmationService
			.getAcknowledgeShowValue()
			.pipe(takeUntil(this.destroy$))
			.subscribe((value) => {
				this.showAcknowledgePopup = value.show;
				this.acknowledgePopupData = value;
			});
	};

	public confirm(value) {
		this.confirmationService.acknowledgeStatus(value);
	}
}
