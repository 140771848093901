import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {
	ChatCustomMarker,
	ChatCustomMarkersFolder,
	ChatFavoriteMarkersFolder,
	MESSAGE_TYPES,
} from 'src/app/types/dtos/chat';
import {FavoriteService} from 'src/app/services/favorite.service';
import {ToastService} from 'src/app/services/toast.service';
import {CustomMarkerDto, CustomMarkerFolderDto, FavoriteDto, FolderDto, GateDto} from 'src/app/types/dtos/models';
import {LoaderService} from 'src/app/services/loader.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MarkerService} from 'src/app/services/marker.service';
@Component({
	selector: 'app-show-folder-modal',
	templateUrl: './show-folder-modal.component.html',
	styleUrls: ['./show-folder-modal.component.scss'],
})
export class ShowFolderModalComponent implements OnInit {
	@Input('folder')
	set folder(value: ChatFavoriteMarkersFolder | ChatCustomMarkersFolder) {
		this._folder = value;
		this.setForm();
	}
	@Input() type: MESSAGE_TYPES;

	@Output() close = new EventEmitter();

	CUSTOM_FOLDER = MESSAGE_TYPES.CUSTOM_FOLDER;
	FOLDER = MESSAGE_TYPES.FAVORITE_FOLDER;
	public form: UntypedFormGroup = undefined;
	public _folder: ChatFavoriteMarkersFolder | ChatCustomMarkersFolder;
	public importFolderName: boolean = false;
	public userIsFolderOwner: boolean = false;
	constructor(
		private router: Router,
		private favoriteService: FavoriteService,
		private markerService: MarkerService,
		private toastService: ToastService,
		private loaderService: LoaderService
	) {}

	ngOnInit(): void {
		if (this.type === MESSAGE_TYPES.CUSTOM_FOLDER) {
			this.userOwnsCustomFolder();
		} else if (this.type === MESSAGE_TYPES.FAVORITE_FOLDER) {
			this.userOwnsFolder();
		}
	}

	private setForm = () => {
		this.form = new UntypedFormGroup({
			name: new UntypedFormControl(this._folder.name, [Validators.required]),
		});
	};

	selectCustomMarker = (marker) => {
		this.router.navigate(['/map'], {state: {customMarker: marker}});
	};

	selectMarker = (marker) => {
		const folderParsed = this._folder as ChatFavoriteMarkersFolder;
		marker.layerId = folderParsed.layerId;
		this.router.navigate(['/map'], {state: {favoriteMarker: marker}});
	};

	importCustomFolder = async () => {
		try {
			this.loaderService.show();
			const folderParsed = this._folder as ChatCustomMarkersFolder;
			const actualFolder: Partial<CustomMarkerFolderDto> = {
				name: this.form.get('name').value,
			};
			const newFolder = await this.markerService.addFolder(actualFolder);
			const folder = newFolder.data;
			const markersToAdd: CustomMarkerDto[] = folderParsed.markers.map((marker) => ({
				...marker,
				latitude: marker.lat,
				longitude: marker.lng,
			}));
			await this.markerService.addListCustomMarkers(markersToAdd, [folder.id], false);
			this.toastService.show({
				severity: 'success',
				summary: `Custom Markers Folder imported successfully as ${actualFolder.name}`,
			});
		} catch (err) {
			this.toastService.show({severity: 'error', summary: 'Error importing folder'});
		} finally {
			this.loaderService.hide();
			this.close.emit();
		}
	};

	importFolder = async () => {
		try {
			this.loaderService.show();
			const folderParsed = this._folder as ChatFavoriteMarkersFolder;
			const actualFolder: Partial<FolderDto> = {
				name: this.form.get('name').value,
				layerId: folderParsed.layerId,
			};
			const favoritesReq = await this.favoriteService.getFavorites();
			const favorites = favoritesReq.data;
			const newFolder = await this.favoriteService.addFolder(actualFolder);
			const folder = newFolder.data;

			const markers = [];

			folderParsed.markers.forEach(async (marker) => {
				const fav = favorites.find((favorite) => favorite.markerId === marker.id);
				if (fav && fav.gates) {
					marker.gates = marker.gates?.filter((gate) =>
						fav.gates.some((favGate) => gate.lat === favGate.lat && gate.lng === favGate.lng)
					);
				}

				const newFavorite: Partial<FavoriteDto> = {
					id: marker.id,
					nickname: marker.name,
					color: marker.color,
					gates: marker.gates?.map((gate) => ({
						...gate,
						latitude: gate.lat,
						longitude: gate.lng,
					})) as GateDto[],
				};
				markers.push(newFavorite);
			});
			await this.favoriteService.addFavoritesListWithGates(markers, false, [folder.id]);
			this.toastService.show({
				severity: 'success',
				summary: `Folder imported successfully as ${actualFolder.name}`,
			});
		} catch (err) {
			this.toastService.show({severity: 'error', summary: 'Error importing folder'});
		} finally {
			this.loaderService.hide();
			this.close.emit();
		}
	};

	userOwnsCustomFolder = async () => {
		try {
			this.loaderService.show();
			const response = await this.markerService.getFolders();
			const allFolders = response.data;
			this.userIsFolderOwner = allFolders.some((folder) => folder.id === this._folder.id);
		} catch (error) {
		} finally {
			this.loaderService.hide();
		}
	};

	userOwnsFolder = async () => {
		try {
			this.loaderService.show();
			const response = await this.favoriteService.getFolders();
			const allFolders = response.data;
			this.userIsFolderOwner = allFolders.some((folder) => folder.id === this._folder.id);
		} catch (error) {
		} finally {
			this.loaderService.hide();
		}
	};
}
