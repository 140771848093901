import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-popup-acknowledge',
	templateUrl: './popup-acknowledge.component.html',
	styleUrls: ['./popup-acknowledge.component.scss'],
})
export class PopupAcknowledgeComponent implements OnInit {
	@Input() title: string;
	@Input() message: string;
	@Input() buttonText: string;

	@Output() close = new EventEmitter();
	constructor() {}

	closePopup = () => {
		this.close.emit();
	};

	acknowledge = () => {
		this.closePopup();
	};

	ngOnInit(): void {}
}
