import {Injectable, OnDestroy} from '@angular/core';
import 'firebase/storage';
import 'firebase/functions';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import 'firebase/firestore';
import {Message, Timestamp, UserTimestamp} from '../types/dtos/chat';
import {UserDto, UserLogged} from '../types/dtos/models';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {v4 as uuid} from 'uuid';
import {UserService} from './user.service';
import {
	CollectionReference,
	DocumentData,
	DocumentReference,
	doc,
	getDoc,
	getDocs,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	limit,
	where,
	setDoc,
	serverTimestamp,
	addDoc,
	collection,
	Unsubscribe,
	updateDoc,
	deleteField,
} from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import {FirebaseApp, getApp} from 'firebase/app';
import {getAdditionalUserInfo, getAuth} from 'firebase/auth';
import {getStorage, ref, uploadBytes} from 'firebase/storage';
import {getFunctions, httpsCallable, httpsCallableFromURL} from 'firebase/functions';
import {getMessaging, getToken} from 'firebase/messaging';

@Injectable({
	providedIn: 'root',
})
export class ChatService implements OnDestroy {
	private apiHost = `${environment.apiHost}/chat`;
	public user: BehaviorSubject<UserDto> = new BehaviorSubject<UserDto>(null);

	private threadsListening$;
	public threadsListening: BehaviorSubject<UserTimestamp> = new BehaviorSubject<UserTimestamp>(null);

	public myUID: string;

	public selectedUser: string;
	public firebaseApp: FirebaseApp;

	private chatThreads: CollectionReference<DocumentData>;
	private userTimestamps: DocumentReference<DocumentData>;

	private actualThread: DocumentReference<DocumentData>;
	private actualThreadData$: Unsubscribe;
	private actualThreadSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public actualThread$: Observable<any> = this.actualThreadSubject.asObservable();
	public threadOldMessages: Subject<Message<any>> = new Subject<Message<any>>();
	private actualThreadMessages$: Unsubscribe;
	public actualThreadMessages: Subject<Message<any>> = new Subject<Message<any>>();
	private currentUserChat;
	private currentUser: UserLogged;
	private currentUserChatSubscription: Subscription;
	private currentUserSubscription: Subscription;
	private db = getFirestore();
	private auth;
	private storage;
	private functions;

	constructor(private authService: AuthService, private userService: UserService) {}

	ngOnDestroy(): void {
		this.currentUserChatSubscription?.unsubscribe();
		this.currentUserSubscription?.unsubscribe();
	}

	public initChat = async () => {
		try {
			this.auth = getAuth();
			this.storage = getStorage();
			this.functions = getFunctions();
			this.myUID = JSON.parse(localStorage.getItem('currentChatUser'))?.id;
			const chatRoot = doc(this.db, 'root', 'chat');
			this.chatThreads = collection(this.db, chatRoot.path, 'threads');
			this.userTimestamps = doc(this.db, chatRoot.path, 'users', this.myUID);
			// This is to create all the threads if they doesn't exist
			await this.userService.getAllUsers();
			this.setThreadsToListen();
			this.currentUserChatSubscription = this.authService.currentUserChat.subscribe((user) => {
				if (!!user) {
					this.requestPermission();
					this.currentUserChat = user;
				}
				if (!user) {
					this.deleteUserFCMToken();
				}
			});
			this.currentUserSubscription = this.authService.currentUser.subscribe((user) => {
				if (user) {
					this.currentUser = user;
				}
			});
		} catch (err) {
			console.error(err);
		}
	};

	private setThreadsToListen = async (maxReps: number = 5) => {
		const reps = maxReps;
		try {
			if (this.threadsListening$) {
				this.threadsListening$();
			}
			this.threadsListening$ = onSnapshot(this.userTimestamps, (snapshot) => {
				this.threadsListening.next(snapshot.data() as UserTimestamp);
			});
		} catch (err) {
			console.error(err);
			if (reps && this.currentUserChat) {
				setTimeout(() => this.setThreadsToListen(reps - 1), 1000);
			}
		}
	};

	public getUserThreadsTimestamps = async () => {
		try {
			const userTimestamps = await getDoc(this.userTimestamps);
			if (userTimestamps.exists()) {
				return userTimestamps.data() as UserTimestamp;
			}
			return null;
		} catch (err) {
			console.error(err);
		}
	};

	public getLastMessages = async (timestamp: Timestamp, limitV: number) => {
		await getDocs(
			query(
				collection(this.db, this.chatThreads.path, this.actualThread.id, 'messages'),
				orderBy('timestamp', 'desc'),
				where('timestamp', '<', timestamp),
				limit(limitV)
			)
		).then((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				this.threadOldMessages.next(doc.data() as Message<any>);
			});
		});
	};

	public listenThread = async (threadId: string, maxReps: number = 5) => {
		const reps = maxReps;
		try {
			if (this.actualThreadMessages$) {
				this.actualThreadMessages$();
			}
			if (this.actualThreadData$) {
				this.actualThreadData$();
			}
			this.actualThread = doc(this.db, this.chatThreads.path, threadId);
			this.actualThreadData$ = onSnapshot(this.actualThread, (snapshot) => {
				this.actualThreadSubject.next(snapshot.data());
			});
			this.actualThreadMessages$ = onSnapshot(
				query(
					collection(this.db, this.chatThreads.path, threadId, 'messages'),
					orderBy('timestamp', 'desc'),
					where('timestamp', '>', new Date(0)),
					limit(1)
				),
				(snapshot) => {
					snapshot.docs.forEach((change) => {
						this.actualThreadMessages.next(change.data() as Message<any>);
					});
					this.updateReadReceipt();
				}
			);
		} catch (err) {
			console.info(`Retrying to listen the thread ${reps}`);
			if (reps && this.currentUserChat) {
				setTimeout(() => this.listenThread(threadId, reps - 1), 1000);
			}
		}
	};

	public stopListeningThread = () => {
		if (this.actualThreadMessages$) {
			this.actualThreadMessages$();
		}
		if (this.actualThreadData$) {
			this.actualThreadData$();
		}
	};

	public updateReadReceipt = async (threadId?: string, maxReps: number = 5) => {
		const reps = maxReps;
		try {
			const actualThreadId: string = threadId || this.actualThread.id;
			//Update on thread timestamp
			// THIS WAS REMOVED BECAUSE WE ARE NOT USING THE READ_RECEIPT FIELD IN THE THREADS COLLECTION
			// THIS CAN BE USEFUL IN THE FUTURE IF WE WANT TO ADD THE FEATURE OF MESSAGE READ BY THE OTHER USER.
			// const currentUser = this.firebaseApp.auth().currentUser?.uid;
			// if (threadId) {
			// 	await this.chatThreads.doc(threadId).set(
			// 		{
			// 			read_receipts: {[currentUser]: timestamp},
			// 		},
			// 		{merge: true}
			// 	);
			// } else {
			// 	await this.actualThread.set(
			// 		{
			// 			read_receipts: {[currentUser]: timestamp},
			// 		},
			// 		{merge: true}
			// 	);
			// }
			const thread = await doc(this.db, this.chatThreads.path, actualThreadId);
			await setDoc(
				thread,
				{
					read_receipts: {
						[this.myUID]: serverTimestamp(),
					},
				},
				{merge: true}
			);

			//Update on user timestamp document
			await setDoc(
				this.userTimestamps,
				{
					timestamps: {
						[actualThreadId]: {
							read_receipt: serverTimestamp(),
						},
					},
				},
				{merge: true}
			);
		} catch (err) {
			console.info(`Retrying update Read Receipt: ${reps}`);
			if (reps && this.currentUserChat) {
				setTimeout(() => this.updateReadReceipt(threadId, reps - 1), 1000);
			}
		}
	};

	public uploadImage = async (file: File) => {
		const fileName = `${uuid()}_${file.name}`;
		if (this.myUID) {
			// const fileRef = await this.firebaseApp
			// 	.storage()
			// 	.ref(`chat/images/${this.actualThread.id}/${fileName}`)
			// 	.put(file);
			await uploadBytes(ref(this.storage, `chat/images/${this.actualThread.id}/${fileName}`), file);
			return fileName;
		}
	};

	public getImageUrl = async (fileName: string) => {
		const path = `chat/images/${this.actualThread.id}/${fileName}`;
		const getImage = httpsCallable(this.functions, 'getImageURL');
		return getImage({path}).then((result: any) => result.data.url);
	};

	public sendMessage = async (message: Partial<Message<any>>, to?: string, maxReps: number = 5) => {
		const reps = maxReps;
		// const thread = to ? this.chatThreads.doc(to) : this.actualThread;
		const thread = to ? doc(this.db, this.chatThreads.path, to) : this.actualThread;
		try {
			message.id = uuid();
			message.author = {
				id: this.auth.currentUser.uid,
				name: this.auth.currentUser.displayName || '',
			};
			message.timestamp = serverTimestamp();

			const threadCollection = collection(this.db, thread.path, 'messages');
			await addDoc(threadCollection, message);
			this.updateReadReceipt(thread.id);
			return;
		} catch (err) {
			console.error(err);
			console.info(`Retrying to send message ${reps}`);
			if (reps && this.currentUserChat) {
				setTimeout(() => this.sendMessage(message, null, reps - 1), 500);
			}
		}
	};

	requestPermission() {
		const messaging = getMessaging();
		return getToken(messaging, {vapidKey: environment.firebaseConfig.vapidKey})
			.then((currentToken) => {
				if (currentToken) {
					localStorage.setItem('tokenToSendPush', currentToken);
					this.saveUserFCMToken(currentToken);
				} else {
					this.deleteUserFCMToken();
				}
			})
			.catch((err) => {
				this.deleteUserFCMToken();
			});
	}

	public saveUserFCMToken = async (token: string, maxReps: number = 5) => {
		const reps = maxReps;
		try {
			if (this.myUID) {
				const userId = this.currentUser.id;
				const userDoc = doc(this.db, `root/chat/users`, this.myUID);
				await setDoc(userDoc, {tokens: {[userId]: token}}, {merge: true});
			}
		} catch (error) {
			console.error(error);
			if (reps && this.currentUserChat) {
				setTimeout(() => this.saveUserFCMToken(token, reps - 1), 1000);
			}
		}
	};

	public deleteUserFCMToken = async (maxReps: number = 5) => {
		const reps = maxReps;
		try {
			const userId = this.currentUser.id;
			const userDoc = doc(this.db, `root/chat/users`, this.myUID);
			await setDoc(userDoc, {tokens: {[userId]: null}}, {merge: true});
		} catch (error) {
			console.error(error);
		}
	};
}
