<div class="modal show-recorded-route" *ngIf="route">
	<div class="modal-content">
		<div class="title">
			<i class="icon-close" (click)="close.emit()"></i>
			<h1 class="title">'{{ route.name }}' Route</h1>
		</div>
		<div class="modal-footer">
			<button class="btn btn-white" (click)="quickView()">
				Quick View Route
			</button>
			<button
				class="btn"
				(click)="importRouteName = true"
				[disabled]="userIsRouteOwner"
				[title]="userIsRouteOwner ? 'You are the owner of the route' : 'Save route'"
			>
				Save Route
			</button>
		</div>
	</div>
</div>

<div class="modal show-folder-name" *ngIf="importRouteName">
	<div class="modal-content">
		<i class="icon-close" (click)="importRouteName = false"></i>
		<h1 class="title">Import '{{ route.name }}' Route as:</h1>

		<div class="modal-body">
			<form [formGroup]="form" *ngIf="form">
				<input type="text" class="name" formControlName="name" />
			</form>
		</div>

		<div class="modal-footer">
			<button class="btn" [disabled]="!form.valid" (click)="importRecordedRoute()">
				Save
			</button>
		</div>
	</div>
</div>
