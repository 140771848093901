<div class="modal remove">
	<div class="modal-content">
		<i class="icon-close" (click)="removeConfirmation.emit(false)"></i>
		<h1 class="title">{{ text | truncate: 60 }}</h1>
		<div class="buttons">
			<button class="btn btn-white" (click)="removeConfirmation.emit(false)">Cancel</button>
			<button class="btn" (click)="removeConfirmation.emit(true)">Remove</button>
		</div>
	</div>
</div>
