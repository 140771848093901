import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {
	GeoLocationDto,
	GeoNoteDto,
	HazmatRouteRequest,
	MultiStopDto,
	MultiStopRouteDto,
	UserTracking,
	VehicleProfileDto,
} from '../types/dtos/models';
import {ApiResponse} from '../types/dtos/service';
import {HERE_TRANSPORT_MODE, LANGUAGES, MarkerIconFile, OSRMRouteType, StopType} from '../types/enums';
import {DrawService} from './draw.service';
import {LoaderService} from './loader.service';
import {MarkerService} from './marker.service';
import {UserService} from './user.service';
import {LatLngLiteral} from '@agm/core';
import {cleanObject} from '../helpers/utils';
import * as _ from 'lodash';
import {MapService} from './map.service';

@Injectable({
	providedIn: 'root',
})
export class RouteService {
	private apiHost = `${environment.apiHost}/routing`;
	private apiHostRoute = `${environment.apiHost}/route`;
	private options = {
		withCredentials: true,
		responseType: 'arraybuffer',
		observe: 'response',
		headers: {'Content-Type': 'application/zip'},
	};
	private routesSubject: BehaviorSubject<Partial<MultiStopRouteDto>[]> = new BehaviorSubject<MultiStopRouteDto[]>(
		undefined
	);
	public currentRoutes: Observable<Partial<MultiStopRouteDto>[]> = this.routesSubject.asObservable();
	private multiStopRouteOSRMSelectedSubject: BehaviorSubject<MultiStopRouteDto> = new BehaviorSubject<
		MultiStopRouteDto
	>(undefined);
	public multiStopRouteOSRMSelected: Observable<
		MultiStopRouteDto
	> = this.multiStopRouteOSRMSelectedSubject.asObservable();

	private multiStopRouteSelectedPolylineSubject: BehaviorSubject<google.maps.Polyline> = new BehaviorSubject<
		google.maps.Polyline
	>(undefined);
	public multiStopRouteSelectedPolyline: Observable<
		google.maps.Polyline
	> = this.multiStopRouteSelectedPolylineSubject.asObservable();

	public get MultiStopRouteSelectedPolylineValue(): google.maps.Polyline {
		return this.multiStopRouteSelectedPolylineSubject.value;
	}
	public set MultiStopRouteSelectedPolylineValue(polyline: google.maps.Polyline) {
		const currentPolyline = this.multiStopRouteSelectedPolylineSubject.value;
		this.multiStopRouteSelectedPolylineSubject.next(polyline);
		if (currentPolyline) {
			currentPolyline.setMap(null);
		}
	}

	private styleFunc(feature) {
		return {
			clickable: false,
			fillColor: feature.getProperty('color'),
			strokeColor: '#CF4727',
			strokeWeight: 6,
			fillOpacity: 0.08,
			zIndex: 0,
		};
	}

	constructor(
		private http: HttpClient,
		private mapService: MapService,
		private userService: UserService,
		private markerService: MarkerService,
		private drawService: DrawService,
		private loaderService: LoaderService
	) {
		this.getRoutes();
		// this.syncRoutes();
	}

	public syncRoutes = () => {
		setTimeout(async () => {
			await this.getRoutes();
			this.syncRoutes();
		}, 120000);
	};

	public addSearch = async (stops: MultiStopDto): Promise<ApiResponse<any>> =>
		this.http
			.post<ApiResponse<any>>(
				`${this.apiHost}/multi-stop`,
				{...stops},
				{
					withCredentials: true,
				}
			)
			.toPromise();

	public addHazmatSearch = async (hazmatRoute: HazmatRouteRequest): Promise<ApiResponse<any>> =>
		this.http
			.post<ApiResponse<any>>(`${this.apiHost}/hazmat-route/multi-stop`, hazmatRoute, {withCredentials: true})
			.toPromise();

	public getRoutes = async (): Promise<ApiResponse<Partial<MultiStopRouteDto>[]>> => {
		return this.http
			.get<ApiResponse<MultiStopRouteDto[]>>(`${this.apiHostRoute}`, {
				withCredentials: true,
			})
			.toPromise()
			.then((res) => {
				this.routes = res.data;
				return res;
			});
	};

	public getFullRoute = async (routeId: number): Promise<ApiResponse<MultiStopRouteDto>> => {
		return this.http
			.get<ApiResponse<MultiStopRouteDto>>(`${this.apiHostRoute}/${routeId}`, {
				withCredentials: true,
			})
			.toPromise();
	};

	public addRoute = async (route: MultiStopRouteDto): Promise<ApiResponse<MultiStopRouteDto>> => {
		return this.http
			.post<ApiResponse<MultiStopRouteDto>>(
				`${this.apiHostRoute}/`,
				{routes: [{...route}]},
				{
					withCredentials: true,
				}
			)
			.toPromise();
	};

	public editRoute = async (route: MultiStopRouteDto, id: number): Promise<ApiResponse<MultiStopRouteDto>> => {
		return this.http
			.put<ApiResponse<MultiStopRouteDto>>(`${this.apiHostRoute}/${id}`, route, {
				withCredentials: true,
			})
			.toPromise();
	};

	public deleteRoute = async (id: number): Promise<ApiResponse<undefined>> => {
		return this.http
			.delete<ApiResponse<undefined>>(`${this.apiHostRoute}/${id}`, {
				withCredentials: true,
			})
			.toPromise();
	};

	public get routes(): Partial<MultiStopRouteDto>[] {
		return this.routesSubject.value;
	}

	public set routes(routes: Partial<MultiStopRouteDto>[]) {
		this.routesSubject.next(routes);
	}

	private drawRouteOnMap = (route: MultiStopRouteDto, editable = false, showMarkerIndex = false) => {
		this.cleanRouteOnMap();
		const map = this.mapService.mapRef as google.maps.Map;
		const routeCoordinates = route.osrmRoute.routes[0];
		const routePolyline = new google.maps.Polyline({
			path: routeCoordinates.geometry.coordinates.map((coord) => {
				return {lat: coord[1], lng: coord[0]};
			}),
			geodesic: true,
			strokeColor: '#CF4727',
			strokeOpacity: 1.0,
			strokeWeight: 6,
		});
		routePolyline.setMap(map);
		this.multiStopRouteSelectedPolylineSubject.next(routePolyline);
		const markers = [];
		route.stops.forEach((stop) => {
			if (stop.type !== StopType.WAYPOINT) {
				const onlyStops = route.stops.filter((stop) => stop.type !== StopType.WAYPOINT);
				const index = onlyStops.findIndex((onlyStop) => onlyStop === stop);
				const startStop = stop.type === StopType.START ? 0 : 1;
				const partialMarker: Partial<GeoNoteDto> = {
					lat: stop.lat,
					lng: stop.lng,
					title: stop.name,
					description: stop.type,
					stopType: stop.type,
				};
				markers.push(
					this.drawService.createGeoNote(
						partialMarker,
						false,
						MarkerIconFile.PIN_DEFAULT,
						undefined,
						undefined,
						showMarkerIndex && stop.type !== StopType.START ? index + startStop : 1
					)
				);
			} else if (stop.type === StopType.WAYPOINT) {
				const partialMarker: Partial<GeoNoteDto> = {
					lat: stop.lat,
					lng: stop.lng,
					title: stop.name,
					description: stop.type,
					stopType: stop.type,
				};
				markers.push(this.drawService.createWaypoint(partialMarker, true));
			}
		});
		route.markers = markers;
		this.multiStopRouteOSRMSelectedSubject.next(route);
	};

	public cleanRouteOnMap = () => {
		const map = this.mapService.mapRef as google.maps.Map;
		if (this.multiStopRouteSelectedPolylineSubject.value) {
			this.multiStopRouteSelectedPolylineSubject.value.setMap(null);
			this.multiStopRouteSelectedPolylineSubject.value.unbindAll();
		}
		map?.data?.forEach((feature) => {
			map.data.remove(feature);
		});
		this.multiStopRouteOSRMSelectedSubject.value?.markers?.forEach((marker) => {
			if (marker) {
				marker.marker.setMap(null);
			}
		});
		this.multiStopRouteOSRMSelectedSubject.next(undefined);
	};

	public createHazmatRoute = async (
		route: MultiStopRouteDto,
		startOnLocation: boolean = true,
		profileId?: number,
		vehicleProfile?: VehicleProfileDto
	): Promise<HazmatRouteRequest> => {
		this.cleanMultiStopRoute();
		await Promise.all(
			route.stops.map(async (stop) => {
				if (stop.type === StopType.USER) {
					const offset = moment().format('Z');
					const userId = stop.id.toString();
					const location: UserTracking[] = (await this.userService.getUserTracking(userId, offset)).data;
					if (location.length) {
						stop.lat = location[0].lat;
						stop.lng = location[0].lng;
					}
				} else if (stop.type === StopType.MARKER) {
					const marker = (await this.markerService.getMarker(stop.id)).data;
					stop.lat = marker.marker.lat;
					stop.lng = marker.marker.lng;
				}
				return stop;
			})
		);
		const stops: LatLngLiteral[] = route.stops.map((stop) => {
			return {lat: stop.lat, lng: stop.lng};
		});
		if (startOnLocation) {
			stops.unshift({
				lat: this.mapService.mapCurrentLocation.lat,
				lng: this.mapService.mapCurrentLocation.lng,
			});
		}
		const origin = stops[0];
		const destination = stops[stops.length - 1];
		const via = stops.slice(1, stops.length - 1);
		const hazmatRoute: HazmatRouteRequest = {
			origin,
			destination,
			via,
			profileId,
			profile: vehicleProfile,
			transportMode: HERE_TRANSPORT_MODE.truck,
			lang: [LANGUAGES.ENGLISH],
		};
		cleanObject(hazmatRoute);
		return hazmatRoute;
	};

	public createMultiStopDto = async (
		route: MultiStopRouteDto,
		type: OSRMRouteType,
		startOnLocation: boolean = true
	): Promise<MultiStopDto> => {
		await Promise.all(
			route.stops.map(async (stop) => {
				if (stop.type === StopType.USER) {
					const offset = moment().format('Z');
					const userId = stop.id.toString();
					const location: UserTracking[] = (await this.userService.getUserTracking(userId, offset)).data;
					if (location.length && location[0].lat && location[0].lng) {
						stop.lat = location[0].lat;
						stop.lng = location[0].lng;
					} else {
						throw new HttpErrorResponse({error: {message: 'User has no location'}});
					}
				} else if (stop.type === StopType.MARKER) {
					const marker = (await this.markerService.getMarker(stop.id)).data;
					stop.lat = marker.marker.lat;
					stop.lng = marker.marker.lng;
				}
				return stop;
			})
		);
		const stops: GeoLocationDto[] = route.stops.map((stop) => {
			return {lat: stop.lat, long: stop.lng};
		});
		const multiStopDto: MultiStopDto = {
			type,
			bearing: -1.0,
			stops,
		};
		if (startOnLocation) {
			multiStopDto.stops.unshift({
				lat: this.mapService.mapCurrentLocation.lat,
				long: this.mapService.mapCurrentLocation.lng,
			});
		}
		return multiStopDto;
	};

	public cleanMultiStopRoute = () => {
		this.cleanRouteOnMap();
		this.multiStopRouteOSRMSelectedSubject.next(undefined);
	};

	public setMultiStopRoute = (route, editable: boolean = false) => {
		if (route) {
			this.drawRouteOnMap(route, editable, true);
		}
	};

	public fitBounds = async () => {
		if (!this.multiStopRouteOSRMSelectedSubject?.value) return;
		const geoJson = this.multiStopRouteOSRMSelectedSubject.value;
		const map = this.mapService.mapRef as google.maps.Map;
		// getBounds() is the function that returns the bounds of the map for a Polygon
		const getBounds = function (polygon: google.maps.Polygon) {
			const bounds = new google.maps.LatLngBounds();
			const paths = polygon.getPaths();
			let path;
			for (let i = 0; i < paths.getLength(); i++) {
				path = paths.getAt(i);
				for (let ii = 0; ii < path.getLength(); ii++) {
					bounds.extend(path.getAt(ii));
				}
			}
			return bounds;
		};
		// Transform all the coords of the path to LatLng
		const coords = geoJson.osrmRoute.routes[0].geometry?.coordinates.map(
			(coord) => new google.maps.LatLng(coord[1], coord[0])
		);
		// Create a new polygon with the coords
		const polygon = new google.maps.Polygon({
			paths: coords,
		});
		// Get the bounds of the polygon and set the map to fit the bounds
		map.fitBounds(getBounds(polygon));
	};
}
