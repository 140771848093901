import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {MarkerDto, GateDto, FavoriteDto, CustomMarkerDto, StopDto} from 'src/app/types/dtos/models';
import {AuthService} from 'src/app/services/auth.service';
import {LatLngLiteral} from '@agm/core';

import {GateService} from 'src/app/services/gate.service';
import {isSelectedPin} from 'src/app/helpers/mapHelper';
import {LoaderService} from 'src/app/services/loader.service';
import {ToastService} from 'src/app/services/toast.service';
import {throwError} from 'rxjs';
import {MapService} from 'src/app/services/map.service';
import {Router} from '@angular/router';
import {StopType} from 'src/app/types/enums';

@Component({
	selector: 'app-marker-info',
	templateUrl: './marker-info.component.html',
	styleUrls: ['./marker-info.component.scss'],
})
export class MarkerInfoComponent implements OnInit {
	gateToRemove: GateDto = undefined;
	showSidebar = false;
	addFavorite = false;
	showRouteGates = false;
	public markersToShare: MarkerDto[];
	private currentMarker: MarkerDto;
	selectedPin;
	share = false;

	constructor(
		public auth: AuthService,
		private gateService: GateService,
		public mapService: MapService,
		private loaderService: LoaderService,
		private toastService: ToastService,
		private router: Router
	) {}

	@Input('marker')
	set marker(value: MarkerDto) {
		this.currentMarker = value;
		if (value) {
			this.selectedPin = isSelectedPin(value, this.mapService.currentStatus);
		}
	}
	get marker(): MarkerDto {
		return this.currentMarker;
	}

	@Output() deleteGate = new EventEmitter<GateDto>();
	@Output() setDestination = new EventEmitter<LatLngLiteral>();
	@Output() selectGate = new EventEmitter<GateDto>();
	@Output() newEntrance = new EventEmitter<any>();
	@Output() editCustomMarker = new EventEmitter<any>();
	@Output() closeSidebar = new EventEmitter<boolean>();
	@Output() addDeleteFavorite = new EventEmitter();
	@Output() removeCustomMarker = new EventEmitter();
	@Output() addCustomMarker = new EventEmitter<Partial<CustomMarkerDto>>();
	ngOnInit(): void {}

	selectDestination = (lat: number, lng: number) => {
		this.setDestination.emit({lat: lat, lng: lng});
	};

	addToMyPins = async () => {
		const newMarker: Partial<CustomMarkerDto> = {
			name: this.currentMarker.name,
			latitude: this.currentMarker.lat,
			longitude: this.currentMarker.lng,
		};
		this.addCustomMarker.emit(newMarker);
	};

	removeConfirmation = async (remove: boolean) => {
		if (remove) {
			this.loaderService.show();
			try {
				const result = await this.gateService.daleteGate(this.gateToRemove.id);
				this.deleteGate.emit(this.gateToRemove);
			} catch (err) {
				throwError(err);
				this.toastService.show({severity: 'error', summary: 'It was not possible to delete entrance'});
			} finally {
				this.loaderService.hide();
			}
		}
		this.gateToRemove = undefined;
	};

	addGate = async (gate: GateDto) => {
		try {
			this.loaderService.show();
			const gateToAdd: Partial<GateDto> = {
				markerId: this.marker.id,
				name: gate.name,
				latitude: gate.latitude || gate.lat,
				longitude: gate.longitude || gate.lng,
			};
			const result = await this.gateService.AddGate(gateToAdd);
			const newGate = result.data;
			this.currentMarker.gates.map((gate) => {
				gate.lat === newGate.lat && gate.lng === newGate.lng ? (gate.newGate = false) : gate;
				return gate;
			});
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'It was not possible to add entrance'});
		} finally {
			this.loaderService.hide();
		}
	};

	editFavorite = (favorite: FavoriteDto) => {
		if (!favorite.rootFolder && favorite.folders.length == 0) {
			favorite.rootFolder = true;
		}
		this.marker.favorite = favorite;
	};

	shareMarker = (marker: MarkerDto) => {
		this.markersToShare = [marker];
	};

	editMarker = (marker: MarkerDto) => {
		this.editCustomMarker.emit(marker);
	};

	routeToMarker = (marker: MarkerDto) => {
		const stop: StopDto = {
			id: marker.id,
			name: marker.name,
			lat: marker.lat,
			lng: marker.lng,
			type: marker.iscustomMarker ? StopType.COORDINATES : StopType.MARKER,
		};
		// this.router.navigate(['/dashboard/map/routing/routes/add'], {queryParams: {stop: 1}});
		this.router.navigate(['/dashboard/map/routing/routes/add'], {queryParams: {stop: btoa(JSON.stringify(stop))}});
	};
}
