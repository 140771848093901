import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'arrayToUpperCaseCommaSeparated',
})
export class ArrayToUpperCaseCommaSeparatedPipe implements PipeTransform {
	transform(value: string[], ...args: unknown[]): string {
		let result = '';
		value.forEach((element, index) => {
			result += element.toUpperCase();
			if (index !== value.length - 1) {
				result += ', ';
			}
		});
		return result;
	}
}
