import {Injectable} from '@angular/core';
import {ApiResponse, PaginationResponse} from '../types/dtos/service';
import {EnterprisePlanDto, InviteAllowedDto, InvitedUserDto, InviteUserToPlanDto, PermissionDto} from '../types/dtos/models';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {castPaginationToRequestParams, PaginationRequest, setDataAndPagination} from '../helpers/requestHelper';

@Injectable({
	providedIn: 'root',
})
export class PlanService {
	private apiHost = `${environment.apiHost}/plans`;

	constructor(private http: HttpClient) {}

	public getPlans = async (): Promise<ApiResponse<EnterprisePlanDto[]>> =>
		this.http
			.get<ApiResponse<EnterprisePlanDto[]>>(`${this.apiHost}/available`, {
				withCredentials: true,
			})
			.toPromise();

	public inviteAllowed = async (data): Promise<ApiResponse<InviteAllowedDto>> =>
		this.http
			.post<ApiResponse<InviteAllowedDto>>(`${this.apiHost}/inviteAllowed`, {...data}, {withCredentials: true})
			.toPromise();

	public inviteUserToBusinessPlan = async (data: InviteUserToPlanDto): Promise<ApiResponse<any>> =>
		this.http.post<ApiResponse<any>>(`${this.apiHost}/invite`, {...data}, {withCredentials: true}).toPromise();

	public getInvitations = async (
		pagination?: PaginationRequest,
		status?: string
	): Promise<ApiResponse<InvitedUserDto[]>> =>
		this.http
			.get<ApiResponse<PaginationResponse<InvitedUserDto[]>>>(`${this.apiHost}/invitations`, {
				params: pagination ? {...castPaginationToRequestParams(pagination), status} : {},
				withCredentials: true,
			})
			.toPromise()
			.then((result) => {
				if (pagination) return setDataAndPagination(result);
				else return result;
			});

	public resendInvite = async (invitationId: string): Promise<ApiResponse<any>> =>
		this.http
			.post<ApiResponse<any>>(`${this.apiHost}/resendInvite`, {}, {params: {invitationId}, withCredentials: true})
			.toPromise();

	public anulateInvite = async (invitationId: string): Promise<ApiResponse<any>> =>
		this.http
			.post<ApiResponse<any>>(
				`${this.apiHost}/anulateInvite`,
				{},
				{params: {invitationId}, withCredentials: true}
			)
			.toPromise();

	public updateBusinessPlans = async (permissions: {permissions: PermissionDto[]}): Promise<ApiResponse<any>> =>
		this.http
			.put<ApiResponse<any>>(
				`${this.apiHost}/permissions`,
				permissions,
				{withCredentials: true}
			)
			.toPromise();
}
