import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {PaginationMetadata} from 'src/app/helpers/requestHelper';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
	constructor() {}

	@Input() pagination: PaginationMetadata;
	@Output() setPage = new EventEmitter();

	ngOnInit() {}
}
