<div class="route-item">
	<div class="body">
		<app-marker-actions
			[partialRoute]="type === 'route' ? route : undefined"
			[customRouteId]="type === 'drawing' ? route.id : undefined"
			[recordRoute]="type === 'recorded-route' ? route : undefined"
			[shareIcon]="true"
			[showClone]="showClone"
			(clone)="clone.emit(route)"
			(editRoute)="edit.emit(route)"
			(delete)="delete.emit(route)"
			(shareRoute)="handleShareContent($event)"
		></app-marker-actions>
		<span class="not-reviewed" [title]="route.id" *ngIf="isRecordedRouteDto(route) && !isReviewed(route)">
			<i class="small-circle"></i>
			Not reviewed
		</span>
		<h2 class="title" [title]="route.name || 'Route'">
			{{ route.name || 'Route' }}
		</h2>
		<span class="description" [title]="route.description || ''">
			{{ route.description || '' | truncate: 200 }}
		</span>
		<div class="creator" *ngIf="isRecordedRouteDto(route)">
			<b>Recorded By:</b>
			<span class="name">{{ route.createdByUser?.firstName }} {{ route.createdByUser.lastName }}</span>
			<span class="email">
				{{ route.createdByUser?.email }}
			</span>
		</div>
		<div class="creator" *ngIf="isRecordedRouteDto(route)">
			<b>Recorded On:</b>
			<span class="name">
				{{ route.createdAt | date }}
			</span>
		</div>
		<div class="creator" *ngIf="isRecordedRouteDto(route) && recordedRouteLastStop">
			<b>Destination:</b>
			<div class="destination">
				<i class="icon-dotbar"></i>
				<span class="name">
					{{ recordedRouteLastStop.title }}
				</span>
			</div>
		</div>
		<div class="record-route-link" *ngIf="displayUrl && isRecordedRouteDto(route)">
			<b>Route link:</b>
			<a class="url" [title]="recordRouteLink || ''" (click)="handleClick(recordRouteLink)">
				{{ recordRouteLink || '' }}
			</a>
		</div>
		<div class="route-url" *ngIf="displayUrl && isMultiStopRouteDto(route)">
			<p class="route-type">Route to Site</p>
			<a class="url" [title]="route.url || ''" (click)="handleClick(route.url)">
				{{ route.url || '' }}
			</a>
		</div>
		<div class="return" *ngIf="displayRouteReturn && isMultiStopRouteDto(route) && route.return">
			<div class="menu">
				<i class="icon-dots" (click)="toggleRouteReturnMenu($event)"></i>
			</div>
			<p class="route-type">Return Route</p>
			<a class="url" [title]="route.return.url || ''" (click)="handleClick(route.return.url)">
				{{ route.return.url || '' }}
			</a>
		</div>
	</div>
	<div class="bottom-options">
		<div class="tag">
			<span class="hazmat" *ngIf="isMultiStopRouteDto(route) && route.isHazmat">
				Hazmat Route
			</span>
		</div>
		<div class="buttons">
			<button class="btn secondary" (click)="quickView.emit(route)">
				Quick view
			</button>
			<button class="btn secondary" (click)="edit.emit(route)">Edit</button>
		</div>
	</div>
</div>
