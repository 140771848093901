import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './helpers/guards/auth.guard';
import {VerificationComponent} from './verification/verification.component';

const routes: Routes = [
	{path: 'analytics.txt', component: VerificationComponent},
	{
		path: 'dashboard',
		loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard],
	},
	{path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)},
	{path: '**', redirectTo: 'dashboard'},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
