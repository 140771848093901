import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from 'src/app/services/auth.service';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService, private router: Router) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authService
			.getUserData()
			.then((user) => {
				if (user) {
					return true;
				}
				this.router.navigate(['/']);
				return false;
			})
			.catch((error) => {
				if (error.status === 401 && this.router.url !== '/login') {
					this.router.navigate(['/login']);
				}
				return false;
			});
	}
}
