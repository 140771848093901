import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {first} from 'rxjs/operators';

export interface ConfirmationDto {
	show: boolean;
	title?: string;
	message?: string;
	confirmationText?: string;
	rejectText?: string;
}

export interface AcknowledgeDto {
	show: boolean;
	title?: string;
	message?: string;
	buttonText?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ConfirmationService {
	private openConfirmationModal = new Subject<ConfirmationDto>();
	private resultConfirmation = new Subject<boolean>();

	private openAcknowledgePopup = new Subject<AcknowledgeDto>();
	private resultAcknowledge = new Subject<boolean>();

	// Confirmation Modal
	public show(title: string, message: string, confirmationText: string, rejectText: string) {
		this.openConfirmationModal.next({show: true, title, message, confirmationText, rejectText});
	}

	public confirmStatus(value: boolean) {
		this.resultConfirmation.next(value);
		this.openConfirmationModal.next({show: false});
	}

	public getConfirmationShowValue() {
		return this.openConfirmationModal.asObservable();
	}

	public async getConfirmationValue() {
		return await this.resultConfirmation.pipe(first()).toPromise();
	}

	// Acknowledge Popup
	public showAcknowledge(title: string, message: string, buttonText: string) {
		this.openAcknowledgePopup.next({show: true, title, message, buttonText});
	}

	public acknowledgeStatus(value: boolean) {
		this.resultAcknowledge.next(value);
		this.openAcknowledgePopup.next({show: false});
	}

	public getAcknowledgeShowValue() {
		return this.openAcknowledgePopup.asObservable();
	}

	public async getAcknowledgeValue() {
		return await this.resultAcknowledge.pipe(first()).toPromise();
	}
}
