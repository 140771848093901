// Markers request

import {MarkerDto, ClusterDto} from '../types/dtos/models';
import {ApiResponse, PaginationResponse} from '../types/dtos/service';

export interface PaginationRequest {
	page: number;
	pageSize: number;
}

export interface MarkerRequest {
	params?: string;
	long: number;
	lat: number;
	layerId: string;
	states: string;
	byApi: string;
}

export interface MarkersResponse {
	markers: MarkerDto[];
	params: string;
}

export interface MarkersClustesResponse {
	centroid: {
		geohash: string;
		latitude: number;
		longitude: number;
	};
	geohashLevel: number;
	neighboursLevel: number;
	clusters: {
		count: number;
		data: ClusterDto[];
	};
	markers: {
		count: number;
		data: MarkerDto[];
	};
	params: string;
}

export interface MarkerResponse {
	marker: MarkerDto;
	params: string;
}

export type QueryParams = {
	[param: string]: string;
};

// Pagination
export const defaultPageSize = 10;

export interface PaginationRequest {
	page: number;
	pageSize: number;
}

export interface PaginationMetadata {
	page: number;
	pageSize: number;
	totalRecords: number;
	totalPages: number;

	// Aux property: Used to render next two page numbers
	nextPages?: number[];
}

export const restartPagination = (pageSize?: number): PaginationMetadata => ({
	page: 1,
	pageSize: pageSize || defaultPageSize,
	totalRecords: 0,
	totalPages: 0,
});

export const castPaginationToRequestParams = (req?: any): QueryParams =>
	Object.keys(req || {}).reduce((res, key) => ({...res, [key]: (req[key] || '').toString()}), {});

export const setDataAndPagination = (result: ApiResponse<PaginationResponse<any>>): ApiResponse<any> => {
	const {data, ...rest} = result;
	return {
		...rest,
		data: data.rows,
		pagination: {
			page: data.currentPage,
			pageSize: data.pageSize,
			totalRecords: data.totalCount,
			totalPages: data.totalPages,
		},
	};
};
