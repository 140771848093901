import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-notification-custom-modal',
	templateUrl: './notification-custom-modal.component.html',
	styleUrls: ['./notification-custom-modal.component.scss'],
})
export class NotificationCustomModalComponent implements OnInit {
	@Output() close = new EventEmitter();
	constructor() {}

	ngOnInit(): void {}
}
