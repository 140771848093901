<div class="reset-credentials" [ngClass]="{modal: router.url === '/dashboard/users'}">
	<div class="modal-content">
		<i *ngIf="router.url === '/dashboard/users'" class="icon-close" (click)="closeResetPassword()"></i>
		<h1 class="modal-title">
			Reset password
			{{
				router.url === '/dashboard/users' && currentUser
					? 'for ' + currentUser.firstName + ' ' + currentUser.lastName
					: ''
			}}
		</h1>
		<ul>
			<li>
				<label>Password</label>
				<span class="ui-float-label">
					<input
						type="password"
						pInputText
						[(ngModel)]="password"
						placeholder=""
						#input1
						autocomplete="new-password"
					/>
				</span>
			</li>
			<li>
				<label>Confirm Password</label>
				<span class="ui-float-label">
					<input
						type="password"
						pInputText
						[(ngModel)]="confirmPassword"
						placeholder=""
						#input1
						autocomplete="new-password"
					/>
				</span>
			</li>
		</ul>
		<button
			type="button"
			class="btn"
			[ngClass]="{'save-button': router.url !== '/dashboard/users'}"
			[disabled]="!password || !confirmPassword || password !== confirmPassword"
			(click)="resetPassword()"
		>
			Save
		</button>
	</div>
</div>
