import {AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ConfirmationService} from 'src/app/services/confirmation.service';
import {GeoNoteDto, ItemContent} from 'src/app/types/dtos/models';
import {ContentType, ItemType} from 'src/app/types/enums';
import * as uuid from 'uuid';
import * as _ from 'lodash';
import {MapService} from 'src/app/services/map.service';
@Component({
	selector: 'app-add-edit-note',
	templateUrl: './add-edit-note.component.html',
	styleUrls: ['./add-edit-note.component.scss'],
})
export class AddEditNoteComponent implements OnInit, OnDestroy, AfterContentInit {
	constructor(private confirmationService: ConfirmationService, private mapService: MapService) {}
	public title: string = '';
	public description: string = '';
	public modifiedNote: GeoNoteDto;
	public audio: ItemContent;
	public image: ItemContent;
	public listeners: google.maps.MapsEventListener[] = [];
	private noteValues: GeoNoteDto;

	@Input() index: number;
	@Input('note')
	set note(value: GeoNoteDto) {
		if (value) {
			this.noteValues = value;
			this.listeners.push(
				value.marker.addListener('drag', (event) => {
					this.modifiedNote.lat = event.latLng.lat();
					this.modifiedNote.lng = event.latLng.lng();
					value.lat = event.latLng.lat();
					value.lng = event.latLng.lng();
				})
			);
			this.modifiedNote = {...value, content: _.cloneDeep(value.content)};
			this.description = value.description;
			this.mergeFieldsIntoDescription(value);
			this.audio = this.modifiedNote.content.find((item) => item.type.includes(ContentType.AUDIO));
			this.image = this.modifiedNote.content.find((item) => item.type.includes(ContentType.IMAGE));
		}
	}

	@Output() save = new EventEmitter<GeoNoteDto>();
	@Output() close = new EventEmitter();
	@Output() delete = new EventEmitter<number>();

	ngAfterContentInit(): void {
		if (!this.modifiedNote) {
			this.modifiedNote = {
				id: uuid.v4(),
				lat: this.mapService.mapCenterActual.lat,
				lng: this.mapService.mapCenterActual.lng,
				title: '',
				description: '',
				content: [
					{
						type: ContentType.IMAGE,
						data: '',
					},
					{
						type: ContentType.AUDIO,
						data: '',
						transcription: '',
					},
				],
			};
		}
		this.modifiedNote.content.forEach((item) => {
			if (item.type.includes(ContentType.AUDIO)) {
				this.audio = item;
			}
			if (item.type.includes(ContentType.IMAGE)) {
				this.image = item;
			}
		});
	}
	ngOnInit(): void {}
	ngOnDestroy(): void {
		this.listeners.forEach((listener) => listener.remove());
	}

	mergeFieldsIntoDescription = (value: GeoNoteDto) => {
		if (value.title) {
			this.modifiedNote.description = `${value.title}\n\n${value.description}`;
		}
		const audio = value.content.find((content) => content?.transcription);
		if (audio?.transcription) {
			this.modifiedNote.description = `${value.title}\n\n${value.description}\n\n${audio.transcription}`;
		}
		value.title = '';
		value.content?.forEach((content) => {
			if (content.transcription) {
				content.transcription = '';
			}
		});
	};

	public cancel = async () => {
		const unsavedChanges =
			this.noteValues.description === this.modifiedNote.description &&
			this.noteValues.lat === this.modifiedNote.lat &&
			this.noteValues.lng === this.modifiedNote.lng &&
			JSON.stringify(this.noteValues.content) === JSON.stringify(this.modifiedNote.content);
		if (unsavedChanges) {
			this.close.emit();
		} else {
			this.confirmationService.show('Are you sure?', 'All the changes will be discarded', 'Yes', 'No');
			const confirmation = await this.confirmationService.getConfirmationValue();
			if (confirmation) {
				this.close.emit();
			}
		}
	};

	public handleNewTranscription = (event: ItemContent) => {
		const sameDescription = this.modifiedNote.description.includes(event.transcription);
		if (this.modifiedNote.description === '') {
			this.modifiedNote.description = `${event.transcription}`;
		} else if (!sameDescription && event.transcription) {
			this.modifiedNote.description = `${this.modifiedNote.description}\n${event.transcription}`;
		}
	};

	public saveNote = async () => {
		this.confirmationService.show('Are you sure?', 'All the changes will be saved', 'Yes', 'No');
		const confirmation = await this.confirmationService.getConfirmationValue();
		this.modifiedNote.title = '';
		if (confirmation) {
			this.modifiedNote.marker.setLabel({
				text: 'Note',
				className: 'draw-marker-label',
				color: 'black',
			});
			if (_.isEmpty(this.audio?.data)) {
				this.audio = undefined;
			}
			if (_.isEmpty(this.image?.data)) {
				this.image = undefined;
			}
			if (this.audio && !this.audio.id) {
				const type = this.audio.data.match(/data:(audio\/[^;]*)/i);
				this.audio.type = type[1];
				this.audio.data = this.audio.data.replace(/codecs=.*;/g, '');
			}
			if (this.image && !this.image.id) {
				const type = this.image.data.match(/data:(image\/[^;]*)/i);
				this.image.type = type[1];
			}
			this.modifiedNote.content = _.compact([this.audio, this.image]);
			this.save.emit(this.modifiedNote);
		}
	};
	public deleteNote = () => {
		this.delete.emit(this.index);
	};
}
