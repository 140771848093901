<div class="modal pending-users">
	<div class="modal-content">
		<i class="icon-close" (click)="closeModal.emit()"></i>
		<div class="header"></div>
		<div class="body">
			<div class="table-container pending-users-list clearfix">
				<div class="header">
					<h3 class="title">Pending Invitations</h3>
				</div>
				<table>
					<thead>
						<tr>
							<th class="spacer"></th>
							<th class="mail">Email</th>
							<th class="sent-date">Date</th>
							<th class="invited-by">Invited by</th>
							<th class="business-plan">Business plan</th>
							<th class="buttons"></th>
							<th class="spacer"></th>
						</tr>
					</thead>
				</table>
				<div class="table-wrapper">
					<perfect-scrollbar #perfectScrollBarPendingUsers (psScrollY)="onScroll()">
						<table>
							<tbody>
								<tr *ngFor="let user of pendingUsers">
									<td class="spacer"></td>
									<td class="mail">{{ user.invitedUser.email }}</td>
									<td class="sent-date">
										<span class="date">{{ user.updatedAt | date }}</span>
										<span clas="hour">{{ user.updatedAt | date: 'shortTime' }}</span>
									</td>
									<td class="invited-by">
										{{ user.creatorUser.firstName }} {{ user.creatorUser.lastName }}
									</td>
									<td class="business-plan">
										{{ user.businessSubscription.name }}
									</td>
									<td class="buttons clearfix">
										<button
											class="btn btn-sqr delete"
											title="Delete invitation"
											(click)="anulateInvite(user.id)"
										>
											<i class="icon-close"></i>
										</button>
										<button
											class="btn btn-sqr resend"
											title="Resend invitation"
											(click)="resendInvite(user.id)"
										>
											<i class="icon-reset"></i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
					</perfect-scrollbar>
				</div>
			</div>
		</div>
	</div>
</div>
