import {Component, OnInit} from '@angular/core';
import {LoaderService} from 'src/app/services/loader.service';

@Component({
	selector: 'loader-component',
	templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit {
	constructor(public loaderService: LoaderService) {}

	public loading = [];
	ngOnInit() {
		this.loaderService.getLoaderValue().subscribe((value) => {
			if (value) {
				this.loading.push(value);
			} else {
				this.loading.pop();
			}
		});
	}
}
