<div class="modal add-list">
	<div class="modal-content" [ngClass]="{pins: addPins}">
		<i class="icon-close" (click)="closedModal.emit()"></i>

		<div class="header">
			<h1 class="title">Add List</h1>
			<p class="subtitle">
				{{
					addPins
						? 'Upload a file from your computer'
						: 'Type a list of APIs or upload a file from your computer'
				}}
			</p>
		</div>

		<form *ngIf="addFavourites" [formGroup]="form">
			<ul>
				<li>
					<label>APIs</label>
					<input type="text" formControlName="apiNums" />
				</li>

				<li>
					<label for="">Upload your CSV file</label>
					<input
						#csvInput
						type="file"
						name="Upload CSV"
						id="txtFileUpload"
						accept=".csv"
						(change)="readCSV(csvInput)"
					/>
				</li>

				<li>
					<label for="">Choose a folder for this list</label>
					<select type="text" formControlName="folder">
						<option *ngFor="let folder of folders" [ngValue]="folder.id">
							{{ folder.name }}
						</option>
					</select>
				</li>
			</ul>

			<div class="buttons">
				<button class="btn btn-white" (click)="closedModal.emit()">Cancel</button>
				<button class="btn" [disabled]="!form.valid" (click)="addFavorites()">Save</button>
			</div>
		</form>

		<form *ngIf="addPins" [formGroup]="form">
		<ul *ngIf="addPins">
			<li>
				<label for="">Upload your CSV file</label>
				<input
					#csvInput
					type="file"
					name="Upload CSV"
					id="txtFileUpload"
					accept=".csv"
					(change)="readCSV(csvInput)"
				/>
			</li>
			<li>
				<label for="">Choose a folder for this list</label>
				<select type="text" formControlName="folder">
					<option *ngFor="let folder of folders" [ngValue]="folder.id">
						{{ folder.name }}
					</option>
				</select>
			</li>
			<div class="buttons">
				<button class="btn btn-white" (click)="closedModal.emit()">Cancel</button>
				<button class="btn" [disabled]="!customMarkers || !customMarkers.length" (click)="addCustomMarkers()">
					Save
				</button>
			</div>
			</ul>
		</form>
	</div>
</div>
