<div class="pagination clearfix">
	<span class="page-number">
		{{ pagination.pageSize * (pagination.page - 1) + 1 }}-{{
			pagination.pageSize * (pagination.page - 1) + pagination.pageSize < pagination.totalRecords
				? pagination.pageSize * (pagination.page - 1) + pagination.pageSize
				: pagination.totalRecords
		}}
		of {{ pagination.totalRecords }}
	</span>

	<a
		[href]=""
		class="linkeable prev"
		[ngClass]="{disabled: pagination.page === 1}"
		(click)="setPage.emit(pagination.page - 1)"
	>
		<span class="icon-left"></span>
	</a>

	<a
		[href]=""
		class="linkeable next"
		[ngClass]="{disabled: pagination.totalPages === pagination.page}"
		(click)="setPage.emit(pagination.page + 1)"
	>
		<span class="icon-right"></span>
	</a>
</div>
