import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {MapOptionsToShow, PopupStorage, PortalFeaturesEnabled} from '../types/dtos/models';
import {POPUPS_TO_SHOW, PORTAL_FEATURES} from '../types/enums';
import {Menu} from 'primeng';

@Injectable({
	providedIn: 'root',
})
export class UtilitiesService {
	public documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
	public menuSubject: BehaviorSubject<Menu> = new BehaviorSubject<Menu>(undefined);
	public menu$ = this.menuSubject.asObservable();

	private mapOptionsToShow: BehaviorSubject<MapOptionsToShow> = new BehaviorSubject<MapOptionsToShow>({
		LOCK_ZOOM: true,
		ZOOM_IN: true,
		ZOOM_OUT: true,
		SET_CURRENT_LOCATION: true,
		MAP_SETTINGS: true,
		FAVORITES: true,
		LAYERS: true,
		ADD_NOTE: false,
		FIT_BOUNDS: false,
	});
	public mapOptionsToShow$ = this.mapOptionsToShow.asObservable();

	private featuresEnabled: BehaviorSubject<PortalFeaturesEnabled> = new BehaviorSubject<PortalFeaturesEnabled>({
		SEARCH_LAYER: false,
		FAVORITES: false,
		PINS: false,
		USERS_LOCATION: false,
		ROUTES: false,
		CUSTOM_ROUTES: false,
		CHAT: false,
		USERS: false,
		SETTINGS: false,
		VEHICLE_PROFILES: false,
		SHARE_BETWEEN_PORTAL_USERS: false,
		DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT: false,
		INSIGHTS_DASHBOARD: false,
	});
	public featuresEnabled$ = this.featuresEnabled.asObservable();

	constructor() {}

	public enableFeatures = (menuItems: PORTAL_FEATURES[]) => {
		const featuresEnabled = this.featuresEnabled.getValue();
		menuItems.forEach((item) => (featuresEnabled[item] = true));
		this.featuresEnabled.next(featuresEnabled);
	};

	public getPopupsToShow = (): PopupStorage[] => {
		const popups = localStorage.getItem('popups') ? JSON.parse(localStorage.getItem('popups')) : [];
		return popups.map((p) => ({id: p.id, shownAt: new Date(p.shownAt)}));
	};

	public setPopupShown = (id: POPUPS_TO_SHOW) => {
		const popups = this.getPopupsToShow();
		const popupExists = popups.some((p) => p.id === id);
		if (!popupExists) {
			popups.push({id, shownAt: new Date()});
		} else {
			popups.forEach((p) => {
				if (p.id === id) {
					p.shownAt = new Date();
				}
			});
		}
		localStorage.setItem('popups', JSON.stringify(popups));
	};

	public setMapOptionsToShow = (options: MapOptionsToShow) => {
		setTimeout(() => {
			this.mapOptionsToShow.next(options);
		}, 0);
	};
	public getMapOptionsToShow = (): MapOptionsToShow => {
		return this.mapOptionsToShow.getValue();
	};

	public setMenu = (menu: Menu) => {
		this.menuSubject.next(menu);
	};
}
