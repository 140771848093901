<section id="show-stops-section" *ngIf="show">
	<div class="modal show-stops">
		<div class="modal-content">
			<i class="icon-close" (click)="close()"></i>
			<h1 class="title"></h1>
			<div class="modal-header">
				<h1>Select Marker</h1>
				<br />
				<input type="text" [(ngModel)]="valueFilter" />
			</div>
			<div class="modal-body">
				<div class="selecting-stop">
					<!-- TAB PANEL OPTIONS -->
					<ul class="tab-view">
						<li
							*ngIf="showLayer"
							[ngClass]="{selected: selectedPanel === 'LAYER'}"
							(click)="selectPanel('LAYER')"
						>
							{{ selectedLayer?.displayName }}
						</li>
						<li
							*ngIf="showFavorites"
							[ngClass]="{selected: selectedPanel === 'FAVORITES'}"
							(click)="selectPanel('FAVORITES')"
						>
							Favorites
						</li>
						<li
							*ngIf="showMyPins"
							[ngClass]="{selected: selectedPanel === 'MY_PINS'}"
							(click)="selectPanel('MY_PINS')"
						>
							My Pins
						</li>
						<li
							*ngIf="showUsers"
							[ngClass]="{selected: selectedPanel === 'USERS'}"
							(click)="selectPanel('USERS')"
						>
							Users
						</li>
					</ul>

					<div class="tab-view-panels">
						<perfect-scrollbar>
							<!-- PANEL LAYERS -->
							<div class="panel layer" *ngIf="selectedPanel === 'LAYER' && markers">
								<div class="search-results">
									<ul>
										<li
											*ngFor="let marker of filteredMarkers; index as i"
											class="item-search"
											(click)="selectMarker(marker)"
										>
											<div class="list-content cursor-pointer">
												<div class="clearfix">
													<i class="icon-pin-small default"></i>
													<p class="name">
														{{ marker.name }}
													</p>
												</div>
												<span class="api-num">
													Api #{{ marker.apiNum }} - {{ marker.distance | number: '1.1-1' }}
													{{ marker.unit | lowercase }} away
												</span>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<!-- PANEL FAVORITES -->
							<div
								class="panel favorites"
								*ngIf="selectedPanel === 'FAVORITES' && (folders || favorites)"
							>
								<ul>
									<li class="folder-item clearfix" *ngFor="let folder of filteredFolders; index as j">
										<div
											class="list-content cursor-pointer"
											[ngClass]="{selected: openFolder === j}"
											(click)="toggleFolder(j)"
										>
											<div class="clearfix">
												<div class="pins-value">
													<i class="icon-pins"></i>
													<span class="value">{{ folder.numberOfMarkers }}</span>
												</div>
												<span class="name">{{ folder.name }}</span>
												<i class="icon-arrow"></i>
											</div>
										</div>
										<div class="folder-content" *ngIf="openFolder === j">
											<ul>
												<li
													*ngFor="let favorite of folder.favoriteMarkers; index as i"
													class="clearfix"
													(click)="selectFavorite(favorite); openFolder = null"
												>
													<figure>
														<img
															[src]="[
																'../../../assets/img/map_assets/markers/ic_marker_pin_' +
																	(favorite.color === 'BLACK'
																		? 'black'
																		: favorite.color === 'BLUE'
																		? 'blue'
																		: favorite.color === 'DEFAULT'
																		? 'default'
																		: favorite.color === 'GREEN'
																		? 'green'
																		: favorite.color === 'ORANGE'
																		? 'orange'
																		: favorite.color === 'PINK'
																		? 'pink'
																		: favorite.color === 'RED'
																		? 'red'
																		: favorite.color === 'WHITE'
																		? 'white'
																		: 'yellow') +
																	'.svg'
															]"
														/>
													</figure>
													<div class="favorite-description">
														<p class="name">{{ favorite.nickname }}</p>
													</div>
												</li>
											</ul>
										</div>
									</li>
								</ul>
								<div class="separator" *ngIf="filteredFolders"></div>
								<ul>
									<li
										class="clearfix"
										*ngFor="let favorite of filteredFavorites; index as i"
										(click)="selectFavorite(favorite)"
									>
										<div class="list-content cursor-pointer clearfix">
											<div class="favorite-description">
												<i class="icon-pin-small" [ngClass]="favorite.color | lowercase"></i>
												<p class="name">
													{{ favorite.nickname }}
												</p>
												<span class="api-num">Api #{{ favorite.apiNum }}</span>
											</div>
										</div>
									</li>
								</ul>
								<!-- </div> -->
							</div>

							<!-- PANEL CUSTOM PINS -->
							<div class="panel my-pins" *ngIf="selectedPanel === 'MY_PINS' && customPins">
								<ul>
									<li
										*ngFor="let pin of filteredCustomPins; index as i"
										class="clearfix list-item"
										(click)="selectCustomMarker(pin)"
									>
										<div class="list-content cursor-pointer clearfix">
											<p class="name">
												<i class="icon-pin-small" [ngClass]="pin.color | lowercase"></i>
												{{ pin.name }}
											</p>
											<div class="info">
												<span>LAT: {{ (pin.lat | number: '1.1-8') + ' ' }}</span>
												<span>LONG: {{ pin.lng | number: '1.1-8' }}</span>
											</div>
										</div>
									</li>
									<li class="add-pin">
										<button class="btn" (click)="createPin()">+</button>
									</li>
								</ul>
							</div>
						</perfect-scrollbar>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
