import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ErrorInterceptor} from './helpers/interceptors/error.interceptor';
import {SentryErrorHandler} from './helpers/interceptors/sentry-error-handler.interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {environment} from 'src/environments/environment';
import {initializeApp} from 'firebase/app';
import {CoreModule} from './core/core.module';
import { VerificationComponent } from './verification/verification.component';
initializeApp(environment.firebaseConfig);
@NgModule({
	declarations: [AppComponent, VerificationComponent],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		HttpClientModule,
		CoreModule,
		AppRoutingModule,
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
		{provide: ErrorHandler, useClass: SentryErrorHandler},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
