<div class="modal show-folder" *ngIf="_folder">
	<div class="modal-content">
		<i class="icon-close" (click)="close.emit()"></i>
		<h1 class="title">'{{ _folder.name }}' Folder</h1>

		<div class="marker-list">
			<perfect-scrollbar>
				<ul>
					<li class="clearfix" *ngFor="let marker of _folder.markers">
						<div
							class="list-content cursor-pointer clearfix"
							(click)="type === CUSTOM_FOLDER ? selectCustomMarker(marker) : selectMarker(marker)"
						>
							<div class="marker-name">
								<figure>
									<img
										[src]="[
											'../../../assets/img/map_assets/markers/ic_marker_pin_' +
												(marker.color === 'BLACK'
													? 'black'
													: marker.color === 'BLUE'
													? 'blue'
													: marker.color === 'DEFAULT'
													? 'default'
													: marker.color === 'GREEN'
													? 'green'
													: marker.color === 'ORANGE'
													? 'orange'
													: marker.color === 'PINK'
													? 'pink'
													: marker.color === 'RED'
													? 'red'
													: marker.color === 'WHITE'
													? 'white'
													: 'yellow') +
												'.svg'
										]"
									/>
								</figure>
								<p class="name">{{ marker.name }}</p>
							</div>
							<div class="marker-coordinates" *ngIf="type === 'custom_folder'">
								<p class="lat">
									<b>LAT:</b>
									{{ marker.lat }}
								</p>
								<p class="lng">
									<b>LONG:</b>
									{{ marker.lng }}
								</p>
							</div>
						</div>
					</li>
				</ul>
			</perfect-scrollbar>
		</div>
		<div class="modal-footer">
			<button
				class="btn"
				(click)="importFolderName = true"
				[disabled]="userIsFolderOwner"
				[title]="userIsFolderOwner ? 'You are the owner of the folder' : 'Import folder'"
			>
				Import this folder to favorites
			</button>
		</div>
	</div>
</div>

<div class="modal show-folder-name" *ngIf="importFolderName">
	<div class="modal-content">
		<i class="icon-close" (click)="importFolderName = false"></i>
		<h1 class="title">Import '{{ _folder.name }}' Folder as:</h1>

		<div class="modal-body">
			<form [formGroup]="form" *ngIf="form">
				<input type="text" class="name" formControlName="name" />
			</form>
		</div>

		<div class="modal-footer">
			<button
				class="btn"
				[disabled]="!form.valid"
				(click)="type === CUSTOM_FOLDER ? importCustomFolder() : importFolder()"
			>
				Save
			</button>
		</div>
	</div>
</div>
