import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FolderDto, CustomMarkerDto} from 'src/app/types/dtos/models';
import {UntypedFormGroup, Validators, UntypedFormControl} from '@angular/forms';
import {Papa} from 'ngx-papaparse';
import {FavoriteService} from 'src/app/services/favorite.service';
import {ToastService} from 'src/app/services/toast.service';
import {LoaderService} from 'src/app/services/loader.service';
import {throwError} from 'rxjs';
import {MarkerService} from 'src/app/services/marker.service';

@Component({
	selector: 'app-add-list',
	templateUrl: './add-list.component.html',
	styleUrls: ['./add-list.component.scss'],
})
export class AddListComponent {
	public form: UntypedFormGroup = undefined;
	public currentFolders: FolderDto[];

	public customMarkers: Partial<CustomMarkerDto>[];

	constructor(
		private papa: Papa,
		private favoriteService: FavoriteService,
		private toastService: ToastService,
		private loaderService: LoaderService,
		private markerService: MarkerService
	) {}

	@Input('folders')
	set folders(value: FolderDto[]) {
		this.currentFolders = value;
		if (value) {
			this.setForm();
		}
	}
	get folders(): FolderDto[] {
		return this.currentFolders;
	}
	@Input() addPins: boolean;
	@Input() addFavourites: boolean;
	@Output() closedModal = new EventEmitter();
	@Output() addedFavorites = new EventEmitter();
	@Output() addedPins = new EventEmitter<CustomMarkerDto[]>();

	get f() {
		return this.form.controls;
	}

	setForm = () => {
		this.form = new UntypedFormGroup({
			folder: new UntypedFormControl(0, Validators.required),
			apiNums: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
		});
	};

	public readCSV = async (csvInput: HTMLInputElement) => {
		try {
			const csvFile: File = csvInput.files[0];
			const parsedRecords = (await new Promise((resolve, reject) =>
				this.papa.parse(csvFile, {
					skipEmptyLines: true,
					complete: (results) => resolve(results),
					error: (err) => {
						reject(err);
					},
				})
			)) as {data: string[][]};
			this.addPins ? this.getPinsFromCSVFile(parsedRecords.data) : this.getApisFromCSVFile(parsedRecords.data);
		} catch (err) {
			throwError(err);
			this.toastService.show({
				severity: 'error',
				summary: 'An error occurred while trying to read the svg',
				detail: 'Try again later',
			});
		}
	};

	getApisFromCSVFile = (csvRecordsArray: string[][]) => {
		csvRecordsArray.shift();
		const apiNums = csvRecordsArray.reduce((prevVal, currVal, idx) => {
			return idx == 0 ? currVal : prevVal + ',' + currVal;
		}, '');
		this.f.apiNums.setValue(apiNums);
	};

	getPinsFromCSVFile = (csvRecordsArray: string[][]) => {
		csvRecordsArray.shift();
		this.customMarkers = csvRecordsArray.reduce((prevVal: Partial<CustomMarkerDto>[], currVal, idx) => {
			const newCustomMarker: Partial<CustomMarkerDto> = {
				name: currVal[0],
				latitude: +currVal[1],
				longitude: +currVal[2],
			};
			prevVal.push(newCustomMarker);
			return prevVal;
		}, []);
	};

	addFavorites = async () => {
		try {
			this.loaderService.show();
			const favorite = {
				folders: this.form.value.folder !== 0 ? [this.form.value.folder] : [],
				rootFolder: this.form.value.folder === 0 ? true : false,
				apiNums: this.form.value.apiNums,
			};

			const result = await this.favoriteService.addFavoritesList(favorite);
			this.toastService.show({severity: 'success', summary: 'Favorites have been created successfully'});
			this.addedFavorites.emit();
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not create favorites'});
			this.closedModal.emit(null);
		} finally {
			this.loaderService.hide();
		}
	};

	addCustomMarkers = async () => {
		try {
			this.loaderService.show();
			const foldersToSave = this.form.value.folder !== 0 ? [this.form.value.folder] : [];
			const isRootFolder = this.form.value.folder === 0 ? true : false;
			const result = await this.markerService.addListCustomMarkers(
				this.customMarkers,
				foldersToSave,
				isRootFolder
			);
			this.addedPins.emit(result.data);
		} catch (err) {
			if (err.error.error === 'INVALID_PAYLOAD')
				this.toastService.show({severity: 'error', summary: err.error.message});
			else this.toastService.show({severity: 'error', summary: 'Something went wrong, please try again later'});
		} finally {
			this.loaderService.hide();
		}
	};
}
