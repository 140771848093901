import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ConfirmationService} from 'src/app/services/confirmation.service';

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: './confirmation-modal.component.html',
	styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
	public showModal: boolean = false;
	public message: string;
	public title: string;
	public confirmationText: string;
	public rejectText: string;
	constructor(public confirmationService: ConfirmationService) {}
	@Output() closeModal = new EventEmitter();

	ngOnInit(): void {
		this.confirmationService.getConfirmationShowValue().subscribe((value) => {
			this.showModal = value.show;
			this.title = value.title;
			this.message = value.message;
			this.confirmationText = value.confirmationText;
			this.rejectText = value.rejectText;
		});
	}

	public confirm(value) {
		this.confirmationService.confirmStatus(value);
	}
}
