import {AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {AudioRecorder} from 'src/app/helpers/recordAudio';
import {LoaderService} from 'src/app/services/loader.service';
import {SpeechRecognitionService} from 'src/app/services/speech-recognition.service';
import {ItemContent} from 'src/app/types/dtos/models';
import {ContentType} from 'src/app/types/enums';

declare var webkitSpeechRecognition: any;
@Component({
	selector: 'app-audio-player',
	templateUrl: './audio-player.component.html',
	styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit, OnDestroy, AfterContentInit {
	public audioRecorder: AudioRecorder = new AudioRecorder();
	public recording: boolean = false;
	public audio: string;
	private textOfAudioSubscription: Subscription;
	public textOfAudio: string = '';
	public value$: ItemContent;

	@Input('value')
	set value(value: ItemContent) {
		if (!value) {
			this.value$ = {
				type: ContentType.AUDIO,
				data: undefined,
				transcription: null,
			};
		} else {
			this.value$ = value;
		}
	}
	get value(): ItemContent {
		return this.value$;
	}
	@Output() valueChange: EventEmitter<ItemContent> = new EventEmitter();
	@Output() delete = new EventEmitter();

	public set textOfAudio$(value: string) {
		this.textOfAudio = value;
		const item = this.value;
		item.transcription = value || '';
		// this.setValue(item);
	}
	public get textOfAudio$(): string {
		return this.textOfAudio;
	}

	public set audio$(value: string) {
		this.audio = value;
		const item = this.value;
		item.data = value;
		this.setValue(item);
	}
	public get audio$(): string {
		return this.audio;
	}

	constructor(public speechRecognitionService: SpeechRecognitionService, private loaderService: LoaderService) {}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.audio$ = this.value$?.data;
			this.textOfAudio$ = this.value$?.transcription || '';
		}, 0);
	}
	ngOnInit(): void {}

	public setValue = (value: ItemContent) => {
		this.value$ = value;
		this.valueChange.emit(this.value);
	};

	ngOnDestroy(): void {
		this.textOfAudioSubscription?.unsubscribe();
	}

	public toggleRecording = () => {
		if (this.recording) {
			this.stopRecording();
		} else {
			this.startRecording();
		}
	};

	private startRecording = () => {
		//start recording using the audio recording API
		this.audioRecorder
			.start()
			.then(() => {
				//on success
				this.recording = true;
				this.speechRecognitionService.recognization.onend = (e) => {
					if (this.recording === true) {
						this.speechRecognitionService.start();
					}
				};
				this.textOfAudioSubscription = this.speechRecognitionService.textOfAudio$.subscribe((text) => {
					if (text) {
						this.textOfAudio$ += `${text}`;
					}
				});
				this.speechRecognitionService.start();
			})
			.catch((error) => {
				//on error
				//No Browser Support Error
				if (
					error.message.includes('mediaDevices API or getUserMedia method is not supported in this browser.')
				) {
					// should display a message to the user
				}
			});
	};

	private stopRecording = () => {
		this.audioRecorder
			.stop()
			.then((audioAsblob: Blob) => {
				this.recording = false;
				this.loaderService.show();
				setTimeout(() => {
					const reader = new FileReader();
					reader.onload = (e) => {
						const srcUrl = e.target.result;
						this.audio$ = srcUrl as string;
						this.value.id = undefined;
						this.value.type = ContentType.AUDIO;
					};
					reader.readAsDataURL(audioAsblob);
					this.speechRecognitionService.stop();
					this.textOfAudioSubscription?.unsubscribe();
					this.loaderService.hide();
				}, 2000);
			})
			.catch((error) => {
				this.loaderService.hide();
			});
	};

	public deleteAudio = () => {
		this.delete.emit();
		this.audio$ = undefined;
		this.textOfAudio$ = '';
		this.value.id = undefined;
		this.value.data = undefined;
		this.value.transcription = '';
		this.setValue(this.value);
	};
}
