import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UserDto} from 'src/app/types/dtos/models';
import {LoaderService} from 'src/app/services/loader.service';
import {UserService} from 'src/app/services/user.service';
import {ToastService} from 'src/app/services/toast.service';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';

@Component({
	selector: 'app-reset-password-modal',
	templateUrl: './reset-password-modal.component.html',
	styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent implements OnInit {
	display: boolean = false;
	password: string;
	confirmPassword: string;
	currentUser: UserDto;

	constructor(
		public router: Router,
		private loaderService: LoaderService,
		private userService: UserService,
		private toastService: ToastService
	) {}

	@Output() closeModal = new EventEmitter();
	@Input('user')
	set user(value: UserDto) {
		if (value) {
			this.currentUser = value;
			this.display = true;
		}
	}
	get user(): UserDto {
		return this.currentUser;
	}

	ngOnInit(): void {}

	closeResetPassword = () => {
		this.display = false;
		this.closeModal.emit();
	};

	resetPassword = async () => {
		try {
			this.loaderService.show();

			const response = await this.userService.resetPassword(
				this.currentUser.id,
				this.currentUser.email,
				this.password
			);
			this.toastService.show({severity: 'success', summary: 'Updated credentials'});
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not Update credentials'});
		}
		finally{
			this.loaderService.hide();
		}
		this.closeResetPassword();
	};
}
