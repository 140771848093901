import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {UserLogged, UserDto, Token, RegularLoginResponse} from '../types/dtos/models';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {Router} from '@angular/router';
import 'firebase/auth';
import {ChatUser} from '../types/dtos/chat';
import {FEATURES} from '../types/enums';
import {UtilitiesService} from './utilities.service';
import {ToastService} from '../services/toast.service';
import {getAuth, signInWithCustomToken} from 'firebase/auth';
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private apiHost = `${environment.apiHost}/auth`;
	private apiChatHost = `${environment.apiHost}/chat`;
	private ssoHost = `${environment.apiHostv3}/v3/sso`;
	private cognitoHost = `${environment.cognitoConfig.baseUrl}/oauth2`;
	private currentUserSubject: BehaviorSubject<UserLogged>;
	public currentUser: Observable<UserLogged>;
	private currentUserChatSubject: BehaviorSubject<ChatUser> = new BehaviorSubject<ChatUser>(null);
	public currentUserChat: Observable<ChatUser>;

	constructor(private http: HttpClient, private router: Router, private utilitiesService: UtilitiesService) {
		const currentUserStorageInfo = localStorage.getItem('currentUser');
		if (currentUserStorageInfo && currentUserStorageInfo !== 'undefined' && currentUserStorageInfo !== '{}') {
			this.currentUserSubject = new BehaviorSubject<UserLogged>(JSON.parse(currentUserStorageInfo));
		} else {
			this.currentUserSubject = new BehaviorSubject<UserLogged>(undefined);
		}
		const currentChatUserStorageInfo = localStorage.getItem('currentChatUser');
		if (
			currentChatUserStorageInfo &&
			currentChatUserStorageInfo !== 'undefined' &&
			currentChatUserStorageInfo !== '{}'
		) {
			this.currentUserChatSubject = new BehaviorSubject<ChatUser>(JSON.parse(currentChatUserStorageInfo));
		} else {
			this.currentUserChatSubject = new BehaviorSubject<ChatUser>(undefined);
		}
		this.currentUser = this.currentUserSubject.asObservable();
		this.currentUserChat = this.currentUserChatSubject.asObservable();
	}

	public get currentUserValue(): UserLogged {
		return this.currentUserSubject.value;
	}

	public get currentUserChatValue(): ChatUser {
		return this.currentUserChatSubject.value;
	}

	private getUser = async () => {
		try {
			const result = await this.getUserData();
			this.currentUserSubject.next(result.data);
			const chatUser = this.currentUserChatSubject.value;
			if (
				result.data.staff &&
				!chatUser &&
				result.data.features.some(
					(f) =>
						f.id === FEATURES.CHAT ||
						f.id === FEATURES.ENTERPRISE_DISPATCH ||
						f.id === FEATURES.ENTERPRISE_PLUS
				)
			) {
				await this.authFirebase();
			}
		} catch (error) {
			throwError(error);
		}
	};

	public userHasFeature = (feature: FEATURES): boolean => {
		const user = this.currentUserSubject.value;
		if (user && user.features) {
			return user.features.some((f) => f.id === feature);
		}
		return false;
	};

	public getUserData = async (): Promise<ApiResponse<UserLogged>> =>
		this.http
			.get<ApiResponse<UserLogged>>(`${this.apiHost}/data`, {withCredentials: true})
			.toPromise()
			.then((res) => {
				this.setSession(res.data);

				return res;
			});

	public login = async (email: string, password: string): Promise<RegularLoginResponse> => {
		const response = await this.http
			.post<ApiResponse<RegularLoginResponse>>(
				`${this.apiHost}/login`,
				{email, password},
				{withCredentials: true, observe: 'response'}
			)
			.toPromise();

		if (response.status === 200) {
			const userData = response.body.data as UserLogged;
			this.setSession(userData);
			if (
				userData.staff &&
				userData.features.some(
					(f) =>
						f.id === FEATURES.CHAT ||
						f.id === FEATURES.ENTERPRISE_DISPATCH ||
						f.id === FEATURES.ENTERPRISE_PLUS
				)
			) {
				this.authFirebase();
			}

			return userData;
		} else if (response.status === 201) {
			const userData = response.body.data;
			return userData;
		}
	};

	public logout = async () => {
		// remove user from local storage and set current user to null
		const result = await this.http
			.post<ApiResponse<null>>(`${this.apiHost}/logout`, {}, {withCredentials: true})
			.toPromise();
		if (result.status === 200) {
			this.clearStorage();
		}
		return result;
	};

	public clearStorage = () => {
		localStorage.removeItem('lockZoomLevel');
		localStorage.removeItem('currentUser');
		localStorage.removeItem('currentChatUser');
		localStorage.removeItem('popups');
		localStorage.removeItem('mapStatus');
		localStorage.removeItem('lockZoomLevel');
		this.currentUserSubject.next(undefined);
		this.currentUserChatSubject.next(undefined);
		this.router.navigate(['/login']);
	};

	public requestResetPassword = async (email: string): Promise<ApiResponse<undefined>> =>
		this.http
			.post<ApiResponse<undefined>>(`${this.apiHost}/reset_password`, {email}, {withCredentials: true})
			.toPromise();

	public validateToken = async (token: string): Promise<ApiResponse<UserDto>> =>
		this.http
			.post<ApiResponse<UserDto>>(`${this.apiHost}/validate_reset_token`, {token}, {withCredentials: true})
			.toPromise();

	public resetNewPassword = async (token: string, password: string): Promise<ApiResponse<UserLogged>> =>
		this.http
			.post<ApiResponse<UserLogged>>(`${this.apiHost}/new_password`, {token, password}, {withCredentials: true})
			.toPromise();

	public changePassword = async (
		actualPassword: string,
		password: string,
		passwordConfirmation: string
	): Promise<ApiResponse<UserLogged>> =>
		this.http
			.post<ApiResponse<UserLogged>>(
				`${this.apiHost}/change_password`,
				{actualPassword, password, passwordConfirmation},
				{withCredentials: true}
			)
			.toPromise();

	private setSession = (authResult: UserLogged) => {
		const authResultTest: UserLogged = authResult;
		localStorage.setItem('currentUser', JSON.stringify(authResultTest));
		this.currentUserSubject.next(authResultTest);
		if (
			authResultTest.features.some(
				(f) =>
					f.id === FEATURES.CHAT || f.id === FEATURES.ENTERPRISE_DISPATCH || f.id === FEATURES.ENTERPRISE_PLUS
			) &&
			!this.currentUserChatSubject.value
		) {
			this.authFirebase();
		}
	};

	public authFirebase = async () => {
		try {
			const result = await this.http
				.get<ApiResponse<Token>>(`${this.apiChatHost}/get-token-portal`, {withCredentials: true})
				.toPromise();
			const token = result.data.token;
			const auth = getAuth();
			const userLogged = await signInWithCustomToken(auth, token);
			// await this.firebaseService.firebaseApp.auth().signInWithCustomToken(token);
			const currentUser = userLogged.user;
			const currentChatUser: ChatUser = {
				id: currentUser.uid,
				name: currentUser.displayName,
			};

			// this.listen();
			localStorage.setItem('currentChatUser', JSON.stringify(currentChatUser));
			this.currentUserChatSubject.next(currentChatUser);
		} catch (err) {
			console.error(err);
		}
	};

	public verifyMFA = async (session: string, mfa: string): Promise<UserLogged> => {
		const response = await this.http
			.post<ApiResponse<UserLogged>>(`${this.apiHost}/verify_mfa_code/${session}`, {mfa}, {withCredentials: true})
			.toPromise();
		if (response.data) {
			const userData = response.data;
			this.setSession(userData);
			if (
				userData.staff &&
				userData.features.some(
					(f) =>
						f.id === FEATURES.CHAT ||
						f.id === FEATURES.ENTERPRISE_DISPATCH ||
						f.id === FEATURES.ENTERPRISE_PLUS
				)
			) {
				this.authFirebase();
			}
			return userData;
		}
	};

	public resetMFA = async (session: string): Promise<ApiResponse<undefined>> => {
		return await this.http
			.post<ApiResponse<undefined>>(`${this.apiHost}/reset_mfa_code/${session}`, {withCredentials: true})
			.toPromise();
	};

	public getExternalIdP = async (email: string) => {
		const result = await this.http.get<ApiResponse<{idp: string}>>(`${this.ssoHost}/idp/${email}`).toPromise();
		return result.data.idp;
	};

	public cognitoRedirect = async (idpId: string) => {
		window.location.href = `${this.cognitoHost}/authorize?
				response_type=code&
				idp_identifier=${idpId}&
				client_id=${environment.cognitoConfig.clientId}&
				redirect_uri=${environment.cognitoConfig.redirectUri}
			`;
	};

	public completeSSOLogin = async (code: string) =>
		this.http
			.post<ApiResponse<UserLogged>>(`${this.ssoHost}/web/login`, {code}, {withCredentials: true})
			.toPromise();
}
