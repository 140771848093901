import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy} from '@angular/core';
import {CustomMarkerDto, FavoriteDto, MapMarker, MarkerDto, UserDto, UserLogged} from 'src/app/types/dtos/models';
import {FavoriteService} from 'src/app/services/favorite.service';
import {SelectItem} from 'primeng/api/selectitem';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {COLORS, ColorList, MarkerType} from 'src/app/types/enums';
import {ToastService} from 'src/app/services/toast.service';
import {LoaderService} from 'src/app/services/loader.service';
import {throwError} from 'rxjs';
import {NoWhitespaceValidator} from 'src/app/helpers/validator';
import {MarkerService} from 'src/app/services/marker.service';
import {LatLngLiteral} from '@agm/core';
import * as _ from 'lodash';
import {AuthService} from 'src/app/services/auth.service';
import {take, takeUntil} from 'rxjs/operators';
import {MapService} from 'src/app/services/map.service';

@Component({
	selector: 'app-add-edit-favorite',
	templateUrl: './add-edit-favorite.component.html',
	styleUrls: ['./add-edit-favorite.component.scss'],
})
export class AddEditFavoriteComponent implements OnInit, AfterViewInit, OnDestroy {
	public folders: SelectItem[];
	public name: string;
	public form: UntypedFormGroup = undefined;
	public currentMarker: MarkerDto;
	public currentFavorite: FavoriteDto;
	public currentCustomMarker: CustomMarkerDto;
	public CUSTOM_MARKER = MarkerType.CUSTOM_MARKER;
	public MARKER = MarkerType.MARKER;
	private currentUser: UserLogged;
	colors = COLORS;
	colorList = ColorList;

	constructor(
		private favoriteService: FavoriteService,
		private toastService: ToastService,
		private loaderService: LoaderService,
		private mapService: MapService,
		private markerService: MarkerService,
		private authService: AuthService
	) {}
	@Input() type: MarkerType;

	@Input('mapCenter')
	set mapCenter(value: LatLngLiteral) {
		if (value) {
			this.form.patchValue({
				lat: value.lat,
				lng: value.lng,
			});
		}
	}

	get mapCenter(): LatLngLiteral {
		return this.form.value.map((form) => ({lat: form.lat, lng: form.lng}));
	}

	@Input('marker')
	set marker(value: MarkerDto) {
		this.currentMarker = value;
		if (value) {
			this.setForm(undefined, value);
		}
	}
	get marker(): MarkerDto {
		return this.currentMarker;
	}

	@Input('customMarker')
	set customMarker(value: CustomMarkerDto) {
		if (value) {
			this.currentCustomMarker = value;
		}
		this.setForm(undefined, undefined, value);
	}
	get customMarker(): CustomMarkerDto {
		return this.currentCustomMarker;
	}

	@Input('favorite')
	set favorite(value: FavoriteDto) {
		this.currentFavorite = value;
		if (value) {
			this.setForm(value);
		}
	}
	get favorite(): FavoriteDto {
		return this.currentFavorite;
	}

	@Output() addedFavorite = new EventEmitter<FavoriteDto>();
	@Output() addedCustomMarker = new EventEmitter<{data: CustomMarkerDto; action: 'CREATE' | 'EDIT'}>();
	@Output() removeFavorite = new EventEmitter();
	@Output() close = new EventEmitter();

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.authService.currentUser.pipe(take(1)).subscribe((user) => {
			this.currentUser = user;
		});
		if (this.type === MarkerType.CUSTOM_MARKER) {
			this.getCustomMarkerFolders();
			// This is due to the error: Expression has changed after it was checked.
			setTimeout(() => {
				if (this.currentCustomMarker) {
					this.mapService.centerMap({
						lat: this.currentCustomMarker.lat,
						lng: this.currentCustomMarker.lng,
					});
				}
				this.mapService.showCenterPinOnMap(true, this.form?.value.color || COLORS.DEFAULT);
			}, 0);
			this.mapService.removePin(this.currentCustomMarker);
		} else if (this.type === MarkerType.MARKER) {
			this.getFolders();
		}
	}

	ngOnDestroy(): void {
		// This is due to the error: Expression has changed after it was checked.
		setTimeout(() => {
			this.mapService.showCenterPinOnMap(false);
		}, 0);
	}

	getCustomMarkerFolders = async () => {
		try {
			const result = await this.markerService.getFolders();
			this.folders = result.data.map((folder) => {
				return {label: folder.name, value: folder.id};
			});
			this.folders.unshift({label: 'Main Folder', value: 0});
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not get folders'});
		}
	};

	getFolders = async () => {
		try {
			this.loaderService.show();
			const result = await this.favoriteService.getFolders();
			this.folders = result.data
				.filter((folder) => folder.layerId === this.mapService.currentStatus.layer.id)
				.map((folder) => {
					return {label: folder.name, value: folder.id};
				});
			this.folders.unshift({label: 'Main Folder', value: 0});
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not get folders'});
		} finally {
			if (!this.form) {
				this.setForm();
			}
			this.loaderService.hide();
		}
	};

	private setForm = (favorite?: FavoriteDto, marker?: MarkerDto, customMarker?: CustomMarkerDto) => {
		const folders = favorite
			? favorite.rootFolder
				? [0].concat(favorite.folders)
				: favorite.folders
			: customMarker
			? customMarker.rootFolder
				? [...new Set([0].concat(customMarker.folders.map((folder) => folder.folderId)))]
				: customMarker.folders?.map((folder) => folder.folderId) || []
			: [0];
		this.form = new UntypedFormGroup({
			nickname: new UntypedFormControl(
				favorite ? favorite.nickname : marker ? marker.name : customMarker ? customMarker.name : 'New Pin',
				[Validators.required, Validators.minLength(2), NoWhitespaceValidator]
			),
			folders: new UntypedFormControl(folders),
			color: new UntypedFormControl(
				favorite
					? favorite.color
					: marker?.color
					? marker.color
					: customMarker
					? customMarker.color
					: COLORS.BLUE
			),
			lat: new UntypedFormControl(customMarker?.lat || this.mapService.mapCenterActual?.lat || undefined),
			lng: new UntypedFormControl(customMarker?.lng || this.mapService.mapCenterActual?.lng || undefined),
		});
	};

	get f() {
		return this.form.controls;
	}

	addFavorite = async () => {
		try {
			this.loaderService.show();
			let favorite = {
				color: this.form.value.color,
				folders: this.form.value.folders.filter((folder) => folder !== 0),
				markerId: this.favorite ? this.favorite.id : this.currentMarker.id,
				nickname: this.form.value.nickname,
				rootFolder: (this.form.value.folders as number[]).includes(0),
			};
			let result;
			if (!this.favorite) {
				result = await this.favoriteService.addFavorite(favorite);
			} else {
				favorite['userId'] = this.favorite.createdByUser.id || this.currentUser.id;
				result = await this.favoriteService.editFavorite(favorite);
				this.mapService.addPin(result);
			}
			this.addedFavorite.emit(result.data);
		} catch (err) {
			throwError(err);
			this.addedFavorite.emit(null);
		} finally {
			this.loaderService.hide();
		}
	};

	addCustomMarker = async () => {
		try {
			this.loaderService.show();
			const folders =
				this.form.value.folders
					.filter((folder) => folder !== 0)
					.map((folder) => ({folderId: folder, order: 0})) || [];
			if ((this.form.value.folders as number[]).includes(0)) {
				folders.unshift({folderId: 0, order: 0});
			}
			const customMarker = {
				color: this.form.value.color,
				folders,
				name: this.form.value.nickname,
				rootFolder: ((this.form.value.folders || []) as number[]).includes(0),
				// Change this after the folders on custom markers are released
				lat: this.form.value.lat,
				lng: this.form.value.lng,
				latitude: this.form.value.lat,
				longitude: this.form.value.lng,
			};
			let result;
			if (!this.currentCustomMarker) {
				result = await this.markerService.addCustomMarkers(customMarker);
				this.mapService.addCustomPin();
			} else {
				customMarker['userId'] = this.currentCustomMarker.createdByUser?.id || this.currentUser.id;
				this.mapService.removePin(customMarker);
				result = await this.markerService.editCustomMarkers(customMarker, this.currentCustomMarker.id);
				this.mapService.editCustomPin(result.data);
			}
			this.addedCustomMarker.emit({data: result.data, action: !this.currentCustomMarker ? 'CREATE' : 'EDIT'});
		} catch (err) {
			throwError(err);
			this.close.emit();
			this.toastService.show({severity: 'error', summary: err.error.message});
		} finally {
			this.loaderService.hide();
		}
	};

	selectColor = (color: string) => {
		this.f.color.setValue(color);
		if (this.type === this.CUSTOM_MARKER) {
			this.mapService.showCenterPinOnMap(true, color as COLORS);
		}
	};

	handleLatChange = (event) => {
		const newLat = +event.target.value;
		this.form.value.lat = newLat;
		this.mapService.mapCenterActual.lat = newLat;
		this.mapService.setAddressWithoutMarker({lat: newLat, lng: this.form.value.lng});
	};

	handleLngChange = (event) => {
		const newLng = +event.target.value;
		this.form.value.lng = newLng;
		this.mapService.mapCenterActual.lng = newLng;
		this.mapService.setAddressWithoutMarker({lng: newLng, lat: this.form.value.lat});
	};
}
