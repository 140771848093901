import {Injectable} from '@angular/core';
import {ApiResponse, PaginationResponse} from '../types/dtos/service';
import {ItemData, MapDrawCustomRouteData, MapRecordedRouteDto, MapRecordedRouteOutputDto} from '../types/dtos/models';
import {ContentType, FEATURES, ItemType, MarkerIconFile} from '../types/enums';
import {BehaviorSubject, Subject} from 'rxjs';
import {DrawService} from './draw.service';
import {LatLng, LatLngLiteral} from '@agm/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {DEFAULT_RADIUS} from '../types/constants';
import {FeatureCollectionGeoJson} from '../helpers/shareHelper';
import {ChatRecordedRoute} from '../types/dtos/chat';
import {PaginationRequest, castPaginationToRequestParams, setDataAndPagination} from '../helpers/requestHelper';
import * as _ from 'lodash';
import {AuthService} from './auth.service';

@Injectable({
	providedIn: 'root',
})
export class RecordedRouteService {
	private url = `${environment.apiHost}/recordRoute`;
	private recordedRoutesSubject = new BehaviorSubject<MapRecordedRouteDto[]>(undefined);
	public recordedRoutes$ = this.recordedRoutesSubject.asObservable();
	private amountNotReviewedRecordedRoutesSubject = new BehaviorSubject<number>(undefined);
	public amountNotReviewedRecordedRoutes$ = this.amountNotReviewedRecordedRoutesSubject.asObservable();

	// Info for the draw service
	public recordedRoutesDraws: any[] = [];

	constructor(private drawService: DrawService, private http: HttpClient, private authService: AuthService) {
		if (authService.userHasFeature(FEATURES.RECORD_ROUTES)) {
			this.getRecordedRoutes();
			this.getNotReviewedRecordRoutes();
		}
		// this.syncRecordedRoutes();
	}

	public syncRecordedRoutes = (): void => {
		setTimeout(() => {
			this.getRecordedRoutes();
			this.syncRecordedRoutes();
		}, 30000);
	};

	public getRecordedRoutes = (
		pagination?: PaginationRequest,
		filters?: {param?: string; timestampBefore?: string; timestampAfter?: string}
	): Promise<ApiResponse<MapRecordedRouteDto[]>> =>
		this.http
			.get<ApiResponse<PaginationResponse<MapRecordedRouteDto[]> | MapRecordedRouteDto[]>>(this.url, {
				params: {
					...(pagination && castPaginationToRequestParams(pagination)),
					...(filters && {..._.omitBy(filters, _.isUndefined)}),
				},
				withCredentials: true,
			})
			.toPromise()
			.then((result) => {
				if (pagination)
					return setDataAndPagination(result as ApiResponse<PaginationResponse<MapRecordedRouteDto[]>>);
				else {
					this.recordedRoutesSubject.next(result.data as MapRecordedRouteDto[]);
					return result;
				}
			});

	public getRecordedRouteById = (id: number): Promise<ApiResponse<MapRecordedRouteDto>> =>
		this.http
			.get<ApiResponse<MapRecordedRouteDto>>(`${this.url}/${id}`, {withCredentials: true})
			.toPromise()
			.then(async (response) => {
				await this.setRecordRouteReviewed(id);
				return response;
			});

	public drawRecordedRoute = (
		route: MapRecordedRouteDto,
		map: google.maps.Map,
		draggable: boolean = false,
		showInfoWindow: boolean = false
	): Partial<MapDrawCustomRouteData> => {
		const polylines = route.polylines.map((polyline) =>
			this.drawService.createPolylineFromLatLng(polyline.coordinates)
		);

		polylines.forEach((polyline) => {
			polyline.polyline.setMap(map);
		});
		let stops = [];
		let notes = [];
		route.items.forEach((item: ItemData) => {
			let markerIcon: MarkerIconFile;
			let marker;
			switch (item.type) {
				case ItemType.STOP:
					markerIcon = MarkerIconFile.PIN_DEFAULT;
					break;
				case ItemType.TURN_LEFT:
					markerIcon = MarkerIconFile.TURN_LEFT;
					break;
				case ItemType.TURN_RIGHT:
					markerIcon = MarkerIconFile.TURN_RIGHT;
					break;
				default:
					markerIcon = MarkerIconFile.NOTE;
					break;
			}
			if ([ItemType.TURN_LEFT, ItemType.TURN_RIGHT].includes(item.type)) {
				marker = this.drawService.createGeoTurn(item, false, markerIcon, map);
			} else {
				marker = this.drawService.createGeoNote(
					item,
					item.type === ItemType.NOTE ? draggable : false,
					markerIcon,
					map,
					item.type === ItemType.NOTE ? showInfoWindow : true
				);
			}
			if (item.type === ItemType.NOTE) {
				notes.push(marker);
			} else if (item.type === ItemType.STOP) {
				stops.push(marker);
			} else if (item.type === ItemType.TURN_LEFT || item.type === ItemType.TURN_RIGHT) {
				stops.push(marker);
			}
		});

		const ret: Partial<MapDrawCustomRouteData> = {
			name: route.name || '',
			description: route.description || '',
			id: route.id || undefined,
			notes: notes || [],
			markers: [],
			stops: stops || [],
			paths: polylines,
		};

		return ret;
	};

	public transformDrawToRecordRouteOutput = (draw: MapDrawCustomRouteData): MapRecordedRouteOutputDto => {
		const items: ItemData[] = draw.stops.concat(draw.notes).map((marker) => ({
			id: marker.id,
			title: marker.title,
			description: marker.description,
			radius: DEFAULT_RADIUS,
			lat: marker.lat,
			lng: marker.lng,
			content: marker.content,
			type: marker.type,
		}));
		const geoJson: FeatureCollectionGeoJson = {
			type: 'FeatureCollection',
			features: draw.paths.map((path) => {
				const geometry = path.polyline
					.getPath()
					.getArray()
					.map((latLng: LatLng) => [latLng.lng(), latLng.lat()]);
				return {
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'LineString',
						coordinates: geometry,
					},
				};
			}),
		};
		return {
			id: draw.id.toString(),
			name: draw.name,
			description: draw.description,
			items,
			geoJson,
		};
	};

	public updateRecordedRoute = (
		route: MapRecordedRouteOutputDto,
		ownerUserId: number
	): Promise<ApiResponse<MapRecordedRouteDto>> =>
		this.http
			.put<ApiResponse<MapRecordedRouteDto>>(`${this.url}/${route.id}/${ownerUserId}`, route, {
				withCredentials: true,
			})
			.toPromise();

	public deleteRecordedRoute = (routeId: number, ownerUserId: number): Promise<ApiResponse<any>> =>
		this.http
			.delete<ApiResponse<MapRecordedRouteDto>>(`${this.url}/${routeId}/${ownerUserId}`, {
				withCredentials: true,
			})
			.toPromise();

	public saveRecordedRoute = (route: ChatRecordedRoute): Promise<ApiResponse<MapRecordedRouteDto>> =>
		this.http
			.post<ApiResponse<MapRecordedRouteDto>>(`${this.url}`, route, {
				withCredentials: true,
			})
			.toPromise()
			.then((response) => {
				return this.getRecordedRoutes().then(() => {
					return response;
				});
			});

	public getNotReviewedRecordRoutes = (): Promise<ApiResponse<{amount: number}>> =>
		this.http
			.get<ApiResponse<{amount: number}>>(`${this.url}/review/amount`, {withCredentials: true})
			.toPromise()
			.then((response) => {
				this.amountNotReviewedRecordedRoutesSubject.next(response.data.amount);
				return response;
			});

	public setRecordRouteReviewed = (routeId: number): Promise<ApiResponse<any>> =>
		this.http.post<ApiResponse<any>>(`${this.url}/review/${routeId}`, {}, {withCredentials: true}).toPromise();

	public get recordRoutesNotReviewed(): number {
		return this.amountNotReviewedRecordedRoutesSubject.value;
	}
	public set recordRoutesNotReviewed(value: number) {
		this.amountNotReviewedRecordedRoutesSubject.next(value);
	}
}
