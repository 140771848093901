import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FolderDto} from 'src/app/types/dtos/models';
import {FavoriteService} from 'src/app/services/favorite.service';
import {ToastService} from 'src/app/services/toast.service';
import {throwError} from 'rxjs';
import {MapService} from 'src/app/services/map.service';

@Component({
	selector: 'app-filter-favorites',
	templateUrl: './filter-favorites.component.html',
	styleUrls: ['./filter-favorites.component.scss'],
})
export class FilterFavoritesComponent implements OnInit {
	searchValue = '';
	filteredFolders: FolderDto[];
	folders: FolderDto[] = [];

	constructor(
		private mapService: MapService,
		private favoriteService: FavoriteService,
		private toastService: ToastService
	) {}

	@Output() selectedFolder = new EventEmitter<number>();

	selected: FolderDto;

	ngOnInit(): void {
		this.getFolders();
	}

	public getFolders = async () => {
		try {
			const layer = this.mapService.currentStatus.layer;
			this.folders = (await this.favoriteService.getFolders()).data.filter(
				(folder) => folder.layerId === layer.id && folder.numberOfMarkers > 0
			);

			if (!this.folders.length) this.selectedFolder.emit(0);
			else {
				const favorites = (await this.favoriteService.getFavorites()).data;
				this.folders.unshift({
					name: 'Main Folder',
					id: 0,
					numberOfMarkers: favorites.reduce(
						(total, x) => (x.rootFolder && x.layerId === layer.id ? total + 1 : total),
						0
					),
				});
				this.filteredFolders = this.filterFolders('');
			}
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not get folders'});
		}
	};

	get valueFilter(): string {
		return this.searchValue;
	}

	set valueFilter(value: string) {
		this.searchValue = value;
		this.filteredFolders = this.filterFolders(this.searchValue);
	}

	filterFolders(filterBy: string): FolderDto[] {
		filterBy = filterBy.toLocaleLowerCase();
		return this.folders.filter((folder: FolderDto) => folder.name.toLocaleLowerCase().indexOf(filterBy) !== -1);
	}
}
