import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse, PaginationResponse} from '../types/dtos/service';

import {PermissionDto, UserDto, UserTracking} from '../types/dtos/models';
import {
	PaginationRequest,
	castPaginationToRequestParams,
	setDataAndPagination,
	QueryParams,
} from '../helpers/requestHelper';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private apiHost = `${environment.apiHost}/user`;

	constructor(private http: HttpClient) {}

	public getUserTracking = async (userIds: string, timezone: string): Promise<ApiResponse<UserTracking[]>> =>
		this.http
			.get<ApiResponse<UserTracking[]>>(`${environment.apiHost}/live_tracking`, {
				params: {userIds, timezone},
				withCredentials: true,
			})
			.toPromise();

	public getAllUsers = async (pagination?: PaginationRequest): Promise<ApiResponse<UserDto[]>> =>
		this.http
			.get<ApiResponse<PaginationResponse<UserDto[]>>>(`${this.apiHost}/all`, {
				params: pagination ? {...castPaginationToRequestParams(pagination)} : {},
				withCredentials: true,
			})
			.toPromise()
			.then((result) => {
				if (pagination) return setDataAndPagination(result);
				else return result;
			});

	public getProfileSettings = async (): Promise<ApiResponse<UserDto>> =>
		this.http
			.get<ApiResponse<UserDto>>(`${this.apiHost}/settings`, {withCredentials: true})
			.toPromise();

	public resetPassword = async (userId: number, email: string, password: string): Promise<ApiResponse<UserDto>> =>
		this.http
			.put<ApiResponse<UserDto>>(
				`${this.apiHost}/credentials`,
				{userId, email, password},
				{withCredentials: true}
			)
			.toPromise();

	public createUser = async (newUser: Partial<UserDto>): Promise<ApiResponse<UserDto>> =>
		this.http
			.post<ApiResponse<UserDto>>(`${this.apiHost}`, newUser, {withCredentials: true})
			.toPromise();

	public updateUser = async (userId: number, newUser: Partial<UserDto>): Promise<ApiResponse<UserDto>> =>
		this.http
			.put<ApiResponse<UserDto>>(`${this.apiHost}`, {userId, ...newUser}, {withCredentials: true})
			.toPromise();

	public updateUserSettings = async (newSettings: Partial<UserDto>): Promise<ApiResponse<UserDto>> =>
		this.http
			.put<ApiResponse<UserDto>>(`${this.apiHost}/settings`, newSettings, {withCredentials: true})
			.toPromise();

	public deleteUser = async (userId: string, businessSubscriptionId: string): Promise<ApiResponse<UserDto>> =>
		this.http
			.delete<ApiResponse<UserDto>>(`${this.apiHost}`, {
				params: {userId, businessSubscriptionId},
				withCredentials: true,
			})
			.toPromise();

	public getUser = async (userId: string): Promise<ApiResponse<UserDto>> =>
		this.http
			.get<ApiResponse<UserDto>>(`${this.apiHost}`, {params: {userId}, withCredentials: true})
			.toPromise();

	public searchUser = async (params: QueryParams): Promise<ApiResponse<UserDto[]>> =>
		this.http
			.get<ApiResponse<UserDto[]>>(`${this.apiHost}/search`, {params, withCredentials: true})
			.toPromise();

	public updateUserPermissions = async (userId: number, permissions: PermissionDto[]): Promise<ApiResponse<any>> =>
		this.http
			.put<ApiResponse<any>>(
				`${this.apiHost}/permissions`,
				{permissions},
				{withCredentials: true, params: {userId}}
			)
			.toPromise();
}
