import {Injectable, OnDestroy} from '@angular/core';
// import { initializeApp } from 'firebase/app';
import {FirebaseApp, deleteApp, getApp, getApps, initializeApp, onLog} from 'firebase/app';
import {getAuth} from 'firebase/auth';
// import {firestore} from 'firebase/firestore';
// import {getToken, getMessaging, onMessage} from 'firebase/messaging';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
@Injectable({
	providedIn: 'root',
})
export class FirebaseService implements OnDestroy {
	public firebaseApp: FirebaseApp;
	public messaging; //firebase.default.messaging(this.firebaseApp);
	constructor(private authService: AuthService) {
		const apps = getApps();
		if (!apps.length) {
			this.firebaseApp = initializeApp(environment.firebaseConfig);
		} else {
			this.firebaseApp = getApp();
		}
		onLog((log) => {
			if (log.level === 'error') {
				this.authService.authFirebase();
			}
		});
	}

	ngOnDestroy(): void {}
}
