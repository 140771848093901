import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MarkerDto, UserDto} from 'src/app/types/dtos/models';
import {MarkerService} from 'src/app/services/marker.service';
import {AuthService} from 'src/app/services/auth.service';
import {SearchService} from 'src/app/services/search.service';
import {UserService} from 'src/app/services/user.service';
import {throwError} from 'rxjs';
import {MapService} from 'src/app/services/map.service';

@Component({
	selector: 'app-search-popover',
	templateUrl: './search-popover.component.html',
	styleUrls: ['./search-popover.component.scss'],
})
export class SearchPopoverComponent implements OnInit {
	public value;
	public markers: MarkerDto[];
	public users: UserDto[];

	constructor(
		private markerService: MarkerService,
		private authService: AuthService,
		private mapService: MapService,
		private searchService: SearchService,
		private userService: UserService
	) {}

	@Output() chosenPlace = new EventEmitter();
	@Output() chosenUser = new EventEmitter();
	@Input('searchValue')
	set searchValue(value: string | number) {
		this.value = +value ? +value : value;
		if (value && value.toString().length > 2) {
			this.searchAddress(this.value);
		}
	}
	get searchValue(): string | number {
		return this.value;
	}

	@Input('searchUserValue')
	set searchUserValue(value: string | number) {
		this.value = +value ? +value : value;
		if (value && value.toString().length > 2) {
			this.searchUser();
		}
	}
	get searchUserValue(): string | number {
		return this.value;
	}

	ngOnInit(): void {}

	searchAddress = async (value) => {
		try {
			const result = await this.markerService.getMarkers({
				params: btoa(value.toString()),
				states: this.authService.currentUserValue.states.map((s) => s.id).join('-'),
				long: this.mapService.mapParamas.lng,
				lat: this.mapService.mapParamas.lat,
				layerId: this.mapService.currentStatus.layer.id,
				byApi: typeof value === 'string' || value instanceof String ? 'false' : 'true',
			});
			this.markers = result.data.markers;
		} catch (err) {
			throwError(err);
		}
	};

	returnPlace = async (marker: MarkerDto) => {
		if (marker) {
			this.value = '';
			this.chosenPlace.emit(marker);
		}
	};

	searchUser = async () => {
		try {
			const result = await this.userService.searchUser({param: btoa(this.value), plans: 'true'});
			this.users = result.data;
		} catch (err) {
			throwError(err);
		}
	};
}
