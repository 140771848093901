<div class="modal acknowledge">
	<div class="modal-content">
		<i class="icon-close" (click)="closePopup()"></i>
		<div class="modal-header">
			<h1>{{ title }}</h1>
		</div>
		<div class="modal-body">
			<div class="form">
				<p class="text">
					{{ message }}
				</p>
			</div>
			<div class="footer">
				<button class="btn" (click)="acknowledge()">{{ buttonText }}</button>
			</div>
		</div>
	</div>
</div>
