import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'splitTextByCapitalLetter',
})
export class SplitTextByCapitalLetterPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): string {
		return value.split(/(?=[A-Z])/).join(' ');
	}
}
