import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {severityType} from '../types/enums';

export interface MessageType {
	severity: severityType;
	summary: string;
	detail?: string;
	life?: number;
	sticky?: boolean;
	closable?: boolean;
	key?: string;
}

@Injectable({
	providedIn: 'root',
})
export class ToastService {
	constructor() {}
	private toast = new Subject<MessageType>();
	private clear = new Subject();

	public show(message: MessageType) {
		this.toast.next(message);
	}

	public getToastValue() {
		return this.toast.asObservable();
	}
	public getClearValue() {
		return this.clear.asObservable();
	}

	public clearToast() {
		this.clear.next();
	}
}
