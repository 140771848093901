import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {FavoriteDto, FolderDto, CustomMarkerDto} from 'src/app/types/dtos/models';

@Component({
	selector: 'app-share-list',
	templateUrl: './share-list.component.html',
	styleUrls: ['./share-list.component.scss'],
})
export class ShareListComponent implements OnInit {
	selectedFolders: FolderDto[] = [];
	selectedFavorites: FavoriteDto[] = [];
	selectedPins: CustomMarkerDto[] = [];

	constructor() {}

	@Input() favorites: FavoriteDto[];
	@Input() folders: FolderDto[];
	@Input() pins: CustomMarkerDto[];
	@Input() filteredFavorites: FavoriteDto[];
	@Input() filteredFolders: FolderDto[];
	@Input() filteredPins: CustomMarkerDto[];
	@Input() valueFilter: string;
	@Output() closedModal = new EventEmitter<{
		selectedFolders?: FolderDto[];
		selectedFavorites?: FavoriteDto[];
		selectedPins?: CustomMarkerDto[];
	}>();
	@Output() filterValue = new EventEmitter();

	ngOnInit(): void {}

	selectAllFolders = () => {
		// this.selectedFolders = this.folders.map((folder) => folder.id.toString())
		this.selectedFolders = this.folders;
	};

	selectedAllFavorites = () => {
		// this.selectedFavorites = this.favorites.map((favorite) => favorite.id.toString());
		this.selectedFavorites = this.favorites;
	};

	selectedAllPins = () => {
		// this.selectedPins = this.pins.map((pin) => pin.id.toString());
		this.selectedPins = this.pins;
	};
}
