import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {ApiResponse} from '../types/dtos/service';
import {ExternalUserDto, ExternalUserTracking, RecipientLicenseDto} from '../types/dtos/models';

@Injectable({
	providedIn: 'root',
})
export class RecipientLicenseService {
	private apiHost = `${environment.apiHost}/recipientLicense`;

	constructor(private http: HttpClient, private router: Router) {}

	public getAllLicensesFromCompany = async (): Promise<ApiResponse<RecipientLicenseDto[]>> =>
		this.http
			.get<ApiResponse<RecipientLicenseDto[]>>(`${this.apiHost}`, {
				withCredentials: true,
			})
			.toPromise();

	public getExternalsUsersFromLicense = async (licenseId: number): Promise<ApiResponse<ExternalUserDto[]>> =>
		this.http
			.get<ApiResponse<ExternalUserDto[]>>(`${this.apiHost}/users/${licenseId}`, {
				withCredentials: true,
			})
			.toPromise();

	public removeExternalsUserFromLicense = async (deviceId: string, licenseId: number): Promise<ApiResponse<void>> =>
		this.http
			.delete<ApiResponse<void>>(`${this.apiHost}/users/${licenseId}/${deviceId}`, {
				withCredentials: true,
			})
			.toPromise();

	public getAllExternalUsers = async (): Promise<ApiResponse<ExternalUserDto[]>> =>
		this.http
			.get<ApiResponse<ExternalUserDto[]>>(`${this.apiHost}/users`, {withCredentials: true})
			.toPromise();

	public getUserTracking = async (
		deviceIds: string,
		timezone: string
	): Promise<ApiResponse<ExternalUserTracking[]>> =>
		this.http
			.get<ApiResponse<ExternalUserTracking[]>>(`${environment.apiHost}/live_tracking/external`, {
				withCredentials: true,
				params: {
					deviceIds,
					timezone,
				},
			})
			.toPromise();
}
