import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {Router} from '@angular/router';
import {ShareAssetDto, ShareExternalAssetDto} from '../types/dtos/models';

@Injectable({
	providedIn: 'root',
})
export class ShareService {
	private apiHost = `${environment.apiHost}/share_assets`;

	constructor(private http: HttpClient, private router: Router) {}

	public shareAssets = async (shareAssetDto: ShareAssetDto): Promise<ApiResponse<undefined>> => {
		return this.http
			.post<ApiResponse<undefined>>(this.apiHost, shareAssetDto, {withCredentials: true})
			.toPromise();
	};

	public shareExternalAsset = async (shareAssetDto: ShareExternalAssetDto): Promise<ApiResponse<any>> => {
		return this.http
			.post<ApiResponse<any>>(`${this.apiHost}/external`, shareAssetDto, {withCredentials: true})
			.toPromise();
	};
}
