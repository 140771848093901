export const DEFAULT_RADIUS = 91.44;
export const DEFAULT_TITLE_LENGTH = 20;
export const DEFAULT_TITLE_LONG_LENGTH = 30;

export enum MEASUREMENT_SYSTEMS {
	IMPERIAL = 'imperial',
	METRIC = 'metric',
}

export enum MEASUREMENT_UNITS_TRANSLATION {
	METERS_TO_CENTIMETERS = 100,
	CENTIMETERS_TO_METERS = 0.01,
	FEET_TO_CENTIMETERS = 30.48,
	CENTIMETERS_TO_FEET = 0.0328084,
	FEET_TO_METERS = 0.3048,
	METERS_TO_FEET = 3.28084,
	POUNDS_TO_KILOGRAMS = 0.453592,
	KILOGRAMS_TO_POUNDS = 2.20462,
	FEET_TO_INCHES = 12,
}

export const UserTypes = [
	'New',
	'Legacy Pro iOS',
	'Legacy ND iOS',
	'Legacy Unlimited iOS',
	'Legacy USA Android',
	'Legacy ND Android',
	'Legacy TX Android',
	'Legacy Ultimate Android',
	'Legacy Unlimited Android',
];

export enum LAYER_TYPES {
	WELLS = 'WELLS',
	SWD = 'SWD',
	RIGS = 'RIGS',
	FRAC = 'FRAC',
	DRILL_PERMITS = 'DRILL_PERMITS',
}

export const Platforms = ['iOS', 'Android', 'Web'];
export const EMAIL_REGEX = '/[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,64}/';
