import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {PerfectScrollbarComponent} from 'ngx-perfect-scrollbar';
import {PaginationMetadata} from 'src/app/helpers/requestHelper';
import {ConfirmationService} from 'src/app/services/confirmation.service';
import {LoaderService} from 'src/app/services/loader.service';
import {PlanService} from 'src/app/services/plan.service';
import {ToastService} from 'src/app/services/toast.service';
import {InvitedUserDto} from 'src/app/types/dtos/models';

@Component({
	selector: 'app-pending-users-modal',
	templateUrl: './pending-users-modal.component.html',
	styleUrls: ['./pending-users-modal.component.scss'],
})
export class PendingUsersModalComponent implements OnInit {
	constructor(
		private planService: PlanService,
		private loaderService: LoaderService,
		private changeDetectorRef: ChangeDetectorRef,
		private toastService: ToastService,
		private confirmationService: ConfirmationService
	) {}
	@Output() closeModal = new EventEmitter();
	@ViewChild('perfectScrollBarPendingUsers') perfectScroll: PerfectScrollbarComponent;
	public pendingUsers: InvitedUserDto[] = [];
	public pagination: PaginationMetadata = {
		page: 1,
		pageSize: 10,
		totalPages: 0,
		totalRecords: 0,
	};

	ngOnInit(): void {
		this.getPendingUsers();
	}

	onScroll = async () => {
		if (
			this.perfectScroll.directiveRef.position().y === 'end' &&
			this.pagination.page < this.pagination.totalPages
		) {
			this.setPage(this.pagination.page + 1);
			this.perfectScroll.directiveRef.update();
		}
	};

	private getPendingUsers = async () => {
		try {
			this.loaderService.show();
			const {totalPages, totalRecords, ...pagination} = this.pagination;
			const result = await this.planService.getInvitations({...pagination}, 'PENDING');
			this.pendingUsers = this.pendingUsers.concat(result.data);
			this.pagination = result.pagination;
			this.changeDetectorRef.detectChanges();
		} catch (err) {
			this.toastService.show({severity: 'error', summary: 'Error', detail: err.message});
		}
		finally{

			this.loaderService.hide();
		}
	};

	public setPage = (page: number) => {
		this.pagination = {
			...this.pagination,
			page,
		};
		this.getPendingUsers();
	};

	public resendInvite = async (invitationId: string) => {
		try {
			this.loaderService.show();
			const result = await this.planService.resendInvite(invitationId);
			this.toastService.show({
				severity: 'success',
				summary: `Success, we've just sent another message to the user.`,
			});
		} catch (err) {
			this.toastService.show({
				severity: 'error',
				summary: 'Error resending the invitation, please try again later',
			});
		}
		finally{

			this.loaderService.hide();
		}
	};

	public anulateInvite = async (invitationId: string) => {
		try {
			this.confirmationService.show('Are you sure?', 'You will delete the invitation to the user.', 'Yes', 'No');
			const confirmation = await this.confirmationService.getConfirmationValue();
			if (confirmation) {
				this.loaderService.show();
				const result = await this.planService.anulateInvite(invitationId);
				this.toastService.show({
					severity: 'success',
					summary: `Success, the invitation was deleted.`,
				});
				this.pendingUsers = this.pendingUsers.filter((user) => user.id !== invitationId);
				this.changeDetectorRef.detectChanges();
			}
		} catch (err) {
			this.toastService.show({
				severity: 'error',
				summary: 'Error deleting the invitation, please try again later.',
			});
		}
		finally{

			this.loaderService.hide();
		}
	};
}
