import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {Router} from '@angular/router';
import {FolderDto, FavoriteDto} from '../types/dtos/models';

@Injectable({
	providedIn: 'root',
})
export class FavoriteService {
	private apiHost = `${environment.apiHost}/favorite`;

	constructor(private http: HttpClient, private router: Router) {}

	public getFavorites = async (): Promise<ApiResponse<FavoriteDto[]>> =>
		this.http
			.get<ApiResponse<FavoriteDto[]>>(`${this.apiHost}/marker`, {
				withCredentials: true,
			})
			.toPromise();

	public addFavorite = async (favorite: Partial<FavoriteDto>): Promise<ApiResponse<FavoriteDto>> =>
		this.http
			.post<ApiResponse<FavoriteDto>>(this.apiHost, favorite, {withCredentials: true})
			.toPromise();

	public addFavoritesListWithGates = async (
		favorites: Partial<FavoriteDto>[],
		rootFolder: boolean,
		folders: number[]
	): Promise<ApiResponse<FavoriteDto>> =>
		this.http
			.post<ApiResponse<FavoriteDto>>(
				`${this.apiHost}/folder/bulk`,
				{markers: favorites, rootFolder, folders},
				{withCredentials: true}
			)
			.toPromise();

	public addFavoritesList = async (favorite: Partial<FavoriteDto>): Promise<ApiResponse<FavoriteDto>> =>
		this.http
			.post<ApiResponse<FavoriteDto>>(`${this.apiHost}/list`, favorite, {withCredentials: true})
			.toPromise();

	public editFavorite = async (favorite: Partial<FavoriteDto>): Promise<ApiResponse<FavoriteDto>> =>
		this.http
			.put<ApiResponse<FavoriteDto>>(this.apiHost, favorite, {withCredentials: true})
			.toPromise();

	public deleteFavorite = async (markerId: string, ownerUserId: number): Promise<ApiResponse<FavoriteDto>> =>
		this.http
			.delete<ApiResponse<FavoriteDto>>(this.apiHost, {
				params: {markerId, userId: ownerUserId},
				withCredentials: true,
			})
			.toPromise();

	public getFolders = async (): Promise<ApiResponse<FolderDto[]>> =>
		this.http
			.get<ApiResponse<FolderDto[]>>(`${this.apiHost}/folder`, {
				withCredentials: true,
			})
			.toPromise();

	public getFolderFavorites = async (folderId: number, userId: number): Promise<ApiResponse<FavoriteDto[]>> =>
		this.http
			.get<ApiResponse<FavoriteDto[]>>(`${this.apiHost}/folder/marker`, {
				withCredentials: true,
				params: {folderId: folderId.toString(), userId: userId.toString()},
			})
			.toPromise();

	public addFolder = async (folder: Partial<FolderDto>): Promise<ApiResponse<FolderDto>> =>
		this.http
			.post<ApiResponse<FolderDto>>(`${this.apiHost}/folder`, folder, {withCredentials: true})
			.toPromise();

	public editFolder = async (name: string, folderId: number): Promise<ApiResponse<FolderDto>> =>
		this.http
			.put<ApiResponse<FolderDto>>(`${this.apiHost}/folder`, {name, folderId}, {withCredentials: true})
			.toPromise();

	public deleteFolder = async (folderId: string, ownerUserId: number): Promise<ApiResponse<FavoriteDto>> =>
		this.http
			.delete<ApiResponse<FavoriteDto>>(`${this.apiHost}/folder`, {
				params: {folderId, userId: ownerUserId},
				withCredentials: true,
			})
			.toPromise();
}
