import {MapParams, MapStatus} from '../services/map.service';
import {iconByColor} from './utils';
import {MapMarker, MarkerDto, CustomMarkerDto, ClusterDto, StopDto} from '../types/dtos/models';
import {COLORS, DrawTools} from '../types/enums';

export const getGeoHashlevel = (zoom: number): number => {
	switch (true) {
		case zoom < 6:
			return 1;
		case zoom >= 6 && zoom < 8:
			return 2;
		case zoom >= 8 && zoom < 10:
			return 3;
		case zoom >= 10 && zoom < 12:
			return 4;
		case zoom === 12:
			return 4;
		case zoom === 13:
			return 5;
		case zoom > 13:
			return 7;
	}
};

export const getMarkerRequest = (mapParamas: MapParams, geohashLevel: number, mapSubject: MapStatus) => {
	return {
		latitude: mapParamas.lat,
		longitude: mapParamas.lng,
		geohashLevel: geohashLevel === 7 ? 5 : geohashLevel,
		neighboursLevel: 2,
		layer: mapSubject.layer.id,
		states: mapSubject.states.map((s) => s.id).join('-'),
		point: geohashLevel < 7 && mapSubject.layer.id !== 'RIGS' ? 'CLUSTER' : 'MARKER',
	};
};

export const createMapMarker = (marker: MarkerDto, markerSelected?: MarkerDto): MapMarker => {
	return {
		id: marker.id,
		lat: marker.lat,
		lng: marker.lng,
		name: marker.name,
		iconUrl: iconByColor(marker.color, markerSelected && marker.id === markerSelected.id),
		iscustomMarker: marker.iscustomMarker,
		color: marker.color,
	};
};

export const createClusterDto = (cluster: ClusterDto): ClusterDto => {
	return {
		...cluster,
		label: {
			color: 'white',
			text: +cluster.count < 1001 ? cluster.count : '+1000',
			fontFamily: '',
			fontSize: cluster.count.length < 3 ? '14px' : cluster.count.length < 4 ? '12px' : '9px',
			fontWeight: cluster.count.length < 4 ? 'semi-bold' : 'bold',
		},
	};
};

export const createMapMarkers = (markers: MapMarker[], markerSelected: MarkerDto): MapMarker[] => {
	const mapMarkers = markers.map((currentValue) => {
		return {
			id: currentValue.id,
			lat: currentValue.lat,
			lng: currentValue.lng,
			name: currentValue.name,
			iconUrl: iconByColor(currentValue.color, markerSelected && currentValue.id === markerSelected.id),
			iscustomMarker: currentValue.iscustomMarker,
			color: currentValue.color,
			zIndex: markerSelected && currentValue.id === markerSelected.id ? 6 : undefined,
		};
	});
	return mapMarkers;
};

export const createMapCustomMarkers = (markers: CustomMarkerDto[]): MapMarker[] => {
	const mapMarkers = markers.map((currentValue) => {
		return {
			id: currentValue.id,
			lat: currentValue.lat,
			lng: currentValue.lng,
			iconUrl: '../../../../assets/img/map_assets/markers/ic_marker_pin_blue.svg',
		};
	});
	return mapMarkers;
};

export const getPinnedCustomMarker = (mapSubject: MapStatus) => {
	switch (mapSubject.layer.id) {
		case 'WELLS':
			return mapSubject.wellsPins.filter((pin) => pin.iscustomMarker);
		case 'SWD':
			return mapSubject.swdPins.filter((pin) => pin.iscustomMarker);
		case 'RIGS':
			return mapSubject.ringsPins.filter((pin) => pin.iscustomMarker);
		case 'FRAC':
			return mapSubject.fracPins.filter((pin) => pin.iscustomMarker);
		case 'CA_WELLS':
			return mapSubject.caWellsPins.filter((pin) => pin.iscustomMarker);
		case 'CA_SWD':
			return mapSubject.caSwdPins.filter((pin) => pin.iscustomMarker);
		case 'IN_WELLS':
			return mapSubject.inWellsPins.filter((pin) => pin.iscustomMarker);
	}
};

export const getAllPinsLayers = (mapSubject: MapStatus) => {
	switch (mapSubject.layer.id) {
		case 'WELLS':
			return mapSubject.wellsPins;
		case 'SWD':
			return mapSubject.swdPins;
		case 'CA_WELLS':
			return mapSubject.caWellsPins;
		case 'IN_WELLS':
			return mapSubject.inWellsPins;
		case 'CA_SWD':
			return mapSubject.caSwdPins;
		case 'RIGS':
			return mapSubject.ringsPins;
		case 'FRAC':
			return mapSubject.fracPins;
	}
};

export const isSelectedPin = (value: MapMarker, mapStatus: MapStatus): boolean => {
	const {wellsPins, swdPins, ringsPins, fracPins, caSwdPins, caWellsPins, inWellsPins, ...currentUser} = mapStatus;
	let selectedPin;
	switch (mapStatus.layer.id) {
		case 'WELLS':
			selectedPin = wellsPins.find((marker) => marker?.id === value.id);
			break;
		case 'SWD':
			selectedPin = swdPins.find((marker) => marker?.id === value.id);
			break;
		case 'CA_WELLS':
			selectedPin = caWellsPins.find((marker) => marker?.id === value.id);
			break;
		case 'IN_WELLS':
			selectedPin = inWellsPins.find((marker) => marker?.id === value.id);
			break;
		case 'CA_SWD':
			selectedPin = caSwdPins.find((marker) => marker?.id === value.id);
			break;
		case 'RIGS':
			selectedPin = ringsPins.find((marker) => marker?.id === value.id);
			break;
		case 'FRAC':
			selectedPin = fracPins.find((marker) => marker?.id === value.id);
			break;
	}
	return selectedPin !== undefined;
};

export const getValueFromDrawToolSelection = (drawToolSelection: string): string | null => {
	if (drawToolSelection === DrawTools.POLYLINE) {
		return 'polyline';
	} else {
		return null;
	}
};

export const getColorCodeById = (id: COLORS): string => {
	switch (id) {
		case COLORS.RED:
			return 'DC555B';
		case COLORS.BLUE:
			return '4F97E9';
		case COLORS.GREEN:
			return '83BC5F';
		case COLORS.YELLOW:
			return 'F6CB6E';
		case COLORS.BLACK:
			return '000000';
		case COLORS.WHITE:
			return 'FFFFFF';
		case COLORS.DEFAULT:
			return 'CF4727';
		case COLORS.PINK:
			return 'C02C68';
		case COLORS.ORANGE:
			return 'EF9148';
	}
};

export const getColorByCode = (id: string): string => {
	switch (id) {
		case 'DC555B':
			return COLORS.RED;
		case '4F97E9':
			return COLORS.BLUE;
		case '83BC5F':
			return COLORS.GREEN;
		case 'F6CB6E':
			return COLORS.YELLOW;
		case '000000':
			return COLORS.BLACK;
		case 'FFFFFF':
			return COLORS.WHITE;
		case 'CF4727':
			return COLORS.DEFAULT;
		case 'C02C68':
			return COLORS.PINK;
		case 'EF9148':
			return COLORS.ORANGE;
		default:
			return COLORS.CUSTOM;
	}
};

export const trimText = (text: string, length: number): string => {
	return text.length > length ? text.substring(0, length) + '...' : text;
};

// Calculate the distance between two LatLng points
const calculateDistance = (p1: google.maps.LatLng, p2: google.maps.LatLng): number => {
	return google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
};
// Find the index of the closest vertex on the polyline to the given waypoint
const findClosestVertexIndex = (waypoint: google.maps.LatLng, polyline: google.maps.Polyline): number => {
	let closestIndex = -1;
	let minSquaredDistance = Number.MAX_VALUE;

	for (let i = 0; i < polyline.getPath().getLength(); i++) {
		const vertex = polyline.getPath().getAt(i);
		const distance = calculateDistance(waypoint, vertex);

		if (distance < minSquaredDistance) {
			minSquaredDistance = distance;
			closestIndex = i;
		}
	}

	return closestIndex;
};

// Calculate the distance along the polyline from start to end index
const calculateDistanceAlongPolyline = (
	polyline: google.maps.Polyline,
	startIndex: number,
	endIndex: number
): number => {
	let distance = 0;

	for (let i = startIndex; i < endIndex; i++) {
		distance += calculateDistance(polyline.getPath().getAt(i), polyline.getPath().getAt(i + 1));
	}

	return distance;
};

// Find the index to insert the new waypoint
export const findInsertionIndex = (
	waypoints: StopDto[],
	polyline: google.maps.Polyline,
	clickedPosition: google.maps.LatLng
): number => {
	const closestVertexIndexes = waypoints.map((waypoint) => {
		return findClosestVertexIndex(new google.maps.LatLng(waypoint.lat, waypoint.lng), polyline);
	});
	const closestVertexIndex = findClosestVertexIndex(clickedPosition, polyline);
	return findInsertionIndexInOrderedArray(closestVertexIndexes, closestVertexIndex);
};

// Find the index to insert a number into an ordered array while maintaining the order
export const findInsertionIndexInOrderedArray = (orderedArray: number[], newNumber: number): number => {
	let startIndex = 0;
	let endIndex = orderedArray.length;

	while (startIndex < endIndex) {
		const midIndex = Math.floor((startIndex + endIndex) / 2);
		const midValue = orderedArray[midIndex];

		if (midValue === newNumber) {
			return midIndex; // Insert at the current position to maintain order
		} else if (midValue < newNumber) {
			startIndex = midIndex + 1;
		} else {
			endIndex = midIndex;
		}
	}

	return startIndex; // Insert at startIndex to maintain order
};
