<div class="card image-uploader">
	<div class="image" *ngIf="image">
		<figure (click)="openImage()" title="See image in fullscreen">
			<img src="{{ image }}" alt="image" />
		</figure>
		<i class="icon-trash" (click)="deleteImage()" title="Remove image"></i>
	</div>
	<div class="upload" *ngIf="!image">
		<div class="upload-container">
			<input
				id="file-upload"
				#fileUpload
				type="file"
				class="btn btn-white"
				accept="image/*"
				(change)="handleFileInput($event, fileUpload)"
			/>
			<label for="file-upload">
				<a class="btn btn-white">Upload Image</a>
			</label>
		</div>
	</div>
</div>
<div class="image-open" *ngIf="showImage" (click)="closeImage()">
	<figure *ngIf="image">
		<img [src]="image" alt="Loading..." />
	</figure>
</div>
