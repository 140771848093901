import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
	selector: 'app-remove-modal',
	templateUrl: './remove-modal.component.html',
	styleUrls: ['./remove-modal.component.scss'],
})
export class RemoveModalComponent implements OnInit {
	constructor() {}

	@Input() text: string;
	@Output() removeConfirmation = new EventEmitter<boolean>();

	ngOnInit(): void {}
}
