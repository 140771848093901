<app-popup-add-phone-number
	*ngIf="showAddPhoneNumberPopup"
	(close)="showAddPhoneNumberPopup = false"
></app-popup-add-phone-number>
<app-popup-acknowledge
	*ngIf="showAcknowledgePopup"
	(close)="showAcknowledgePopup = false"
	[message]="acknowledgePopupData.message"
	[title]="acknowledgePopupData.title"
	[buttonText]="acknowledgePopupData.buttonText"
></app-popup-acknowledge>
