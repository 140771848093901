import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {geoJsonToPolylines} from 'src/app/helpers/shareHelper';
import {LoaderService} from 'src/app/services/loader.service';
import {RecordedRouteService} from 'src/app/services/recorded-route.service';
import {ToastService} from 'src/app/services/toast.service';
import {ChatRecordedRoute} from 'src/app/types/dtos/chat';
import {MapRecordedRouteDto} from 'src/app/types/dtos/models';
import {ItemType} from 'src/app/types/enums';

@Component({
	selector: 'app-show-recorded-route-modal',
	templateUrl: './show-recorded-route-modal.component.html',
	styleUrls: ['./show-recorded-route-modal.component.scss'],
})
export class ShowRecordedRouteModalComponent implements OnInit {
	constructor(
		private router: Router,
		private recordedRouteService: RecordedRouteService,
		private loaderService: LoaderService,
		private toastService: ToastService
	) {}

	public _route: MapRecordedRouteDto;
	public _routeFromChat: ChatRecordedRoute;

	@Input('route')
	set route(value: ChatRecordedRoute) {
		this._routeFromChat = value;
		const recordedRoute: MapRecordedRouteDto = {
			name: value.name,
			description: value.description,
			polylines: geoJsonToPolylines(value.geoJson),
			items: value.items,
			createdByUser: value.createdByUser,
		};
		this._route = recordedRoute;
	}
	get route(): ChatRecordedRoute {
		return this._routeFromChat;
	}
	@Input() type: string;

	@Output() close = new EventEmitter();

	public userIsRouteOwner: boolean = false;
	public importRouteName: boolean = false;

	public form: UntypedFormGroup = undefined;
	ngOnInit(): void {
		this.setForm();
	}

	public setForm = () => {
		this.form = new UntypedFormGroup({
			name: new UntypedFormControl(this.route.name, [Validators.required]),
		});
	};

	public importRecordedRoute = async () => {
		try {
			this.loaderService.show();
			const newRoute = this._routeFromChat;
			newRoute.name = this.form.value.name;
			const response = await this.recordedRouteService.saveRecordedRoute(newRoute);
			this.toastService.show({severity: 'success', summary: 'Route saved successfully'});
			this.router.navigate(['/dashboard/map/routing/recorded-routes/edit'], {
				queryParams: {id: response.data.id},
			});
		} catch (error) {
			console.error(error);
		} finally {
			this.loaderService.hide();
		}
	};
	public quickView = () => {
		this.router.navigate(['/dashboard/map/routing/recorded-routes'], {state: {recordedRoute: this._route}});
	};
}
