import {Pipe, PipeTransform} from '@angular/core';
import {convertToFeetAndInches} from '../helpers/utils';
import {MEASUREMENT_SYSTEMS} from '../types/constants';

@Pipe({
	name: 'transformFeetToFeetInches',
})
export class TransformFeetToFeetInchesPipe implements PipeTransform {
	transform(feets: number, ...args: unknown[]): string {
		return convertToFeetAndInches(feets, MEASUREMENT_SYSTEMS.IMPERIAL);
	}
}
