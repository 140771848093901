import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from 'src/app/services/auth.service';
import {LoaderService} from 'src/app/services/loader.service';
import {ToastService} from 'src/app/services/toast.service';
import {UserService} from 'src/app/services/user.service';
import {UtilitiesService} from 'src/app/services/utilities.service';
import {POPUPS_TO_SHOW} from 'src/app/types/enums';

@Component({
	selector: 'app-popup-add-phone-number',
	templateUrl: './popup-add-phone-number.component.html',
	styleUrls: ['./popup-add-phone-number.component.scss'],
})
export class PopupAddPhoneNumberComponent implements OnInit {
	public form;
	public currentUser;
	@Output() close = new EventEmitter();

	constructor(
		private userService: UserService,
		private utilities: UtilitiesService,
		private auth: AuthService,
		private toastService: ToastService,
		private loaderService: LoaderService
	) {}

	ngOnInit(): void {
		this.setForm();
		this.currentUser = this.auth.currentUserValue;
	}

	public setForm = () => {
		this.form = new UntypedFormGroup({
			phone: new UntypedFormControl('', [Validators.required, Validators.pattern('^\\+?[0-9]{0,115}')]),
		});
	};

	closePopup = () => {
		this.utilities.setPopupShown(POPUPS_TO_SHOW.ADD_PHONE_NUMBER);
		this.close.emit();
	};

	onSubmit = async () => {
		if (this.form.invalid) return;
		try {
			this.loaderService.show();
			const {phone} = this.form.value;
			await this.userService.updateUserSettings({phoneNumber: phone});
			await this.auth.getUserData();
			this.closePopup();
		} catch (error) {
			this.toastService.show({
				severity: 'error',
				summary: 'Error',
				detail: 'Error updating phone number. Please try again later.',
			});
		} finally {
			this.loaderService.hide();
		}
	};
}
