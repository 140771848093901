import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastService} from 'src/app/services/toast.service';
import {ItemContent} from 'src/app/types/dtos/models';
import {ContentType} from 'src/app/types/enums';

@Component({
	selector: 'app-image-uploader',
	templateUrl: './image-uploader.component.html',
	styleUrls: ['./image-uploader.component.scss'],
})
export class ImageUploaderComponent implements OnInit {
	public image: string;
	public value$: ItemContent;
	public file;
	public showImage: boolean = false;

	@Input('value')
	set value(value: ItemContent) {
		if (!value) {
			this.value$ = {
				type: ContentType.IMAGE,
				data: undefined,
			};
		} else {
			this.value$ = value;
			this.image = value.data;
		}
	}
	get value(): ItemContent {
		return this.value$;
	}
	@Output() valueChange: EventEmitter<ItemContent> = new EventEmitter();
	@Output() delete = new EventEmitter();

	constructor(private toastService: ToastService) {}

	ngOnInit(): void {}

	public setValue = (value: ItemContent) => {
		this.value = value;
		this.valueChange.emit(value);
	};

	openImage = () => {
		this.showImage = true;
	};
	closeImage = () => {
		this.showImage = false;
	};

	handleFileInput = (event: any, files: any) => {
		const fileList: FileList = files.files;
		if (fileList) {
			this.processFiles(fileList);
		}
	};

	processFiles = (files: FileList) => {
		for (let i = 0; i < files.length; i++) {
			const filereader = new FileReader();
			const file: File = files.item(i);
			if (file.type.match('image.*')) {
				this.file = file;
				if (file.type.match('(.*)(png|jpg|jpeg|gif)')) {
					filereader.onload = (e) => {
						const dataURL = e.target.result;
						this.image = dataURL as string;
						this.value$.data = dataURL as string;
						this.setValue(this.value$);
						return dataURL;
					};
					filereader.readAsDataURL(files[i]);
				} else {
					this.image = '';
				}
			} else {
				this.toastService.show({severity: 'warn', summary: 'Invalid File type, please select an image file'});
			}
		}
	};

	deleteImage = () => {
		this.image = undefined;
		this.value$.id = undefined;
		this.value$.data = undefined;
		this.setValue(this.value$);
	};
	uploadImage = (event: any) => {};
}
