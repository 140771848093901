import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
	MarkerDto,
	MapMarker,
	FavoriteDto,
	RecentSearchDto,
	FolderDto,
	CustomMarkerDto,
	MultiStopRouteDto,
	MapDrawingDto,
	MapRecordedRouteDto,
} from 'src/app/types/dtos/models';
import {isSelectedPin} from 'src/app/helpers/mapHelper';
import {SearchService} from 'src/app/services/search.service';
import {FavoriteService} from 'src/app/services/favorite.service';
import {COLORS, MarkerType, SHARE_TYPES} from 'src/app/types/enums';
import {iconByColor, creatMapPin} from 'src/app/helpers/utils';
import {LoaderService} from 'src/app/services/loader.service';
import {throwError} from 'rxjs';
import {MarkerService} from 'src/app/services/marker.service';
import {RouteService} from 'src/app/services/route.service';
import {DrawService} from 'src/app/services/draw.service';
import {ToastService} from 'src/app/services/toast.service';
import {RecordedRouteService} from 'src/app/services/recorded-route.service';
import {MapService} from 'src/app/services/map.service';

@Component({
	selector: 'app-marker-actions',
	templateUrl: './marker-actions.component.html',
	styleUrls: ['./marker-actions.component.scss'],
})
export class MarkerActionsComponent implements OnInit {
	addFavorite = false;
	showMenuPopup = false;
	share = false;
	removeMarker = false;

	public route: MultiStopRouteDto;
	public customRoute: MapDrawingDto;
	public MARKER = MarkerType.MARKER;
	constructor(
		private mapService: MapService,
		private searchService: SearchService,
		private routeService: RouteService,
		private favoriteService: FavoriteService,
		private loaderService: LoaderService,
		private drawService: DrawService,
		private markerService: MarkerService,
		private toastService: ToastService,
		private recordRouteService: RecordedRouteService
	) {}

	@Input() shareIcon: boolean;
	@Input() fromFavorites: boolean;
	@Input() marker: MarkerDto | RecentSearchDto;
	@Input() folder: FolderDto;
	@Input() pin: CustomMarkerDto;
	@Input() partialRoute: Partial<MultiStopRouteDto>;
	@Input() recordRoute: Partial<MapRecordedRouteDto>;
	@Input() customRouteId: number;
	@Input() showClone: boolean = false;
	@Output() shareCustomMarker = new EventEmitter<CustomMarkerDto>();
	@Output() deletedRecentSearch = new EventEmitter();
	@Output() deleteFromFolder = new EventEmitter<Partial<FavoriteDto>>();
	@Output() removeCustomMarker = new EventEmitter();
	@Output() deletedFavorite = new EventEmitter();
	@Output() editCustomMarker = new EventEmitter();
	@Output() editFavorite = new EventEmitter();
	@Output() editRoute = new EventEmitter();
	@Output() editCustomRoute = new EventEmitter();
	@Output() delete = new EventEmitter();
	@Output() clone = new EventEmitter();
	@Output() shareRoute = new EventEmitter();

	ngOnInit(): void {}

	public getFullRoute = async () => {
		try {
			const res = await this.routeService.getFullRoute(this.partialRoute.id);
			const route = res.data;
			Object.assign(route, {drawIds: route.draws.map((d) => d.id)});
			this.route = route;
		} catch (err) {
			throwError(err);
		}
	};

	public getRecordedRoute = async (id: number) => {
		try {
			const res = await this.recordRouteService.getRecordedRouteById(id);
			const route = res.data;
			this.recordRoute = route;
		} catch (err) {
			throwError(err);
		}
	};

	public getFullCustomRoute = async (customRouteId: number) => {
		try {
			const res = await this.drawService.getFullDraw(customRouteId);
			this.customRoute = res.data;
		} catch (err) {
			throwError(err);
		}
	};

	pinSelected = () => {
		const result = isSelectedPin(this.marker ? this.marker : this.pin, this.mapService.currentStatus);
		return result;
	};

	addPin = () => {
		this.mapService.addPin(creatMapPin(this.marker ? this.marker : {...this.pin, iscustomMarker: true}));
	};

	removePin = () => {
		const pin = this.marker
			? (this.marker as MapMarker)
			: {...this.pin, color: COLORS.BLUE, iscustomMarker: true, iconUrl: iconByColor(COLORS.BLUE, false)};
		this.mapService.removePin(pin);
	};

	addedFavorite = (favorite: FavoriteDto) => {
		if (favorite) {
			this.marker.favorite = favorite;
			this.editFavorite.emit(favorite);
			``;
			this.mapService.addFavorite(favorite);
		}
		this.addFavorite = false;
	};

	deleteFavorite = async (remove) => {
		if (remove) {
			this.loaderService.show();
			try {
				const result = await this.favoriteService.deleteFavorite(
					this.marker.id.toString(),
					this.marker.createdByUser.id
				);
				if (!this.fromFavorites) this.mapService.removeFavorite(this.marker.favorite);
				else this.deletedFavorite.emit(this.marker.id);
				this.marker.favorite = undefined;
				this.addFavorite = false;
				this.toastService.show({severity: 'success', summary: 'Favorite deleted'});
			} catch (err) {
				if (err.error.message === 'Favorite not found') {
					this.mapService.removeFavorite(this.marker.favorite);
					this.marker.favorite = undefined;
					this.addFavorite = false;
					this.deletedFavorite.emit(this.marker.id);
				}
				throwError(err);
			} finally {
				this.loaderService.hide();
			}
		}
		this.removeMarker = false;
	};

	deleteCustomMarker = async (remove: boolean) => {
		if (remove) {
			try {
				this.loaderService.show();
				const result = await this.markerService.deleteCustomMarker(this.pin.id, this.pin.createdByUser.id);
				this.mapService.addCustomPin();
				this.removeCustomMarker.emit(this.pin);
				this.toastService.show({severity: 'success', summary: 'Custom marker deleted'});
			} catch (err) {
				console.error(err);
				this.toastService.show({severity: 'error', summary: 'Error deleting custom marker'});
				throwError(err);
			} finally {
				this.loaderService.hide();
			}
		}
		this.removeMarker = false;
	};

	deleteRecordRoute = async (remove: boolean) => {
		if (remove) {
			try {
				this.loaderService.show();
				await this.recordRouteService.deleteRecordedRoute(
					this.recordRoute.id,
					this.recordRoute.createdByUser.id
				);
				this.toastService.show({severity: 'success', summary: 'Record route deleted'});
				await this.recordRouteService.getRecordedRoutes();
			} catch (err) {
				console.error(err);
				this.toastService.show({severity: 'error', summary: 'Error deleting custom marker'});
				throwError(err);
			} finally {
				this.loaderService.hide();
			}
		}
		this.removeMarker = false;
	};

	deleteJustFromFolder = async () => {
		this.deleteFromFolder.emit({markerId: this.marker?.id || this.pin?.id, folders: [this.folder.id]});
		this.removeMarker = false;
	};

	public shareContent = async (event: any) => {
		event.stopPropagation();
		event.preventDefault();
		if (this.customRouteId) {
			await this.getFullCustomRoute(this.customRouteId);
			const data = {type: SHARE_TYPES.CUSTOM_ROUTE, data: this.customRoute};
			this.shareRoute.emit(data);
		} else if (this.partialRoute) {
			await this.getFullRoute();
			const data = {type: SHARE_TYPES.ROUTE, data: this.route};
			this.shareRoute.emit(data);
		} else if (this.recordRoute) {
			await this.getRecordedRoute(this.recordRoute.id);
			const data = {type: SHARE_TYPES.RECORDED_ROUTE, data: this.recordRoute};
			this.shareRoute.emit(data);
		} else if (this.pin) {
			this.shareCustomMarker.emit(this.pin);
		} else {
			this.share = true;
			this.showMenuPopup = false;
		}
	};

	deleteRoute = async (remove: boolean) => {
		if (remove) {
			this.delete.emit();
		}
		this.removeMarker = false;
	};

	cloneClick = () => {
		this.clone.emit();
	};
}
