<div class="marker-actions menu-popup-wrap">
	<i
		*ngIf="!shareIcon"
		[ngClass]="{'icon-heart': !marker?.favorite, 'icon-heart-focus': marker?.favorite}"
		(click)="addFavorite = true"
	></i>
	<i *ngIf="!!showClone" class="icon icon-clone" title="Clone" (click)="cloneClick()"></i>
	<i *ngIf="!!shareIcon" class="icon icon-share" title="Share" (click)="shareContent($event)"></i>
	<i
		*ngIf="!(pin && pin.isNewMarker)"
		class="icon icon-dots"
		title="Display menu"
		(click)="showMenuPopup = !showMenuPopup"
	></i>
	<div
		class="menu-popup"
		[ngClass]="{show: showMenuPopup}"
		(click)="showMenuPopup = false"
		*ngIf="!(pin && pin.isNewMarker)"
	>
		<ul>
			<li *ngIf="!shareIcon" (click)="shareContent($event)">
				<i class="icon-share"></i>
				Share
			</li>
			<li
				*ngIf="
					pin ||
					marker?.recentSearchId ||
					fromFavorites ||
					marker?.favorite ||
					partialRoute ||
					customRouteId ||
					recordRoute
				"
				(click)="
					customRouteId || partialRoute || recordRoute
						? delete.emit()
						: marker && marker.recentSearchId
						? deletedRecentSearch.emit(marker.recentSearchId)
						: (removeMarker = true)
				"
			>
				<i class="icon-trash"></i>
				Remove
			</li>
			<li
				*ngIf="pin || fromFavorites || partialRoute || customRouteId"
				(click)="pin ? editCustomMarker.emit() : partialRoute ? editRoute.emit() : (addFavorite = true)"
			>
				<i class="icon-edit"></i>
				Edit
			</li>
		</ul>
	</div>
	<i
		class="icon-outline-pin"
		[ngClass]="{active: pinSelected()}"
		*ngIf="!(pin?.isNewMarker || partialRoute || customRouteId || recordRoute)"
		(click)="!pinSelected() ? addPin() : removePin()"
	></i>
</div>

<app-add-edit-favorite
	*ngIf="addFavorite"
	[marker]="marker.favorite ? undefined : marker"
	[favorite]="marker.favorite ? marker.favorite : fromFavorites ? marker : undefined"
	[type]="MARKER"
	(addedFavorite)="addedFavorite($event)"
	(removeMarker)="removeMarker = true"
	(close)="addFavorite = false"
></app-add-edit-favorite>

<app-share
	*ngIf="share"
	[favorites]="!pin && (fromFavorites || marker?.favorite) ? [marker] : undefined"
	[markers]="marker && !pin && !marker.favorite && !fromFavorites ? [marker] : undefined"
	[marker]="marker"
	[routes]="route ? [route] : undefined"
	[customRoutes]="customRoute ? [customRoute] : undefined"
	[recordRoutes]="recordRoute ? [recordRoute] : undefined"
	[pins]="pin ? [pin] : undefined"
	[customMarker]="pin"
	(closedModal)="share = false"
></app-share>

<app-remove-modal
	*ngIf="removeMarker && !folder"
	[text]="
		pin
			? 'Are you sure you want to remove this from My Pins?'
			: partialRoute
			? 'Are you sure you want to remove this from Routes?'
			: recordRoute
			? 'Are you sure you want to remove this from recorded routes?'
			: 'Are you sure you want to remove this from Favorites?'
	"
	(removeConfirmation)="
		pin
			? deleteCustomMarker($event)
			: partialRoute
			? deleteRoute($event)
			: recordRoute
			? deleteRecordRoute($event)
			: deleteFavorite($event)
	"
></app-remove-modal>

<div class="modal remove" *ngIf="removeMarker && folder">
	<div class="modal-content">
		<i class="icon-close" (click)="removeMarker = false"></i>
		<p class="title">You want to remove this favorite from folder {{ folder.name }} or from all?</p>

		<div class="buttons">
			<button class="btn" (click)="deleteJustFromFolder()">This folder</button>
			<button class="btn" (click)="!!pin ? deleteCustomMarker(true) : deleteFavorite(true)">All</button>
			<button class="btn btn-white" (click)="removeMarker = false">Cancel</button>
		</div>
	</div>
</div>
