import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../types/dtos/service';
import {Router} from '@angular/router';

import {RecentSearchDto} from '../types/dtos/models';

@Injectable({
	providedIn: 'root',
})
export class SearchService {
	private apiHost = `${environment.apiHost}/recent_search`;

	constructor(private http: HttpClient, private router: Router) {}

	public getSearches = async (
		layerId: string,
		longitude: string,
		latitude: string
	): Promise<ApiResponse<{markers: RecentSearchDto[]}>> =>
		this.http
			.get<ApiResponse<{markers: RecentSearchDto[]}>>(`${this.apiHost}`, {
				params: {layerId, longitude, latitude},
				withCredentials: true,
			})
			.toPromise();

	public addSearch = async (markerId: number): Promise<ApiResponse<RecentSearchDto>> =>
		this.http.post<ApiResponse<RecentSearchDto>>(this.apiHost, {markerId}, {withCredentials: true}).toPromise();

	public deleteAll = async (layerId: string): Promise<ApiResponse<undefined>> =>
		this.http
			.delete<ApiResponse<undefined>>(`${this.apiHost}/all`, {
				params: layerId ? {layerId} : {},
				withCredentials: true,
			})
			.toPromise();

	public deleteSearch = async (recentSearchId: string): Promise<ApiResponse<undefined>> =>
		this.http
			.delete<ApiResponse<undefined>>(`${this.apiHost}`, {params: {recentSearchId}, withCredentials: true})
			.toPromise();
}
