import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	private loader = new Subject<boolean>();

	public show() {
		this.loader.next(true);
	}

	public hide() {
		this.loader.next(false);
	}

	public getLoaderValue() {
		return this.loader.asObservable();
	}
}
