<div *ngIf="value && value.toString().length" class="search_popover">
	<div *ngIf="markers && !markers.length" class="items-content">
		<span class="no-results">No results</span>
	</div>

	<div *ngIf="markers && !!markers.length" class="items-content">
		<div class="list">
			<ul>
				<li *ngFor="let item of markers" (click)="returnPlace(item)">
					<span class="name">
						{{ item.name }}
					</span>
					<span class="email">{{ item.apiNum }}</span>
				</li>
			</ul>
		</div>
	</div>
	<div *ngIf="users && !!users.length" class="items-content">
		<div class="list">
			<ul>
				<li *ngFor="let item of users" (click)="chosenUser.emit(item)">
					<span class="name">
						{{ item.firstName + ' ' + item.lastName }}
					</span>
					<span class="email">{{ item.email }}</span>
				</li>
			</ul>
		</div>
	</div>
</div>
