import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MarkerService} from 'src/app/services/marker.service';
import {GateService} from 'src/app/services/gate.service';
import {GateDto, CustomMarkerDto, MapMarker} from 'src/app/types/dtos/models';
import {UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {LatLngLiteral} from '@agm/core';
import {ColorList, COLORS} from 'src/app/types/enums';
import {ToastService} from 'src/app/services/toast.service';
import {LoaderService} from 'src/app/services/loader.service';
import {throwError} from 'rxjs';
import {NoWhitespaceValidator} from 'src/app/helpers/validator';
import {MapService} from 'src/app/services/map.service';

@Component({
	selector: 'app-add-entrance',
	templateUrl: './add-entrance.component.html',
	styleUrls: ['./add-entrance.component.scss'],
})
export class AddEntranceComponent implements OnInit {
	form: UntypedFormGroup = this.formBuilder.group({
		name: new UntypedFormControl('', [Validators.required, , Validators.minLength(2), NoWhitespaceValidator]),
		lat: new UntypedFormControl('', [Validators.required, Validators.min(-90), Validators.max(90)]),
		lng: new UntypedFormControl('', [Validators.required, Validators.min(-180), Validators.max(180)]),
		color: new UntypedFormControl(COLORS.DEFAULT, [Validators.required]),
	});
	public colors = COLORS;
	public colorList = ColorList;
	public currentMarker: CustomMarkerDto;

	constructor(
		private gateService: GateService,
		private formBuilder: UntypedFormBuilder,
		private markerService: MarkerService,
		private mapService: MapService,
		private toastService: ToastService,
		private loaderService: LoaderService
	) {}

	@Input('name')
	set setName(value: string) {
		this.form.patchValue({name: value});
	}

	@Input() markerId: number;

	@Input('customMarker')
	set customMarker(value: CustomMarkerDto) {
		this.currentMarker = value;
		if (value) {
			this.form.patchValue({
				name: value.name,
				lat: value.lat,
				lng: value.lng,
				color: value.color,
			});
		}
	}

	get customMarker(): CustomMarkerDto {
		return this.currentMarker;
	}

	@Input('mapCenter')
	set mapCenter(value: LatLngLiteral) {
		if (value) {
			this.form.patchValue({
				lat: value.lat,
				lng: value.lng,
			});
		}
	}

	get mapCenter(): LatLngLiteral {
		return {lat: this.form.value.lat, lng: this.form.value.lng};
	}

	@Input() savePin: boolean = true;
	@Output() onSave: EventEmitter<Partial<CustomMarkerDto>> = new EventEmitter<Partial<CustomMarkerDto>>();
	@Output() createdGate: EventEmitter<GateDto> = new EventEmitter<GateDto>();
	@Output() editedCustomMarker: EventEmitter<MapMarker> = new EventEmitter<MapMarker>();
	@Output() closeModal = new EventEmitter();

	ngOnInit(): void {}

	get f() {
		return this.form.controls;
	}

	addEntrance = async () => {
		try {
			this.loaderService.show();
			const gate: Partial<GateDto> = {
				markerId: this.markerId,
				name: this.form.value.name,
				latitude: this.form.value.lat,
				longitude: this.form.value.lng,
			};
			const result = await this.gateService.AddGate(gate);
			this.createdGate.emit(result.data);
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not create entrance'});
			this.closeModal.emit();
		} finally {
			this.loaderService.hide();
		}
	};

	selectColor(color: string) {
		this.form.patchValue({color: color});
	}

	addCustomMarker = async () => {
		const newCustomMarker: Partial<CustomMarkerDto> = {
			name: this.form.value.name,
			latitude: this.form.value.lat,
			longitude: this.form.value.lng,
			color: this.form.value.color,
		};
		if (this.savePin) {
			this.loaderService.show();
			try {
				if (!this.customMarker) {
					const result = await this.markerService.addCustomMarkers(newCustomMarker);
					const createdPin = result.data;
					const mapMarker = {
						id: 0,
						lat: this.form.value.lat,
						lng: this.form.value.lng,
						iconUrl: '../../../../assets/img/map_assets/markers/ic_marker_pin_blue_selected.svg',
						color: this.form.value.color,
						zIndex: 6,
					};
					this.mapService.setAddress(mapMarker);
					this.onSave.emit(newCustomMarker);
				} else {
					const name = this.form.value.name;
					const result = await this.markerService.editCustomMarkers(newCustomMarker, this.currentMarker.id);
					this.editedCustomMarker.emit({
						...this.currentMarker,
						iscustomMarker: true,
						color: newCustomMarker.color,
						name: newCustomMarker.name,
						lat: newCustomMarker.latitude,
						lng: newCustomMarker.longitude,
					});
					this.closeModal.emit({...this.currentMarker, name: this.form.value.name});
				}
			} catch (err) {
				throwError(err);
				this.toastService.show({severity: 'error', summary: err.error.message});
			} finally {
				this.loaderService.hide();
			}
		} else {
			this.onSave.emit(newCustomMarker);
		}
	};

	handleLatChange = (event) => {
		const newLat = +event.target.value;
		this.form.value.lat = +newLat;
		this.mapService.mapCenterActual.lat = +newLat;
		this.mapService.showCenterPinOnMap(true, COLORS.BLUE);
		this.mapService.setAddressWithoutMarker({lat: newLat, lng: this.form.value.lng});
	};

	handleLngChange = (event) => {
		const newLng = +event.target.value;
		this.form.value.lng = +newLng;
		this.mapService.mapCenterActual.lng = +newLng;
		this.mapService.showCenterPinOnMap(true, COLORS.BLUE);
		this.mapService.setAddressWithoutMarker({lng: newLng, lat: this.form.value.lat});
	};

	handleSavePinChange = () => {
		this.savePin = !this.savePin;
	};
}
