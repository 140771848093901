import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {LoaderService} from 'src/app/services/loader.service';
import {ToastService} from 'src/app/services/toast.service';
import {NotificationsService} from 'src/app/services/notifications.service';
import {NotificationDto, PortalFeaturesEnabled} from 'src/app/types/dtos/models';
import {Subject, Subscription, throwError} from 'rxjs';
import {ChatService} from 'src/app/services/chat.service';
import {FEATURES, PORTAL_FEATURES} from 'src/app/types/enums';
import {UtilitiesService} from 'src/app/services/utilities.service';
import {RecordedRouteService} from 'src/app/services/recorded-route.service';
import {takeUntil} from 'rxjs/operators';
import {environment} from 'src/environments/environment';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
	public showLogoutPopup = false;
	public firstName = '';
	public lastName = '';
	public notifications: NotificationDto[];
	public nextNotification: NotificationDto;
	s;
	public currentUserSubscription: Subscription;
	public menuItemsShow: PortalFeaturesEnabled;
	private menuItemsShowSubscription: Subscription;
	public notification: boolean = false;
	public newMessage: Subscription;
	private destroy$ = new Subject<void>();

	public showRecordedRouteNotificationBadge: boolean = false;

	constructor(
		public router: Router,
		public auth: AuthService,
		private chatService: ChatService,
		private loaderService: LoaderService,
		private toast: ToastService,
		private utilitiesService: UtilitiesService,
		private notificationsService: NotificationsService,
		private recordedRouteService: RecordedRouteService
	) {}

	ngOnDestroy(): void {
		this.currentUserSubscription?.unsubscribe();
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngOnInit(): void {
		this.menuItemsShowSubscription = this.utilitiesService.featuresEnabled$
			.pipe(takeUntil(this.destroy$))
			.subscribe((menuItemsShow) => {
				if (menuItemsShow) {
					this.menuItemsShow = menuItemsShow;
				}
			});
		this.recordedRouteService.amountNotReviewedRecordedRoutes$
			.pipe(takeUntil(this.destroy$))
			.subscribe((amount) => {
				if (amount) {
					this.showRecordedRouteNotificationBadge = amount > 0;
				}
			});
		this.auth.currentUser.pipe(takeUntil(this.destroy$)).subscribe((currentUser) => {
			if (currentUser) {
				this.firstName = currentUser.firstName;
				this.lastName = currentUser.lastName;
				this.getNotifications();
				if (currentUser.staff && currentUser.features.some((f) => f.id === FEATURES.CHAT)) {
					this.newMessage = this.chatService.threadsListening.subscribe((threads) => {
						if (threads && threads.timestamps) {
							let notif = false;
							Object.values(threads.timestamps).forEach((thread) => {
								if (thread.timestamp?.seconds > thread.read_receipt?.seconds) {
									notif = true;
								}
								this.notification = notif;
							});
						}
					});
				}
				if (currentUser.staff) {
					currentUser.features.forEach((feature) => {
						switch (feature.id) {
							case FEATURES.ENTERPRISE_BASIC:
								this.utilitiesService.enableFeatures([PORTAL_FEATURES.USERS, PORTAL_FEATURES.SETTINGS]);
								break;
							case FEATURES.ENTERPRISE_DISPATCH:
								this.utilitiesService.enableFeatures([
									PORTAL_FEATURES.SEARCH_LAYER,
									PORTAL_FEATURES.FAVORITES,
									PORTAL_FEATURES.PINS,
									PORTAL_FEATURES.USERS_LOCATION,
									PORTAL_FEATURES.ROUTES,
									PORTAL_FEATURES.CHAT,
									PORTAL_FEATURES.USERS,
									PORTAL_FEATURES.SETTINGS,
								]);
								break;
							case FEATURES.ENTERPRISE_PLUS:
								this.utilitiesService.enableFeatures([
									PORTAL_FEATURES.SEARCH_LAYER,
									PORTAL_FEATURES.FAVORITES,
									PORTAL_FEATURES.PINS,
									PORTAL_FEATURES.USERS_LOCATION,
									PORTAL_FEATURES.ROUTES,
									PORTAL_FEATURES.CUSTOM_ROUTES,
									PORTAL_FEATURES.CHAT,
									PORTAL_FEATURES.USERS,
									PORTAL_FEATURES.SETTINGS,
								]);
								break;
							case FEATURES.CUSTOM_ROUTES:
								this.utilitiesService.enableFeatures([PORTAL_FEATURES.CUSTOM_ROUTES]);
								break;
							case FEATURES.CHAT:
								this.utilitiesService.enableFeatures([PORTAL_FEATURES.CHAT]);
								break;
							case FEATURES.HAZMAT_ROUTES:
								this.utilitiesService.enableFeatures([PORTAL_FEATURES.VEHICLE_PROFILES]);
								break;
							case FEATURES.SHARE_BETWEEN_PORTAL_USERS:
								this.utilitiesService.enableFeatures([PORTAL_FEATURES.SHARE_BETWEEN_PORTAL_USERS]);
								break;
							case FEATURES.DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT:
								this.utilitiesService.enableFeatures([
									PORTAL_FEATURES.DRAG_AND_DROP_AUTOMATED_ROUTE_EDIT,
								]);
								break;
							case FEATURES.INSIGHTS_DASHBOARD:
								this.utilitiesService.enableFeatures([PORTAL_FEATURES.INSIGHTS_DASHBOARD]);
								break;
						}
					});
					if (
						!currentUser.features
							.map((f) => f.id)
							.some(
								(f) =>
									f === FEATURES.ENTERPRISE_BASIC ||
									f === FEATURES.ENTERPRISE_DISPATCH ||
									f === FEATURES.ENTERPRISE_PLUS
							) &&
						currentUser.staff
					) {
						this.utilitiesService.enableFeatures([
							PORTAL_FEATURES.SEARCH_LAYER,
							PORTAL_FEATURES.FAVORITES,
							PORTAL_FEATURES.PINS,
							PORTAL_FEATURES.USERS_LOCATION,
							PORTAL_FEATURES.ROUTES,
							PORTAL_FEATURES.USERS,
							PORTAL_FEATURES.SETTINGS,
						]);
					} else if (
						!!currentUser.features
							.map((f) => f.id)
							.some(
								(f) =>
									f === FEATURES.ENTERPRISE_BASIC ||
									f === FEATURES.ENTERPRISE_DISPATCH ||
									f === FEATURES.ENTERPRISE_PLUS
							) &&
						!currentUser.staff
					) {
						this.utilitiesService.enableFeatures([
							PORTAL_FEATURES.SEARCH_LAYER,
							PORTAL_FEATURES.FAVORITES,
							PORTAL_FEATURES.PINS,
							PORTAL_FEATURES.ROUTES,
							PORTAL_FEATURES.SETTINGS,
						]);
					}
				}
				if (currentUser.features.some((f) => f.id === FEATURES.CAN_ACCESS_PORTAL) || !currentUser.staff) {
					this.utilitiesService.enableFeatures([
						PORTAL_FEATURES.SEARCH_LAYER,
						PORTAL_FEATURES.FAVORITES,
						PORTAL_FEATURES.PINS,
						PORTAL_FEATURES.ROUTES,
						PORTAL_FEATURES.SETTINGS,
					]);
				}
			}
		});
	}

	getNotifications = async () => {
		try {
			const result = await this.notificationsService.getNotifications();
			this.notifications = result.data.filter((notification) => !notification.read);
			this.notifications.length ? (this.nextNotification = this.notifications[0]) : '';
		} catch (err) {
			throwError(err);
		}
	};

	closeNotificationModal = async (notification: NotificationDto) => {
		this.nextNotification = undefined;
		let index = this.notifications.indexOf(notification);
		if (index > -1) {
			this.notifications.splice(index, 1);
			try {
				const result = await this.notificationsService.updateNotification(notification.id);
			} catch (err) {
				throwError(err);
			}
		}
		this.notifications.length ? (this.nextNotification = this.notifications[0]) : '';
	};

	public openUrl = (url: string) => {
		window.open(url, '_blank');
	};

	logout = async () => {
		this.loaderService.show();
		try {
			this.auth.logout();
		} catch (err) {
			throwError(err);
			this.toast.show({severity: 'error', summary: 'Something went wrong, please try again later'});
		} finally {
			this.loaderService.hide();
		}
	};
	public redirectToInsights = () => {
		window.open(`${environment.insightsUrl}`, '_blank');
	};
}
