import {LatLngLiteral, Polyline} from '@agm/core';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {getColorByCode, trimText} from '../helpers/mapHelper';
import {DEFAULT_RADIUS, DEFAULT_TITLE_LENGTH} from '../types/constants';
import {
	ItemData,
	MapDrawCustomRouteData,
	MapDrawingDto,
	PolylineDto,
	GeoNoteDto,
	PolylineData,
	TranslateDto,
	MarkerDto,
} from '../types/dtos/models';
import {ApiResponse} from '../types/dtos/service';
import {COLORS, COLORS_HEX, ContentType, ItemType, LANGUAGES, MarkerIconFile} from '../types/enums';
import {v4 as uuid} from 'uuid';
import {ImageService} from './image.service';
import {InfoWindow} from '@agm/core/services/google-maps-types';
import {BehaviorSubject, Observable} from 'rxjs';
import {MapService} from './map.service';
import {ic_marker_pin_default} from 'src/assets/img/map_assets/custom_route/ic_marker_pin_default';

@Injectable({
	providedIn: 'root',
})
export class DrawService {
	private drawsSubject: BehaviorSubject<Partial<MapDrawingDto>[]> = new BehaviorSubject<MapDrawingDto[]>([]);
	public currentDraws: Observable<Partial<MapDrawingDto>[]> = this.drawsSubject.asObservable();
	private apiHost = `${environment.apiHost}/drawings`;
	private translateApiHost = `${environment.apiHost}/translate`;

	constructor(private http: HttpClient, private mapService: MapService, private imageService: ImageService) {
		this.getDraws();
		// this.syncDraws();
	}

	public syncDraws = () => {
		setTimeout(async () => {
			await this.getDraws();
			this.syncDraws();
		}, 120000);
	};

	public getDraws = async (): Promise<ApiResponse<MapDrawingDto[]>> =>
		this.http
			.get<ApiResponse<MapDrawingDto[]>>(`${this.apiHost}`, {withCredentials: true})
			.toPromise()
			.then((res) => {
				this.drawsSubject.next(res.data);
				return res;
			});

	public saveDraw = async (draw: MapDrawingDto): Promise<ApiResponse<MapDrawingDto>> =>
		this.http.post<ApiResponse<MapDrawingDto>>(`${this.apiHost}`, {...draw}, {withCredentials: true}).toPromise();

	public deleteDraw = async (drawId: number, ownerId: number): Promise<ApiResponse<void>> =>
		this.http
			.delete<ApiResponse<void>>(`${this.apiHost}/${drawId}/${ownerId}`, {withCredentials: true})
			.toPromise();

	public translateText = async (text: TranslateDto): Promise<ApiResponse<TranslateDto>> =>
		this.http
			.post<ApiResponse<TranslateDto>>(`${this.translateApiHost}`, text, {withCredentials: true})
			.toPromise();

	public getFullDraw = async (id: number): Promise<ApiResponse<MapDrawingDto>> =>
		this.http
			.get<ApiResponse<MapDrawingDto>>(`${this.apiHost}/${id}`, {
				withCredentials: true,
			})
			.toPromise();

	public updateDraw = async (draw: MapDrawingDto): Promise<ApiResponse<MapDrawingDto>> =>
		this.http
			.put<ApiResponse<MapDrawingDto>>(`${this.apiHost}/${draw.id}`, draw, {withCredentials: true})
			.toPromise();

	public transformDrawToDto = (draw: MapDrawCustomRouteData): MapDrawingDto => {
		const polylines: PolylineDto[] = [];
		draw.paths &&
			polylines.push(
				...draw.paths.map(
					(polyline): PolylineDto => {
						return {
							coordinates: polyline.polyline
								.getPath()
								.getArray()
								.map((latLng): LatLngLiteral => ({lat: latLng.lat(), lng: latLng.lng()})),
						};
					}
				)
			);
		const items: ItemData[] = [];
		draw.notes &&
			items.push(
				...draw.notes?.map(
					(note): ItemData => ({
						title: note.title,
						description: note.description,
						lat: note.marker.getPosition().lat(),
						lng: note.marker.getPosition().lng(),
						color: note.color || COLORS.BLUE,
						type: ItemType.NOTE,
						radius: DEFAULT_RADIUS,
						image: note.image,
					})
				)
			);
		draw.markers &&
			items.push(
				...draw.markers?.map((pin) => ({
					title: pin.title,
					description: pin.description,
					lat: pin.marker.getPosition().lat(),
					lng: pin.marker.getPosition().lng(),
					color: pin.color || COLORS.BLUE,
					type: ItemType.MARKER,
					radius: DEFAULT_RADIUS,
				}))
			);
		draw.stops &&
			items.push(
				...draw.stops?.map((stop) => {
					return {
						title: stop.title,
						description: stop.description,
						stopType: stop.stopType,
						lat: stop.marker.getPosition().lat(),
						lng: stop.marker.getPosition().lng(),
						color: stop.color || COLORS.BLUE,
						type: ItemType.STOP,
						radius: DEFAULT_RADIUS,
					};
				})
			);
		draw.startPoint &&
			items.push({
				title: draw.startPoint.title,
				description: draw.startPoint.description,
				lat: draw.startPoint.marker.getPosition().lat(),
				lng: draw.startPoint.marker.getPosition().lng(),
				color: draw.startPoint.color || COLORS.DEFAULT,
				type: ItemType.START_POINT,
				radius: DEFAULT_RADIUS,
			});
		draw.endPoint &&
			items.push({
				title: draw.endPoint.title,
				description: draw.endPoint.description,
				lat: draw.endPoint.marker.getPosition().lat(),
				lng: draw.endPoint.marker.getPosition().lng(),
				color: draw.endPoint.color || COLORS.DEFAULT,
				type: ItemType.END_POINT,
				radius: DEFAULT_RADIUS,
			});
		let distance = 0;
		if (draw.paths) {
			draw.paths.forEach((polyline) => (distance += this.getPolylineDistanceMeters(polyline.polyline)));
		}
		const ret: MapDrawingDto = {
			id: draw.id || undefined,
			name: draw.name,
			description: draw.description,
			distance,
			color: draw.color,
			polylines,
			items,
			userId: draw.userId,
		};

		return ret;
	};

	public transformDrawToDtoSharable = async (draw: MapDrawCustomRouteData): Promise<MapDrawingDto> => {
		const drawDto = this.transformDrawToDto(draw);
		const promises = drawDto.items.map(async (item) => {
			if (item.title.length) {
				const titleTranslated = await this.translateText({text: item.title, lang: LANGUAGES.SPANISH});
				item.translated_title = titleTranslated.data.text;
			}
			if (item.description.length) {
				const descriptionTranslated = await this.translateText({
					text: item.description,
					lang: LANGUAGES.SPANISH,
				});
				item.translated_description = descriptionTranslated.data.text;
			}
		});
		await Promise.all(promises);
		return drawDto;
	};

	public fitBounds = function (markers: GeoNoteDto[], polylines: PolylineData[]) {
		const map = this.mapService.mapRef;
		// Transform all the coords of the path to LatLng
		let coords = [];
		coords.push(
			markers
				.filter((x) => x)
				.map(
					(marker) =>
						new google.maps.LatLng(marker.marker.getPosition().lat(), marker.marker.getPosition().lng())
				)
		);
		coords.push(
			[].concat(
				...polylines.map((path) =>
					path.polyline
						.getPath()
						.getArray()
						.map((latLng) => new google.maps.LatLng(latLng.lat(), latLng.lng()))
				)
			)
		);
		// Create a new polygon with the coords
		const polygon = new google.maps.Polygon({
			paths: coords,
		});
		const bounds = new google.maps.LatLngBounds();
		const paths = polygon.getPaths();
		let path;
		for (let i = 0; i < paths.getLength(); i++) {
			path = paths.getAt(i);
			for (let ii = 0; ii < path.getLength(); ii++) {
				bounds.extend(path.getAt(ii));
			}
		}
		map && map.fitBounds(bounds);
	};

	public transformDtoToDraw = async (
		dto: MapDrawingDto,
		map: google.maps.Map,
		edit: boolean = false,
		fitBounds: boolean = true
	): Promise<MapDrawCustomRouteData> => {
		const paths: PolylineData[] = [];
		dto.polylines &&
			paths.push(
				...dto.polylines.map((polyline) => {
					const path = new google.maps.Polyline({
						path: polyline.coordinates,
						draggable: false,
						geodesic: false,
						strokeColor: `#${dto.color}`,
						strokeOpacity: 1.0,
						strokeWeight: 6,
						map,
					});
					return {polyline: path, id: uuid()};
				})
			);
		let startPoint: GeoNoteDto;
		let endPoint: GeoNoteDto;
		const markers: GeoNoteDto[] = [];
		const notes: GeoNoteDto[] = [];
		const stops: GeoNoteDto[] = [];
		dto.items &&
			dto.items.forEach(async (item) => {
				const marker = new google.maps.Marker({
					position: {lat: item.lat, lng: item.lng},
					map,
					title: item.title,
					draggable:
						edit &&
						item.type !== ItemType.STOP &&
						item.type !== ItemType.START_POINT &&
						item.type !== ItemType.END_POINT,
					label: {
						text: trimText(item.title, DEFAULT_TITLE_LENGTH),
						className: 'draw-marker-label',
						color: 'black',
					},
					icon: {
						url: `../../assets/img/map_assets/custom_route/${
							item.type === ItemType.NOTE ? 'ic_note' : `ic_marker_pin_${item.color.toLowerCase()}`
						}.svg`,
						fillColor: `#000000`,
						fillOpacity: 1,
						strokeColor: `#000000`,
						...(item.type === ItemType.NOTE && {
							origin: new google.maps.Point(-14, -16),
							labelOrigin: new google.maps.Point(45, 40),
						}),
						scaledSize:
							item.type === ItemType.NOTE ? new google.maps.Size(60, 60) : new google.maps.Size(40, 40),
					},
				});

				let content = '';
				if (item.image && item.type === 'NOTE') {
					let link = await this.getImgLink(item.image);
					content = `<div class="info-window">
					<div class="info-window__title"><h1>${item.title}</h1></div>
					<br>
					<div class="info-window__description">${item.description}</div>
					<br/>
					<img src="${link}" style="width: 250px; height: 250px"/>
				</div>`;
				} else {
					content = `<div class="info-window">
					<div class="info-window__title"><h1>${item.title}</h1></div>
					<br>
					<div class="info-window__description">${item.description}</div>
				</div>`;
				}
				const infoWindow = new google.maps.InfoWindow({content});
				marker.addListener('click', () => {
					infoWindow.open(map, marker);
				});
				marker.setMap(map);
				const geoNote: GeoNoteDto = {
					id: uuid(),
					lat: item.lat,
					lng: item.lng,
					color: item.color,
					title: item.title ? item.title : 'Note',
					description: item.description,
					marker,
					infoWindow,
					image: item.image === null ? undefined : item.image,
				};
				if (item.type === ItemType.MARKER) {
					markers.push(geoNote);
				} else if (item.type === ItemType.NOTE) {
					notes.push(geoNote);
				} else if (item.type === ItemType.STOP) {
					geoNote.stopType = item.stopType;
					stops.push(geoNote);
				} else if (item.type === ItemType.START_POINT) {
					startPoint = geoNote;
				} else if (item.type === ItemType.END_POINT) {
					endPoint = geoNote;
				}
			});
		// Transform all the coords of the path to LatLng
		let coords = [];
		coords.push(markers.map((marker) => new google.maps.LatLng(marker.lat, marker.lng)));
		coords.push(notes.map((note) => new google.maps.LatLng(note.lat, note.lng)));
		coords.push(
			[].concat(
				...paths.map((path) =>
					path.polyline
						.getPath()
						.getArray()
						.map((latLng) => new google.maps.LatLng(latLng.lat(), latLng.lng()))
				)
			)
		);
		// Create a new polygon with the coords
		const polygon = new google.maps.Polygon({
			paths: coords,
		});
		// Get the bounds of the polygon and set the map to fit the bounds
		fitBounds && this.fitBounds([...markers, ...notes, ...stops, startPoint, endPoint], paths);

		const ret = {
			id: dto.id,
			name: dto.name,
			description: dto.description,
			color: dto.color,
			colorCode: getColorByCode(dto.color),
			paths,
			markers,
			notes,
			stops,
			startPoint,
			endPoint,
		};
		return ret;
	};

	public cleanMapDraws = (customRouteDraws: MapDrawCustomRouteData[]) => {
		customRouteDraws.forEach((customRouteDraw) => {
			if (customRouteDraw) {
				customRouteDraw.paths?.forEach((path) => {
					path.polyline.setMap(null);
					path.polyline.unbindAll();
				});
				customRouteDraw.markers?.forEach((pin) => {
					pin.marker.setMap(null);
					pin.marker.unbindAll();
					pin.infoWindow?.unbindAll();
				});
				customRouteDraw.notes?.forEach((note) => {
					note.marker.setMap(null);
					note.marker.unbindAll();
					note.infoWindow?.unbindAll();
				});
				customRouteDraw.stops?.forEach((stop) => {
					stop.marker.setMap(null);
					stop.marker.unbindAll();
					stop.infoWindow?.unbindAll();
				});
				customRouteDraw.startPoint?.marker.setMap(null);
				customRouteDraw.startPoint?.marker.unbindAll();
				customRouteDraw.startPoint?.infoWindow?.unbindAll();
				customRouteDraw.endPoint?.marker.setMap(null);
				customRouteDraw.endPoint?.marker.unbindAll();
				customRouteDraw.endPoint?.infoWindow?.unbindAll();
				this.mapService.drawStatus = {
					active: false,
					color: undefined,
					description: undefined,
					name: undefined,
					toolSelected: undefined,
					paths: undefined,
					markers: undefined,
					notes: undefined,
					startPoint: undefined,
					endPoint: undefined,
				};
			}
		});
	};

	public removePath = (id: string) => {
		const customRouteDraw = this.mapService.drawStatus;
		const path = customRouteDraw.paths?.find((path) => path.id === id);
		path?.polyline.setMap(null);
		path?.polyline.unbindAll();
		customRouteDraw.paths = customRouteDraw.paths?.filter((path) => path.id !== id);
	};

	public removeMarker = (id: string) => {
		const customRouteDraw = this.mapService.drawStatus;
		const marker = customRouteDraw.markers?.find((marker) => marker.id === id);
		marker?.marker.setMap(null);
		marker?.marker.unbindAll();
		marker?.infoWindow.unbindAll();
		customRouteDraw.markers = customRouteDraw.markers?.filter((marker) => marker.id !== id);
	};

	public removeStop = (id: string) => {
		const customRouteDraw = this.mapService.drawStatus;
		const stop = customRouteDraw.stops?.find((marker) => marker.id === id);
		stop?.marker.setMap(null);
		stop?.marker.unbindAll();
		stop?.infoWindow.unbindAll();
		customRouteDraw.stops = customRouteDraw.stops?.filter((stop) => stop.id !== id);
	};

	public editMarker = (id: string, title: string, description: string) => {
		try {
			const customRouteDraw = this.mapService.drawStatus;
			const marker = customRouteDraw.markers?.find((marker) => marker.id === id);
			marker.title = title;
			marker.marker.setLabel({
				text: trimText(title, DEFAULT_TITLE_LENGTH),
				className: 'draw-marker-label',
				color: 'black',
			});
			marker.description = description;
			marker.infoWindow.setContent(`
				<div class="info-window">
					<div class="info-window__title"><h1>${title}</h1></div>
					<br>
					<div class="info-window__description">${description}</div>
				</div>
			`);
		} catch (error) {}
	};

	public removeNote = (id: string) => {
		const customRouteDraw = this.mapService.drawStatus;
		const note = customRouteDraw.notes?.find((note) => note.id === id);
		note?.marker.setMap(null);
		note?.marker.unbindAll();
		note?.infoWindow.unbindAll();
		customRouteDraw.notes = customRouteDraw.notes?.filter((note) => note.id !== id);
	};

	public editNote = async (id: string, title: string, description: string, image?: string) => {
		const customRouteDraw = this.mapService.drawStatus;
		const note = customRouteDraw.notes?.find((note) => note.id === id);
		note.title = title;
		note.description = description;
		let link = await this.getImgLink(image);
		note.image = image;
		note.image
			? note.infoWindow.setContent(`
		<div class="info-window">
			<div class="info-window__title"><h1>${title}</h1></div>
			<br>
			<div class="info-window__description">${description}</div>
			<br/>
			<img src="${link}" style="width: 250px; height: 250px"/>
		</div>`)
			: note.infoWindow.setContent(`
		<div class="info-window">
			<div class="info-window__title"><h1>${title}</h1></div>
			<br>
			<div class="info-window__description">${description}</div>
		</div>
`);
	};

	public openInfoWindow = (id: string) => {
		const customRouteDraw = this.mapService.drawStatus;
		const map = this.mapService.mapRef;
		customRouteDraw.markers.forEach((marker) => {
			if (marker.id === id) {
				marker.infoWindow.open(map, marker.marker);
			} else {
				marker.infoWindow.close();
			}
		});
		customRouteDraw.notes.forEach((note) => {
			if (note.id === id) {
				note.infoWindow.open(map, note.marker);
			} else {
				note.infoWindow.close();
			}
		});
		customRouteDraw.stops.forEach((stop) => {
			if (stop.id === id) {
				stop.infoWindow.open(map, stop.marker);
			} else {
				stop.infoWindow.close();
			}
		});
	};

	public createWaypoint = (note: Partial<GeoNoteDto>, draggable: boolean) => {
		const map = this.mapService.mapRef;
		const marker = new google.maps.Marker({
			position: {lat: note.lat, lng: note.lng},
			zIndex: 100,
			map,
			draggable,
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				scale: 7,
				fillColor: `#${COLORS_HEX.WHITE}`,
				fillOpacity: 1,
				strokeColor: `#${COLORS_HEX.BLACK}`,
				strokeWeight: 1,
			},
		});
		marker.setMap(map);
		const geoNote = {
			id: uuid(),
			marker,
			lat: note.lat,
			lng: note.lng,
			color: note.color || undefined,
			stopType: note.stopType || undefined,
			title: note.title,
			description: note.description || '',
		};
		return geoNote;
	};

	public createGeoTurn = (
		note: Partial<GeoNoteDto>,
		draggable: boolean = false,
		icon: MarkerIconFile = MarkerIconFile.PIN_BLUE,
		map = this.mapService.mapRef
	) => {
		const marker = new google.maps.Marker({
			position: {lat: note.lat, lng: note.lng},
			map,
			draggable,
			icon: {
				url: `../../assets/img/map_assets/custom_route/${icon}`,
				fillColor: `#000000`,
				fillOpacity: 1,
				strokeColor: `#000000`,
				scaledSize: new google.maps.Size(40, 40),
				...([MarkerIconFile.NOTE, MarkerIconFile.TURN_LEFT, MarkerIconFile.TURN_RIGHT].includes(icon) && {
					origin: new google.maps.Point(-14, -16),
					scaledSize: new google.maps.Size(60, 60),
				}),
			},
		});
		marker.setMap(map);
		const geoNote = {
			id: uuid(),
			marker,
			lat: note.lat,
			lng: note.lng,
			color: note.color || undefined,
			stopType: note.stopType || undefined,
			title: note.title,
			description: note.description || '',
			type: note.type || undefined,
		};

		return geoNote;
	};

	public displayMarker = (markerToDisplay: MarkerDto, draggable: boolean = false, map = this.mapService.mapRef) => {
		const marker = new google.maps.Marker({
			position: {lat: markerToDisplay.lat, lng: markerToDisplay.lng},
			map,
			draggable,
			icon: {
				url: `../../assets/img/map_assets/custom_route/ic_marker_pin_${
					markerToDisplay.color ? markerToDisplay.color.toLocaleLowerCase() : 'orange'
				}.svg`,
				labelOrigin: new google.maps.Point(20, 10),
				scaledSize: new google.maps.Size(35, 35),
			},
			label: {
				text: trimText(markerToDisplay.name, DEFAULT_TITLE_LENGTH),
				color: 'black',
				className: 'draw-marker-label',
			},
		});
		marker.setMap(map);
		return marker;
	};

	public createGeoNote = (
		note: Partial<GeoNoteDto>,
		draggable: boolean,
		icon: MarkerIconFile = MarkerIconFile.PIN_BLUE,
		map = this.mapService.mapRef,
		showInfoWindow: boolean = true,
		markerIndex: number = undefined
	) => {
		let content = '';
		const marker = new google.maps.Marker({
			position: {lat: note.lat, lng: note.lng},
			map,
			title: note.title,
			draggable,
			label: {
				text: note.title
					? trimText(note.title, DEFAULT_TITLE_LENGTH)
					: icon === MarkerIconFile.NOTE
					? 'Note'
					: ' ',
				className: 'draw-marker-label',
				color: 'black',
			},
			crossOnDrag: true,
			icon: {
				...(markerIndex !== undefined
					? {url: ic_marker_pin_default(markerIndex)}
					: {url: `../../assets/img/map_assets/custom_route/${icon}`}),
				scaledSize: new google.maps.Size(40, 40),
				labelOrigin: new google.maps.Point(20, 15),
				...([MarkerIconFile.TURN_LEFT, MarkerIconFile.TURN_RIGHT, MarkerIconFile.NOTE].includes(icon) && {
					scaledSize: new google.maps.Size(60, 60),
				}),
				...([MarkerIconFile.NOTE].includes(icon) && {
					origin: new google.maps.Point(-14, -16),
					labelOrigin: new google.maps.Point(45, 40),
					scaledSize: new google.maps.Size(60, 60),
				}),
			},
		});
		if (note.content && note.content.length) {
			note.content.forEach((c) => {
				if (c.type.includes(ContentType.IMAGE)) {
					content += `<div class="info-window__image"><img src="${c.data}" style="width: 250px; height: 250px"/></div><br>`;
				} else if (c.type.includes(ContentType.AUDIO)) {
					content += `<div class="info-window__audio"><audio controls><source src="${c.data}" type="${
						c.type
					}"></audio>
					<br>
					<div class="info-window__audio__text">${c.transcription || ''}</div>
					</div><br>`;
				}
			});
		}
		let infoWindow = undefined;
		if (showInfoWindow) {
			infoWindow = new google.maps.InfoWindow({
				content: `
				<div class="info-window">
				<div class="info-window__title"><h1>${note.title ? note.title : 'Note'}</h1></div>
				<br>
				<div class="info-window__description">${note.description || ''}</div>
				<br>
				${content}
				</div>
				`,
			});
			marker.addListener('click', () => {
				infoWindow.open(map, marker);
			});
		}
		marker.setMap(map);
		const geoNote = {
			id: uuid(),
			marker,
			infoWindow,
			lat: note.lat,
			lng: note.lng,
			content: note.content || [],
			color: note.color || undefined,
			stopType: note.stopType || undefined,
			title: note.title,
			description: note.description || '',
			type: note.type || undefined,
		};

		return geoNote;
	};

	public changeInfoWindowContent = (infoWindow: InfoWindow, title: string, description: string) => {
		infoWindow.setContent(`
			<div class="info-window">
				<div class="info-window__title"><h1>${title}</h1></div>
				<br>
				<div class="info-window__description">${description}</div>
			</div>
		`);
	};

	public cleanGeoNoteFromMap = (geoNote: GeoNoteDto) => {
		geoNote.marker.setMap(null);
		geoNote.marker.unbindAll();
		geoNote.infoWindow.unbindAll();
	};

	public getPolylineDistanceMeters = (polyline: google.maps.Polyline) => {
		const path = polyline.getPath().getArray();
		const distanceMeters = google.maps.geometry.spherical.computeLength(path);
		return distanceMeters;
	};

	public centerMapOnGeoNote = (geoNote: GeoNoteDto, zoom: number = 10) => {
		const map = this.mapService.mapRef as google.maps.Map;
		map.setCenter({lat: geoNote.marker.getPosition().lat(), lng: geoNote.marker.getPosition().lng()});
		map.setZoom(zoom);
	};
	public getImgLink = async (id: string): Promise<string> => {
		if (id) {
			return (await this.imageService.signImage(id)).data.url;
		}
	};

	// }
	public createPolylineFromGeoJSONCoordinates = (
		polyline: number[][],
		color: string = COLORS_HEX.DEFAULT
	): PolylineData => {
		const path = polyline.map((latLng) => new google.maps.LatLng(latLng[1], latLng[0]));
		const pathPolyline = new google.maps.Polyline({
			path,
			draggable: false,
			geodesic: false,
			strokeColor: `#${color}`,
			strokeOpacity: 1.0,
			strokeWeight: 6,
		});
		return {polyline: pathPolyline, id: uuid()};
	};

	public createPolylineFromLatLng = (polyline: LatLngLiteral[]): PolylineData => {
		const path = polyline.map((latLng) => new google.maps.LatLng(latLng.lat, latLng.lng));
		const pathPolyline = new google.maps.Polyline({
			path,
			draggable: false,
			geodesic: false,
			strokeColor: `#${COLORS_HEX.DEFAULT}`,
			strokeOpacity: 1.0,
			strokeWeight: 6,
		});
		return {polyline: pathPolyline, id: uuid()};
	};

	public createMarker = (lat: number, lng: number, title: string = '', draggable: boolean = false) => {
		const marker = new google.maps.Marker({
			position: {lat, lng},
			title,
			draggable,
		});
		return marker;
	};
}
