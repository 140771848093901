<button class="csv-uploader-button" [disabled]="!!disabled" (click)="handleDisplayModal(true)">
	<i class="icon-attach-1" [ngClass]="{disabled: disabled}"></i>
</button>
<div class="modal csv-uploader" *ngIf="showModal">
	<div class="modal-content" *ngIf="selectedStep === 'type_selection'">
		<i class="icon-close" (click)="handleDisplayModal(false)"></i>
		<h1 class="modal-title-csv">Upload Destination List</h1>
		<h2 class="modal-description-csv">Select destination type</h2>
		<div class="type-selection">
			<div class="type" [ngClass]="{selected: selectedType === 'Well'}" (click)="handleSelectType('Well')">
				<div>
					<img src="../../../../assets/img/wells.svg" />
				</div>
				<h2>Wells</h2>
			</div>
			<div class="type" [ngClass]="{selected: selectedType === 'Pin'}" (click)="handleSelectType('Pin')">
				<div>
					<img src="../../../../assets/img/yellow-pin.svg" />
				</div>
				<h2>Pins</h2>
			</div>
		</div>
		<button class="btn btn-next" (click)="handleSelectStep('file_upload')">Next</button>
	</div>
	<div class="modal-content" *ngIf="selectedStep === 'file_upload'">
		<i class="icon-close" (click)="handleDisplayModal(false)"></i>
		<h1 class="modal-title-csv">Upload Well List</h1>
		<div class="csv-template">
			<p>Need help? Use our template</p>
			<div class="csv-template-info">
				<div class="icon-name">
					<i class="icon-doc-text"></i>
					<p>{{ selectedType }} list CSV template</p>
				</div>
				<p (click)="handleDownloadTamplate()">Download</p>
			</div>
		</div>
		<div class="csv-upload-file">
			<p>Upload your CSV file</p>
			<div class="file-uploader" [ngClass]="{error: csvError}">
				<label class="file-input" for="csv-uploader" [ngClass]="{error: csvError}">
					<p *ngIf="!csvUploadedInfo">Choose your file</p>
					<p class="csv-info" *ngIf="csvUploadedInfo">{{ csvUploadedInfo.name }}</p>
					<input
						#csvInput
						type="file"
						name="Upload CSV"
						id="csv-uploader"
						accept=".csv"
						class="csv-uploader"
						(change)="readCSV(csvInput)"
					/>
				</label>
			</div>
			<div *ngIf="csvError" class="error-section">
				<img src="../../../../assets/img/warning.svg" />
				<div class="error-info">
					<p>Failed to upload your file.</p>
					<p>Please try again</p>
				</div>
			</div>
			<div class="buttons">
				<button class="btn btn-white" (click)="handleSelectStep('type_selection')">Cancel</button>
				<button class="btn" (click)="handleSaveStops()" [disabled]="csvError || !csvUploadedInfo">Save</button>
			</div>
		</div>
	</div>
</div>
