<div class="modal map-settings">
	<div class="modal-content">
		<i class="icon-close" (click)="closeModal.emit(false)"></i>

		<h1 class="modal-title">Map settings</h1>

		<p class="title-bar">
			<span>Show in Map</span>
		</p>

		<ul>
			<li class="clearfix">
				<a
					class="switch"
					(click)="showNearbyLayer(!mapService.currentStatus.showNearbyLayer)"
					[ngClass]="{on: mapService.currentStatus.showNearbyLayer}"
				>
					<span class="dot"></span>
				</a>
				<span>Show Nearby {{ mapService.currentStatus.layer.displayName }}s</span>
			</li>
			<li class="clearfix">
				<a
					class="switch"
					(click)="showNearbyPins(!mapService.currentStatus.showNearbyPins)"
					[ngClass]="{on: mapService.currentStatus.showNearbyPins}"
				>
					<span class="dot"></span>
				</a>
				<span>Show Nearby Pins</span>
			</li>
		</ul>

		<p class="title-bar">
			<span>My pinned Wells</span>
		</p>

		<button (click)="clearPins()" class="btn">
			Clear pinned {{ mapService.currentStatus.layer.id | layerName }}s
		</button>
	</div>
</div>
