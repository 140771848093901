<div class="change-password">
	<div class="change-password-content">
		<label>Old password</label>
		<span class="ui-float-label">
			<input type="password" pInputText [(ngModel)]="oldPassword" #input1 autocomplete="new-password" />
		</span>

		<label>New password</label>
		<span class="ui-float-label">
			<input type="password" pInputText [(ngModel)]="password" #input1 autocomplete="new-password" />
		</span>

		<label>Confirm Password</label>
		<span class="ui-float-label">
			<input type="password" pInputText [(ngModel)]="confirmPassword" #input1 autocomplete="new-password" />
		</span>

		<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
			<button class="cancel-button" (click)="closeModal.emit()">
				Cancelar
			</button>
			<button
				class="red-button save-button"
				[disabled]="!oldPassword || !password || !confirmPassword || password !== confirmPassword"
				(click)="resetPassword()"
			>
				Save
			</button>
		</div>
	</div>
</div>
