import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UserDto} from 'src/app/types/dtos/models';
import {LoaderService} from 'src/app/services/loader.service';
import {UserService} from 'src/app/services/user.service';
import {ToastService} from 'src/app/services/toast.service';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {throwError} from 'rxjs';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
	password: string;
	oldPassword: string;
	confirmPassword: string;
	currentUser: UserDto;

	constructor(
		public router: Router,
		private loaderService: LoaderService,
		private toastService: ToastService,
		private auth: AuthService
	) {}

	@Output() closeModal = new EventEmitter();

	ngOnInit(): void {}

	resetPassword = async () => {
		try {
			this.loaderService.show();
			await this.auth.changePassword(this.oldPassword, this.password, this.confirmPassword);
			this.toastService.show({severity: 'success', summary: 'Password successfully changed'});
		} catch (err) {
			throwError(err);
			this.toastService.show({severity: 'error', summary: 'Could not Update password'});
		}
		finally{
			this.loaderService.hide();
		}
		this.closeModal.emit();
	};
}
