<div class="card audio-player">
	<div class="audio" *ngIf="!!audio$">
		<div class="audio-container">
			<audio src="{{ audio }}" player="mp3" controls="true">
				Your browser does not support the audio element. See:
				<a href="https://www.w3schools.com/html/html5_audio.asp">HTML5 Audio</a>
			</audio>
			<i class="icon-trash" (click)="deleteAudio()"></i>
		</div>
	</div>
	<div class="record" *ngIf="!audio$">
		<div
			(click)="toggleRecording()"
			class="mic"
			[ngClass]="{recording}"
			[title]="recording ? 'Stop recording' : 'Start recording audio'"
		>
			<i class="icon-mic" *ngIf="!recording"></i>
			<i class="icon-record" *ngIf="recording"></i>
		</div>
	</div>
</div>
